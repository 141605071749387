import { IUser, IWorkItem } from "./converters";

export function getIdsFromMentionable(comment: string): string[] {
  const regexp = /\[.*\]\((\w+)\)/g;
  return [...new Set([...comment.matchAll(regexp)].map((x) => x[1]))];
}

export function getTextFromMentionable(comment: string): string {
  return comment.replace(/\[(.*?)\]\(\w+\)/g, "$1");
}

export function getEmailsFromIds(
  ids: string[],
  userList: IUser[],
  currentUserEmail: string
): string[] {
  const emails = [];
  for (let i = 0; i < ids.length; i++) {
    if (ids[i].includes("@") && ids[i] !== currentUserEmail) {
      emails.push(ids[i]);
      continue;
    }
    const found = userList.find((user) => user.id === ids[i]);
    if (found && found.email !== currentUserEmail) {
      emails.push(found.email);
    }
  }
  return emails;
}

export function incompleteDependencies(data: IWorkItem[], item: IWorkItem) {
  const selected = data.filter((x) => item.dependencies.includes(x.id ?? ""));
  return selected.filter((x) => x.status !== "done" && x.end >= item.start);
}
