import { GridSelectionModel, GridToolbarContainer } from "@mui/x-data-grid-pro";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import React from "react";

interface EditToolbarProps {
  handleAdd: () => void;
  handleDelete: () => void;
  selectionModel: GridSelectionModel;
}

export default function EditBoardToolbar({ handleAdd }: EditToolbarProps) {
  return (
    <GridToolbarContainer
      sx={{ gap: 1, padding: 2, flexDirection: "row-reverse" }}
    >
      <Button
        color="primary"
        variant="contained"
        startIcon={<Add />}
        onClick={handleAdd}
      >
        Add board
      </Button>
      {/*<Button*/}
      {/*  color="error"*/}
      {/*  variant="outlined"*/}
      {/*  startIcon={<Delete />}*/}
      {/*  onClick={handleDelete}*/}
      {/*  disabled={selectionModel.length === 0}*/}
      {/*>*/}
      {/*  Delete board*/}
      {/*</Button>*/}
    </GridToolbarContainer>
  );
}
