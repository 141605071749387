import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Check, Delete, PersonOff } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import { useAuth } from "./FirebaseAuthContext";
import {
  collection,
  doc,
  updateDoc,
  where,
  onSnapshot,
  documentId,
  getDocs,
  FieldPath,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router";
import { useConfirm } from "material-ui-confirm";
import { useFirebase } from "./useFirebase";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ColorMode, ColorModeContext } from "./MainRouter";
import {
  boardConverter,
  CollaboratorRole,
  IUser,
  organizationConverter,
  userConverter,
  IBoard,
} from "./converters";
import { query } from "firebase/firestore";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
} from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";
import { PricingPlanName } from "./config";

interface BoardMember {
  id: string;
  boardIds: string[];
  role: CollaboratorRole;
}

export default function MyAccount() {
  const [displayName, setDisplayName] = useState<string>("");
  const [organizationName, setOrganizationName] = useState<string>("");
  const [loadingBoardMembers, setLoadingBoardMembers] = useState<boolean>(true);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
  const [boardMembers, setBoardMembers] = useState<BoardMember[]>([]);
  const [users, setUsers] = useState<{ [userId: string]: IUser }>({});

  const { auth, firestore } = useFirebase();
  const { userData, userCollection, userOrganization, userPlan } = useAuth();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { mode, setMode } = useContext(ColorModeContext);

  const columns: GridColumns<BoardMember> = [
    {
      field: "id",
      headerName: "Name",
      type: "string",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const user = users[params.value as string];
        if (!user) {
          return "";
        }

        return <Typography variant="body2">{user.displayName}</Typography>;
      },
    },
    {
      field: "role",
      headerName: "Status",
      type: "string",
      minWidth: 150,
      renderCell: (params) => {
        const role = params.value;
        let displayValue = "Guest (Free)";
        if (role === CollaboratorRole.Editor) {
          displayValue = "Member (Paid)";
        }

        return <Typography variant="body2">{displayValue}</Typography>;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 120,
      cellClassName: "actions",
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<Delete />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="inherit"
        />,
      ],
    },
  ];

  useEffect(() => {
    if (userData?.displayName) {
      setDisplayName(userData.displayName);
    }
  }, [userData]);

  useEffect(() => {
    if (userOrganization) {
      setOrganizationName(userOrganization.name);
    }
  }, [userOrganization]);

  // load board members
  useEffect(() => {
    if (!userOrganization) {
      return;
    }

    const boardCollection = collection(firestore, "boards").withConverter(
      boardConverter
    );
    const fieldPath = new FieldPath(
      "collaboratorsByEmail",
      auth.currentUser?.email as string,
      "role"
    );
    const boardsQueryRef = query(
      boardCollection,
      where(fieldPath, "==", CollaboratorRole.Owner)
    );
    return onSnapshot(boardsQueryRef, (snapshot) => {
      if (snapshot.empty) {
        setLoadingBoardMembers(false);
        return;
      }
      const members: {
        [userId: string]: { boardIds: string[]; role: CollaboratorRole };
      } = {};
      snapshot.forEach((board) => {
        const boardData = board.data();
        if (!boardData.collaboratorsById) {
          return;
        }
        Object.keys(boardData.collaboratorsById).forEach((userId) => {
          const collaborator = boardData.collaboratorsById[userId];
          if (collaborator.role === CollaboratorRole.Owner) {
            return;
          }

          if (!members[userId]) {
            members[userId] = {
              boardIds: [board.id],
              role: collaborator.role,
            };
            return;
          }
          members[userId].boardIds.push(board.id);
          if (collaborator.role === CollaboratorRole.Editor) {
            members[userId].role = CollaboratorRole.Editor;
          }
        });
      });

      const memberList: BoardMember[] = [];
      Object.keys(members).forEach((userId) => {
        memberList.push({
          id: userId,
          boardIds: members[userId].boardIds,
          role: members[userId].role,
        });
      });
      setBoardMembers(memberList);
      setLoadingBoardMembers(false);
    });
  }, [userOrganization, firestore, auth.currentUser?.email]);

  // load users so that they can be used in column renderers
  useEffect(() => {
    const loadUsers = async () => {
      if (!boardMembers) {
        return;
      }

      const newUserIds: string[] = boardMembers
        .map((boardMember) => boardMember.id)
        .filter((userId) => !(userId in users));

      const newUsers: {
        [userId: string]: IUser;
      } = { ...users };
      const userCollection = collection(firestore, "users").withConverter(
        userConverter
      );
      for (let i = 0; i < newUserIds.length; i += 10) {
        const q = query(
          userCollection,
          where(documentId(), "in", newUserIds.slice(i, i + 10))
        );
        const users = await getDocs(q);
        users.forEach((user) => {
          newUsers[user.id] = user.data();
        });
      }
      setUsers(newUsers);
      setLoadingUsers(false);
    };

    loadUsers();
  }, [boardMembers, firestore, users]);

  const saveChanges = async () => {
    if (!userCollection || !userData || !userOrganization) {
      return;
    }
    const userRef = doc(userCollection, userData.id);

    await updateDoc(userRef, {
      displayName: displayName,
    });

    const orgCollection = collection(firestore, "organizations").withConverter(
      organizationConverter
    );
    const organizationRef = doc(orgCollection, userOrganization.id);
    await updateDoc(organizationRef, {
      name: organizationName,
    });

    navigate("/boards");
  };

  const deactivate = async () => {
    await confirm({
      confirmationText: "Deactivate",
      title: "Deactivate User",
      description: "Are you sure you want to deactivate your user?",
    });
    if (!userCollection || !userData) {
      return;
    }
    const userRef = doc(userCollection, userData.id);

    await updateDoc(userRef, {
      enabled: false,
    });

    auth.signOut();
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    const userName = users[id].displayName;
    await confirm({
      confirmationText: "Delete",
      title: `Remove Member - ${userName}`,
      description: `Are you sure you want to remove ${userName} from all of your boards?`,
    });

    const boardMember = boardMembers.find(
      (boardMember) => boardMember.id === id
    ) as BoardMember;
    const boardCollection = collection(firestore, "boards").withConverter(
      boardConverter
    );
    boardMember.boardIds.forEach(async (boardId) => {
      const boardRef = doc(boardCollection, boardId);
      const boardData = (await getDoc(boardRef)).data() as IBoard;
      const newCollaboratorsById = boardData.collaboratorsById;
      delete newCollaboratorsById[id];
      const newCollaboratorsByEmail = { ...boardData.collaboratorsByEmail };
      delete newCollaboratorsByEmail[users[id].email];

      await setDoc(boardRef, {
        ...boardData,
        collaboratorsById: newCollaboratorsById,
        collaboratorsByEmail: newCollaboratorsByEmail,
      });
    });

    setBoardMembers(
      boardMembers.filter((boardMember) => boardMember.id !== id)
    );
  };

  return (
    <Container
      maxWidth="md"
      sx={{ display: "flex", flexDirection: "column", flex: 1, gap: 2 }}
    >
      <Typography
        variant="h4"
        sx={{ marginTop: 3, marginBottom: 3, color: "text.primary" }}
      >
        My Account
      </Typography>

      <Grid container sx={{ alignItems: "center" }} spacing={1}>
        <Grid item xs={12} md={3}>
          <Typography sx={{ color: "text.primary" }}>User Name</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="User Name"
            size="small"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ alignItems: "center" }} spacing={1}>
        <Grid item xs={12} md={3}>
          <Typography sx={{ color: "text.primary" }}>
            Organization Name
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Organization Name"
            size="small"
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ alignItems: "center" }} spacing={1}>
        <Grid item xs={12} md={3}>
          <Typography sx={{ color: "text.primary" }}>Plan</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            disabled
            label="Plan"
            size="small"
            InputLabelProps={{ shrink: true }}
            sx={{
              input: {
                textTransform: "capitalize",
              },
            }}
            value={userPlan?.displayName || ""}
          />
        </Grid>
        {userPlan?.name === PricingPlanName.Free && (
          <Grid item xs={12} md={2}>
            <Button variant="contained" onClick={() => navigate("/#pricing")}>
              Upgrade
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container sx={{ alignItems: "center" }} spacing={1}>
        <Grid item xs={12} md={3}>
          <Typography sx={{ color: "text.primary" }}>Color Theme</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Select<ColorMode>
            size="small"
            fullWidth
            value={mode}
            onChange={(e) => setMode(e.target.value as ColorMode)}
          >
            <MenuItem value="preference">Default</MenuItem>
            <MenuItem value="light">Light</MenuItem>
            <MenuItem value="dark">Dark</MenuItem>
          </Select>
        </Grid>
      </Grid>

      <Divider />

      <Grid container sx={{ alignItems: "center" }} spacing={1}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: "flex", gap: 1, flexDirection: "row-reverse" }}
        >
          <Button
            variant="contained"
            startIcon={<Check />}
            onClick={() => saveChanges()}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="error"
            startIcon={<PersonOff />}
            onClick={() => deactivate()}
          >
            Deactivate User
          </Button>
        </Grid>
      </Grid>

      <Typography
        variant="h4"
        sx={{ marginTop: 1, marginBottom: 1, color: "text.primary" }}
      >
        Members of your boards
      </Typography>

      <Paper sx={{ flex: 1, marginBottom: 5 }}>
        <DataGridPro<BoardMember>
          initialState={{
            sorting: {
              sortModel: [{ field: "role", sort: "desc" }],
            },
          }}
          autoHeight
          disableColumnResize
          disableColumnPinning
          disableColumnMenu
          disableColumnReorder
          disableColumnFilter
          disableColumnSelector
          rows={boardMembers}
          loading={loadingBoardMembers || loadingUsers}
          columns={columns}
          density="standard"
          disableSelectionOnClick
          components={{
            NoRowsOverlay: () => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography>No members found</Typography>
              </Box>
            ),
          }}
        />
      </Paper>
    </Container>
  );
}
