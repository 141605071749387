import {
  Box,
  Button,
  List,
  ListItem,
  TextField,
  Typography,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  Autocomplete,
} from "@mui/material";
import React, { Dispatch, SyntheticEvent, useContext, useState } from "react";
import { PersonAdd, Delete } from "@mui/icons-material";
import UserAvatar from "./UserAvatar";
import { useFirestoreDocumentMutation } from "@react-query-firebase/firestore";
import {
  CollaboratorRole,
  emailConverter,
  EmailTemplate,
  IUser,
  userConverter,
} from "./converters";
import { useConfirm } from "material-ui-confirm";
import AppDrawer from "./AppDrawer";
import { AppContext, IAppContext } from "./App";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { useFirebase } from "./useFirebase";
import { useNavigate } from "react-router";
import { useRemoveCollaborator } from "./useRemoveCollaborator";

interface IShareDrawer {
  open: boolean;
  setOpen: Dispatch<boolean>;
}

const MIN_AUTOCOMPLETE_LENGTH = 2;
const DISABLE_AUTOCOMPLETE = true;

export default function ShareDrawer({ open, setOpen }: IShareDrawer) {
  const { boardDocument, boardData, data, boardPlan } = useContext(
    AppContext
  ) as IAppContext;

  const [email, setEmail] = useState("");
  const mutation = useFirestoreDocumentMutation(boardDocument);
  const confirm = useConfirm();
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const { auth, firestore } = useFirebase();
  const [options, setOptions] = useState<IUser[]>([]);
  const [newRole, setNewRole] = useState<CollaboratorRole>(
    CollaboratorRole.Editor
  );
  const navigate = useNavigate();
  const removeCollaborator = useRemoveCollaborator();

  const userCollection = collection(firestore, "users").withConverter(
    userConverter
  );

  const handleAddCollaborator = async () => {
    // check if email is valid
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!emailRegex.test(email)) {
      setInvalidEmail(true);
      return;
    }

    // check if email is already in the list
    if (email in boardData.collaboratorsByEmail) {
      setEmailExists(true);
      return;
    }

    // check if editor limit is reached
    const canProceed = await checkEditorsLimit();
    if (!canProceed) {
      return;
    }

    const emailCollection = collection(firestore, "emails").withConverter(
      emailConverter
    );
    const ref = query(userCollection, where("email", "==", email));
    const querySnapshot = await getDocs(ref);

    // if email is not in the list and the user exists in the database, add it
    if (!querySnapshot.empty) {
      const user = querySnapshot.docs[0].data();
      if (user.id === undefined) {
        return;
      }

      // send invitation email
      await addDoc(emailCollection, {
        to: [user.email],
        template: {
          name: EmailTemplate.ShareBoardWithExistingUser,
          data: {
            boardName: boardData.name,
            boardId: boardDocument.id,
            userName: auth.currentUser?.displayName as string,
          },
        },
      });

      const userRoleObject = {
        userEmail: email,
        userId: user.id,
        role: newRole,
      };
      mutation.mutate({
        ...boardData,
        collaboratorsByEmail: {
          ...boardData.collaboratorsByEmail,
          [email]: userRoleObject,
        },
        // due to firestore limitations, we need to store collaboratorsById separately
        collaboratorsById: {
          ...boardData.collaboratorsById,
          [user.id]: userRoleObject,
        },
      });

      setEmail("");
      return;
    }

    // send invitation email
    await addDoc(emailCollection, {
      to: [email],
      template: {
        name: EmailTemplate.ShareBoardWithNewUser,
        data: {
          boardName: boardData.name,
          boardId: boardDocument.id,
          userName: auth.currentUser?.displayName as string,
        },
      },
    });

    // before a user signs up, we can only store the email in collaboratorsByEmail
    mutation.mutate({
      ...boardData,
      collaboratorsByEmail: {
        ...boardData.collaboratorsByEmail,
        [email]: {
          userEmail: email,
          userId: null,
          role: CollaboratorRole.Viewer,
        },
      },
    });

    setEmail("");
  };

  const handleRemoveCollaborator = async (userEmail: string) => {
    try {
      await confirm({
        confirmationText: "Remove",
        description: `Remove ${email} from collaborators?`,
      });
    } catch (e) {
      return;
    }

    await removeCollaborator(boardData, data, userEmail);
  };

  const checkEditorsLimit = async () => {
    if (!boardPlan) {
      return false;
    }

    const editorsCount = Object.values(boardData.collaboratorsByEmail).filter(
      (collaborator) => collaborator.role === CollaboratorRole.Editor
    ).length;
    if (editorsCount >= boardPlan.editorsPerBoardLimit) {
      try {
        await confirm({
          confirmationText: "Upgrade",
          title: `Number of editors exceeded for ${boardPlan.name} plan`,
          description: `The ${boardPlan.name} plan allows adding up to ${boardPlan.editorsPerBoardLimit} editors. To add more editors to the board, the board owner's plan should be upgraded.`,
        });
        navigate("/#pricing");
        return false;
      } catch (e) {
        return false;
      }
    }
    return true;
  };

  const handleChangeCollaboratorRole = async (
    userEmail: string,
    role: CollaboratorRole
  ) => {
    if (!boardPlan) {
      return;
    }

    // make sure the editors limit is not exceeded
    if (role === CollaboratorRole.Editor) {
      const canProceed = await checkEditorsLimit();
      if (!canProceed) {
        return;
      }
    }

    const newBoardData = {
      ...boardData,
      collaboratorsByEmail: {
        ...boardData.collaboratorsByEmail,
        [userEmail]: { ...boardData.collaboratorsByEmail[userEmail], role },
      },
    };

    // if the user is already in the database, update collaboratorsById
    const ref = query(userCollection, where("email", "==", userEmail));
    const querySnapshot = await getDocs(ref);
    if (!querySnapshot.empty) {
      const user = querySnapshot.docs[0].data();
      const userId = user.id as string;
      newBoardData.collaboratorsById[userId] = {
        ...newBoardData.collaboratorsById[userId],
        role,
      };

      mutation.mutate(newBoardData);
    }
  };

  const loadOptions = async (prefixFrom: string) => {
    // we are using the prefixFrom as a starting point for the query and
    // prefixTo as an ending point. For example, if the
    // prefixFrom is "abc", the prefixTo will be "abd"
    // this way we can get all the users that start with "abc"
    const prefixTo =
      prefixFrom.slice(0, prefixFrom.length - 1) +
      String.fromCharCode(prefixFrom.charCodeAt(prefixFrom.length - 1) + 1);

    const userCollection = collection(firestore, "users").withConverter(
      userConverter
    );

    // email matches
    const emailQueryRef = query(
      userCollection,
      where("email", ">=", prefixFrom.toLocaleLowerCase()),
      where("email", "<", prefixTo.toLocaleLowerCase())
    );
    const emailQuerySnapshot = await getDocs(emailQueryRef);
    const emailMatches = emailQuerySnapshot.docs
      .map((doc) => doc.data())
      .filter((user) => user.displayName !== "");

    // display name matches
    const displayNameQueryRef = query(
      userCollection,
      where("lowerCaseDisplayName", ">=", prefixFrom.toLocaleLowerCase()),
      where("lowerCaseDisplayName", "<", prefixTo.toLocaleLowerCase())
    );
    const displayNameQuerySnapshot = await getDocs(displayNameQueryRef);
    const displayNameMatches = displayNameQuerySnapshot.docs.map((doc) =>
      doc.data()
    );

    // merge the two lists, after that remove duplicates, and sort
    const newOptions = [...emailMatches, ...displayNameMatches];

    // remove duplicate emails from the list
    const newOptionsMap = new Map<string, IUser>();
    newOptions.forEach((option) => {
      newOptionsMap.set(option.email, option);
    });
    const uniqueNewOptions = Array.from(newOptionsMap.values());

    const sortedNewOptions = uniqueNewOptions.sort((a, b) => {
      // choose fields we want to compare:
      // if the option was found by email, we use the email,
      // otherwise we use the display name
      const comparedFieldA = a.email.startsWith(prefixFrom)
        ? a.email
        : a.displayName;
      const comparedFieldB = b.email.startsWith(prefixFrom)
        ? b.email
        : b.displayName;
      return comparedFieldA.localeCompare(comparedFieldB);
    });

    setOptions(sortedNewOptions);
  };

  const handleChangeEmail = (e: SyntheticEvent, value: string | null) => {
    setEmail(value || "");
    if (value !== null && value.length >= MIN_AUTOCOMPLETE_LENGTH) {
      loadOptions(value);
    } else {
      setOptions([]);
    }
    setInvalidEmail(false);
    setEmailExists(false);
  };

  // we are using a space character as a placeholder for the helper text so that
  // the fields don't shift when the helper text is shown
  let emailErrorMessage = " ";
  if (invalidEmail) {
    emailErrorMessage = "Invalid e-mail address";
  } else if (emailExists) {
    emailErrorMessage = "E-mail already exists";
  }

  return (
    <AppDrawer
      open={open}
      setOpen={setOpen}
      title="Invite to Board"
      icon={<PersonAdd />}
      minWidth={400}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Typography>
          Please add collaborators by entering their e-mail address:
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Autocomplete<IUser, false, false, true>
            fullWidth
            freeSolo
            onChange={(_, value) => {
              if (typeof value === "string") {
                setEmail(value);
                return;
              }
              setEmail(value?.email || "");
            }}
            value={email}
            options={options}
            placeholder="Enter e-mail address"
            size="small"
            filterOptions={(options) => {
              if (DISABLE_AUTOCOMPLETE) {
                return [];
              }

              // this prevents the autocomplete from showing options
              // when the user types the first few characters and then deletes them
              if (email.length < MIN_AUTOCOMPLETE_LENGTH) {
                return [];
              }

              return options.filter(
                (option) => !(option.email in boardData.collaboratorsByEmail)
              );
            }}
            onInputChange={handleChangeEmail}
            renderInput={(params) => (
              <TextField
                error={emailErrorMessage !== " "}
                helperText={emailErrorMessage}
                {...params}
              />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.email}>
                  <UserAvatar userEmail={option.email} />
                </li>
              );
            }}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option;
              }
              return option.email.startsWith(email)
                ? option.email
                : option.displayName;
            }}
          />

          <FormControl sx={{ minWidth: 100 }} size="small">
            <Select
              onChange={(e) => setNewRole(e.target.value as CollaboratorRole)}
              value={newRole}
            >
              <MenuItem value={CollaboratorRole.Viewer}>Viewer</MenuItem>
              <MenuItem value={CollaboratorRole.Editor}>Editor</MenuItem>
              <MenuItem value={CollaboratorRole.Owner}>Owner</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Button
            sx={{ mb: 3 }}
            disabled={email.length === 0 || invalidEmail}
            onClick={handleAddCollaborator}
            startIcon={<PersonAdd />}
            variant="contained"
          >
            Share
          </Button>
        </Box>
        {boardData.collaboratorsByEmail &&
          Object.keys(boardData.collaboratorsByEmail).length > 0 && (
            <Typography variant="h6" component="h2">
              Shared With:
            </Typography>
          )}
        <List>
          {boardData.collaboratorsByEmail &&
            Object.entries(boardData.collaboratorsByEmail).map(
              ([collaboratorEmail, { role }]) => {
                const isOwner = role === CollaboratorRole.Owner;
                return (
                  <ListItem
                    key={collaboratorEmail}
                    sx={{ pl: 0, display: "flex", gap: 1 }}
                  >
                    <UserAvatar
                      userEmail={collaboratorEmail}
                      allowDisplayEmail
                    />

                    <FormControl sx={{ minWidth: 100 }} size="small">
                      <Select
                        onChange={(e) =>
                          handleChangeCollaboratorRole(
                            collaboratorEmail,
                            e.target.value as CollaboratorRole
                          )
                        }
                        value={role}
                      >
                        {!isOwner && (
                          <MenuItem value={CollaboratorRole.Viewer}>
                            Viewer
                          </MenuItem>
                        )}
                        {!isOwner && (
                          <MenuItem value={CollaboratorRole.Editor}>
                            Editor
                          </MenuItem>
                        )}
                        {isOwner && (
                          <MenuItem value={CollaboratorRole.Owner}>
                            Owner
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <IconButton
                      sx={{ visibility: isOwner ? "hidden" : "visible" }}
                      onClick={() =>
                        handleRemoveCollaborator(collaboratorEmail)
                      }
                      edge="end"
                      aria-label="delete"
                    >
                      <Delete />
                    </IconButton>
                  </ListItem>
                );
              }
            )}
        </List>
      </Box>
    </AppDrawer>
  );
}
