import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Link,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {
  GridActionsCellItem,
  GridColumns,
  GridRowId,
  GridEnrichedColDef,
} from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import EditBoardToolbar from "./EditBoardToolbar";
import { Outlet, useNavigate } from "react-router";
import {
  collection,
  deleteDoc,
  doc,
  documentId,
  FieldPath,
  getDocs,
  where,
} from "firebase/firestore";
import { query } from "firebase/firestore";
import {
  useFirestoreCollectionMutation,
  useFirestoreQueryData,
} from "@react-query-firebase/firestore";
import dayjs from "dayjs";
import {
  boardConverter,
  CardStatus,
  CollaboratorRole,
  IBoard,
  IOrganization,
  organizationConverter,
} from "./converters";
import { Delete, Settings, Visibility, Edit, Add } from "@mui/icons-material";
import { useFirebase } from "./useFirebase";
import { useConfirm } from "material-ui-confirm";
import Footer from "./Footer";
import { useAuth } from "./FirebaseAuthContext";
import { PricingPlan, PricingPlanName, pricingPlans } from "./config";
import { httpsCallable } from "firebase/functions";
import NewBoardModal from "./NewBoardModal";

export const DEFAULT_STATES: CardStatus[] = [
  "backlog",
  "todo",
  "in-progress",
  "done",
];

export default function Boards() {
  const { firestore, auth, functions } = useFirebase();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { userPlan, userData, userOrganization } = useAuth();
  const [organizations, setOrganizations] = React.useState<{
    [orgId: string]: IOrganization;
  }>({});
  const [boardPlans, setBoardPlans] = React.useState<{
    [boardId: string]: PricingPlan;
  }>({});
  const [open, setOpen] = useState<boolean>(false);

  function getNewBoardName() {
    const boardNames = boardQuery.data?.map((x) => x.name) ?? [];
    let newBoardNumber = 0;
    if (boardNames.includes("New Board")) {
      newBoardNumber += 1;
      while (boardNames.includes(`New Board ${newBoardNumber}`)) {
        newBoardNumber += 1;
      }
    }
    return newBoardNumber ? `New Board ${newBoardNumber}` : "New Board";
  }

  const nameColumn: GridEnrichedColDef<IBoard> = {
    field: "name",
    headerName: "Name",
    type: "string",
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <Typography variant="body2">
        <Link
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(`/boards/${params.row.id}`)}
        >
          {params.value}
        </Link>
      </Typography>
    ),
  };

  const dateColumn: GridEnrichedColDef<IBoard> = {
    field: "dateCreated",
    headerName: "Date Created",
    type: "dateTime",
    renderCell: (params) => (
      <Typography variant="body2">
        {params.value.toDate().toDateString()}
      </Typography>
    ),
    minWidth: 170,
  };

  const ownerColumn: GridEnrichedColDef<IBoard> = {
    field: "organizationId",
    headerName: "Organization",
    type: "string",
    minWidth: 250,
    renderCell: (params) => {
      const organizationId = params.value;
      const organization = organizations[organizationId];
      if (!organization) {
        return "";
      }

      return organization.name;
    },
  };

  const planColumn: GridEnrichedColDef<IBoard> = {
    field: "id",
    headerName: "Board Plan",
    type: "string",
    minWidth: 100,
    renderCell: (params) => {
      const boardId = params.value;
      const boardPlan = boardPlans[boardId];
      if (!boardPlan) {
        return "";
      }

      return boardPlan.displayName;
    },
  };

  const roleColumn: GridEnrichedColDef<IBoard> = {
    field: "collaboratorsByEmail",
    headerName: "Role",
    type: "string",
    minWidth: 100,
    renderCell: (params) => {
      const collaboratorsByEmail = params.value as {
        [email: string]: { role: CollaboratorRole };
      };
      const email = auth.currentUser?.email as string;
      const role = collaboratorsByEmail[email].role;

      const roleMap: { [role in CollaboratorRole]: string } = {
        [CollaboratorRole.Owner]: "Owner",
        [CollaboratorRole.Editor]: "Editor",
        [CollaboratorRole.Viewer]: "Viewer",
      };

      return roleMap[role];
    },
  };

  const getActionsColumn: (params: {
    allowDelete: boolean;
  }) => GridEnrichedColDef<IBoard> = ({ allowDelete }) => ({
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 120,
    cellClassName: "actions",
    getActions: ({ id }) => {
      const actions = [
        <GridActionsCellItem
          icon={<Visibility />}
          label="View Board"
          className="textPrimary"
          onClick={() => navigate(`/boards/${id}`)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<Settings />}
          label="Settings"
          className="textPrimary"
          onClick={() => navigate(`/boards/${id}/settings`)}
          color="inherit"
        />,
      ];

      if (allowDelete) {
        actions.push(
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />
        );
      }

      return actions;
    },
  });

  const ownBoardsColumns: GridColumns<IBoard> = [
    nameColumn,
    dateColumn,
    getActionsColumn({ allowDelete: true }),
  ];

  const sharedBoardsColumns: GridColumns<IBoard> = [
    nameColumn,
    dateColumn,
    ownerColumn,
    planColumn,
    roleColumn,
    getActionsColumn({ allowDelete: false }),
  ];

  const boardCollection = collection(firestore, "boards").withConverter(
    boardConverter
  );
  const fieldPath = new FieldPath(
    "collaboratorsByEmail",
    auth.currentUser?.email as string,
    "role"
  );
  const ref = query(
    boardCollection,
    where(fieldPath, ">=", CollaboratorRole.Viewer)
  );
  const boardQuery = useFirestoreQueryData(["boards"], ref, {
    subscribe: true,
  });

  // preload organizations so that they can be used in column renderers
  useEffect(() => {
    const loadOrganizations = async () => {
      if (!boardQuery.data) {
        return;
      }

      const orgIds = boardQuery.data.map((board) => board.organizationId);
      const newOrganizations: {
        [orgId: string]: IOrganization;
      } = {};
      const orgCollection = collection(
        firestore,
        "organizations"
      ).withConverter(organizationConverter);
      for (let i = 0; i < orgIds.length; i += 10) {
        const q = query(
          orgCollection,
          where(documentId(), "in", orgIds.slice(i, i + 10))
        );
        const organizations = await getDocs(q);
        organizations.forEach((org) => {
          newOrganizations[org.id] = org.data();
        });
      }
      setOrganizations(newOrganizations);
    };

    loadOrganizations();
  }, [boardQuery.data, firestore]);

  // preload board plans so that they can be used in column renderers
  useEffect(() => {
    const loadBoardPlans = async () => {
      if (!boardQuery.data) {
        return;
      }

      const getBoardPlan = httpsCallable(functions, "getBoardPlan");
      const newBoardPlans: {
        [boardPlanId: string]: PricingPlan;
      } = {};
      await Promise.all(
        boardQuery.data
          .filter((board) => board.organizationId !== userData?.organizationId)
          .map(async (board) => {
            const boardId = board.id;
            const result = await getBoardPlan({ boardId });
            const pricingPlan = pricingPlans[result.data as PricingPlanName];
            newBoardPlans[board.id as string] = pricingPlan;
          })
      );

      setBoardPlans(newBoardPlans);
    };

    loadBoardPlans();
  }, [boardQuery.data, firestore, functions, userData?.organizationId]);

  const ownBoards = boardQuery.data?.filter(
    (board) => board.organizationId === userData?.organizationId
  );
  const sharedBoards = boardQuery.data?.filter(
    (board) => board.organizationId !== userData?.organizationId
  );

  const mutation = useFirestoreCollectionMutation(boardCollection);
  const handleAdd = async (boardName: string) => {
    if (
      !auth.currentUser ||
      !auth.currentUser.email ||
      !userData ||
      !userPlan
    ) {
      return;
    }

    const boardsCount =
      boardQuery.data?.filter(
        (board) => board.organizationId === userData.organizationId
      ).length ?? 0;

    if (boardsCount >= userPlan.boardsLimit) {
      await confirm({
        confirmationText: "Upgrade",
        title: `Number of boards exceeded for free plan`,
        description: `The free plan allows creating up to ${userPlan.boardsLimit} boards. To create an unlimited number of boards, please upgrade your plan.`,
      });
      navigate("/#pricing");
      return;
    }

    const userRoleObject = {
      role: CollaboratorRole.Owner,
      userEmail: auth.currentUser.email,
      userId: auth.currentUser.uid,
    };
    mutation.mutate({
      organizationId: userData.organizationId,
      name: boardName,
      dateCreated: dayjs(new Date()),
      startDate: dayjs(new Date()),
      daysPerUnit: 1,
      workDays: [1, 2, 3, 4, 5],
      collaboratorsByEmail: {
        // initially, the only collaborator is the owner
        [auth.currentUser.email]: userRoleObject,
      },
      collaboratorsById: {
        [auth.currentUser.uid]: userRoleObject,
      },
      groupByPerson: true,
      states: DEFAULT_STATES,
    });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    const boardName = boardQuery.data?.find((x) => x.id === id);
    await confirm({
      confirmationText: "Delete",
      title: `Delete Board "${boardName?.name}"`,
      description: `Are you sure you want to delete the board "${boardName?.name}" and all of its tasks?`,
    });
    await deleteDoc(doc(boardCollection, id as string));
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "stretch",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{ marginTop: 3, marginBottom: 3, color: "text.primary" }}
        >
          Your boards{" "}
          {userOrganization && userPlan && (
            <>
              - {userOrganization?.name}
              <IconButton onClick={() => navigate("/account")}>
                <Edit />
              </IconButton>
              ({userPlan?.displayName} plan)
            </>
          )}
        </Typography>
      </Box>

      <BoardsTable
        showToolbar
        toolbarProps={{ handleAdd: () => setOpen(true) }}
        rows={ownBoards ?? []}
        columns={ownBoardsColumns}
        loading={boardQuery.isLoading}
        emptyText='Please add a board by clicking on the "Add Board" button.'
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "stretch",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{ marginTop: 3, marginBottom: 3, color: "text.primary" }}
        >
          Boards you are a member of
        </Typography>
      </Box>

      <BoardsTable
        rows={sharedBoards ?? []}
        columns={sharedBoardsColumns}
        loading={boardQuery.isLoading}
        emptyText="You haven't been invited to any boards yet. Please ask your
                organization's administrator to add you to a board."
      />

      <Outlet />
      <NewBoardModal
        open={open}
        setOpen={setOpen}
        defaultValue={getNewBoardName()}
        handleAdd={handleAdd}
      />
      <Footer />
    </Container>
  );
}

interface BoardsTableProps {
  columns: GridColumns<IBoard>;
  rows: IBoard[];
  emptyText: string;
  loading: boolean;
  showToolbar?: boolean;
  toolbarProps?: {
    handleAdd: () => void;
  };
}

function BoardsTable({
  columns,
  rows,
  emptyText,
  loading,
  showToolbar,
  toolbarProps,
}: BoardsTableProps) {
  const navigate = useNavigate();

  return (
    <Paper sx={{ marginBottom: 1 }}>
      <DataGridPro<IBoard>
        sortingOrder={["asc", "desc"]}
        initialState={{
          sorting: {
            sortModel: [{ field: "dateCreated", sort: "desc" }],
          },
        }}
        sx={{
          "& .MuiDataGrid-row": {
            cursor: "pointer",
          },
        }}
        autoHeight
        disableColumnResize
        disableColumnPinning
        disableColumnMenu
        disableColumnReorder
        disableColumnFilter
        disableColumnSelector
        rows={rows}
        loading={loading}
        columns={columns}
        density="standard"
        onRowClick={(e) => navigate("/boards/" + e.id)}
        // experimentalFeatures={{ newEditingApi: true }}
        // editMode="row"
        disableSelectionOnClick
        // processRowUpdate={processRowUpdate}
        components={{
          Toolbar: showToolbar ? EditBoardToolbar : null,
          NoRowsOverlay: () => (
            <NoBoards showArrow={showToolbar}>{emptyText}</NoBoards>
          ),
        }}
        componentsProps={{
          toolbar: toolbarProps,
        }}
      />
    </Paper>
  );
}

interface NoBoardsProps {
  showArrow?: boolean;
  children: React.ReactNode;
}

function NoBoards({ showArrow, children }: NoBoardsProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        gap: 2,
        px: 2,
        position: "relative",
        textAlign: "center",
      }}
    >
      {showArrow && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 511.999 414.537"
          style={{
            width: 100,
            position: "absolute",
            top: 10,
            right: 60,
            opacity: 0.5,
          }}
        >
          <path d="M272.672 106.83c35.974-29.622 93.074-70.771 124.026-101.724C404.401.483 413.074-.958 421.124.61c8.291 1.606 15.868 6.316 21.079 13.928l1.587 2.598 68.209 134.816-23.269 11.733c-6.285-12.42-46.018-93.326-61.794-123.347-8.421 113.201-50.941 202.355-119.111 265.333C232.553 375.215 126.272 412.448.41 414.537L0 388.52c119.332-1.982 219.658-36.875 290.177-102.028 64.084-59.205 103.813-143.79 111.061-251.776l-112.032 92.217-16.534-20.103z" />
        </svg>
      )}
      <Typography>{children}</Typography>
    </Box>
  );
}
