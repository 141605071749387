import { Button, ButtonGroup, Typography } from "@mui/material";
import { Add, KeyboardArrowDown } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import { colorArray, ColorName } from "./colors";
import ListItemIcon from "@mui/material/ListItemIcon";
import ColorChip from "./ColorChip";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import MenuItem from "@mui/material/MenuItem";

interface IAddWorkItemDropdown {
  readOnly: boolean;
  handleAdd: (color?: ColorName) => void;
}
export default function AddWorkItemDropdown({
  readOnly,
  handleAdd,
}: IAddWorkItemDropdown) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        sx={{ display: { sm: "none", xs: "flex" } }}
        color="primary"
        startIcon={<Add />}
        onClick={(e) => handleAdd("Green")}
        disabled={readOnly}
        variant="contained"
      >
        Add
      </Button>
      <ButtonGroup
        sx={{ display: { sm: "flex", xs: "none" } }}
        disableElevation
        variant="contained"
        aria-label="Disabled elevation buttons"
      >
        <Button
          color="primary"
          startIcon={<Add />}
          onClick={(e) => handleAdd("Green")}
          disabled={readOnly}
        >
          Add Work Item
        </Button>
        <Button
          color="primary"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          disabled={readOnly}
        >
          <KeyboardArrowDown />
        </Button>
      </ButtonGroup>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          sx: {
            minWidth: 250,
          },
        }}
      >
        {colorArray.map((color) => (
          <MenuItem onClick={() => handleAdd(color)}>
            <ListItemIcon>
              <ColorChip color={color} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2">Add {color} Work Item</Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
