import { collection, deleteDoc, doc, getDocs, query } from "firebase/firestore";
import { commentConverter, IWorkItem, itemConverter } from "./converters";
import { useFirebase } from "./useFirebase";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { useParams } from "react-router";
import { useConfirm } from "material-ui-confirm";

export default function useDeleteWorkItem() {
  const { firestore } = useFirebase();
  const params = useParams();
  const confirm = useConfirm();

  return async function deleteWorkItem(
    item: IWorkItem,
    confirmBeforeDelete: boolean
  ) {
    const boardID = params.board;
    if (item.id === undefined || boardID === undefined) {
      return;
    }

    if (confirmBeforeDelete) {
      await confirm({
        confirmationText: "Delete",
        title: `Delete Work Item "${item.name}"`,
        description: `Are you sure you want to delete the work item "${item.name}"?`,
      });
    }

    const itemCollection = collection(
      firestore,
      "boards",
      boardID,
      "items"
    ).withConverter(itemConverter);

    await deleteDoc(doc(itemCollection, item.id));

    // delete all files from comments
    const commentCollection = collection(
      firestore,
      "boards",
      boardID,
      "items",
      item.id,
      "comments"
    ).withConverter(commentConverter);
    const commentRef = query(commentCollection);
    const querySnapshot = await getDocs(commentRef);
    const storage = getStorage();
    querySnapshot.forEach((doc) => {
      const commentData = doc.data();
      if (commentData.fileData) {
        const imageRef = ref(storage, commentData.fileData);
        deleteObject(imageRef);
      }
    });
  };
}
