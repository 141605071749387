import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { CssBaseline, ThemeProvider } from "@mui/material";

import "firebase/compat/auth";
import { FirebaseAuthProvider } from "./FirebaseAuthContext";
import MainRouter from "./MainRouter";
import { LicenseInfo } from "@mui/x-license-pro";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";

const LICENSE =
  "59fbcc22fe59c4f2d17484ad3ea3cedfTz01NzgzNixFPTE3MDU1MjA3NzM4MDIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=";

LicenseInfo.setLicenseKey(LICENSE);

Sentry.init({
  dsn: "https://95903fd0733e457e87e032f45f4b3662@o4504719012331520.ingest.sentry.io/4504720146694144",
  integrations: [new BrowserTracing(), new Sentry.Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  // disable Sentry on localhost
  enabled: process.env.NODE_ENV !== "development",
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <FirebaseAuthProvider>
        <MainRouter />
      </FirebaseAuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
