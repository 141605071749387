import React, { useEffect, useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import workplanScreenshot from "./images/workplan-screenshot.png";
import finalImage from "./images/undraw/undraw_Scrum_board_re_wk7v.png";
import visualizeCapacity from "./images/undraw/undraw_Calendar_re_ki49.png";
import tasks from "./images/undraw/undraw_completed_tasks_vs6q.png";
import collaborate from "./images/undraw/undraw_Collaboration_re_vyau.png";

import { useNavigate } from "react-router";
import { PeopleAlt, Splitscreen, TaskAlt } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import Page from "./Page";
import Pricing from "./Pricing";

// const ShadowBox = styled(Box)({
//   backgroundColor: "white",
//   boxShadow: "2px 2px 30px rgba(0, 0, 0, 0.1)",
//   borderRadius: 10,
//   overflow: "hidden",
//   padding: 30,
// });

// https://trial.wrike.com/resource-management
// https://www.freepik.com/serie/20285455
// https://www.freepik.com/free-vector/happy-people-avatars_7085154.htm#query=avatar&position=2&from_view=keyword

// https://www.freepik.com/free-vector/group-people-illustration-set_6969087.htm#page=3&position=45&from_view=author
export default function Website() {
  const navigate = useNavigate();
  const [mouseCoords, setMouseCoords] = useState<[number, number]>([0, 0]);
  useEffect(() => {
    const handler = (e: MouseEvent) => {
      setMouseCoords([
        e.clientX / window.innerWidth,
        e.clientY / window.innerHeight,
      ]);
    };
    document.addEventListener("mousemove", handler);
    return () => document.removeEventListener("mousemove", handler);
  }, []);

  return (
    <Page>
      <Box>
        <Container>
          <Grid container>
            <Grid item xs={12} md={5}>
              <Typography variant="h3" component="h1" sx={{ mb: 3, mt: 10 }}>
                Simple workforce planning.
              </Typography>

              <Typography variant="h6" component="h2" sx={{ mb: 3 }}>
                Plan your work by allocating your resources on a timeline,
                visualizing how work is distributed across your team, so you can
                know exactly what you can get done and by when.
              </Typography>

              <Button
                sx={{ mb: 5, fontSize: 20 }}
                variant="contained"
                size="large"
                onClick={() => navigate("/login")}
              >
                Sign Up for Free
              </Button>
            </Grid>
            <Grid item xs={12} md={7}>
              <img
                src={workplanScreenshot}
                style={{
                  width: "100%",
                  transform: `perspective(1000px) rotate3d(${
                    mouseCoords[1] * -4 + 1
                  }, -6, 1, ${(1 - mouseCoords[0]) * 40 - 10}deg)`,
                }}
              />

              {/*<ShadowBox>*/}
              {/*  <img*/}
              {/*    src={workplanIllustration}*/}
              {/*    style={{ width: "100%", paddingTop: 20 }}*/}
              {/*  />*/}
              {/*</ShadowBox>*/}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Divider />
      <Box sx={{ paddingY: 4, backgroundColor: "white" }}>
        <Container>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Box
                sx={{
                  minHeight: 350,
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <img src={visualizeCapacity} style={{ width: "100%" }} />
              </Box>
              <Typography
                variant="h5"
                component="h3"
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <Splitscreen style={{ color: "#50A329" }} /> Visualize Capacity
              </Typography>
              <Typography sx={{ mb: 2 }}>
                Visually plan your resource allocation by day, week, month, or
                any other unit of measure convenient to your project. See where
                assignments overlap, what the dependencies are, and what work
                needs to be reassigned.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Box
                sx={{
                  minHeight: 350,
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <img src={collaborate} style={{ width: "100%" }} />
              </Box>
              <Typography
                variant="h5"
                component="h3"
                sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}
              >
                <PeopleAlt style={{ color: "#50A329" }} /> Collaborate
              </Typography>
              <Typography sx={{ mb: 2 }}>
                Share boards with other team members, instantly. Collaborate in
                real time together - create, schedule and assign work items and
                tasks.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Box
                sx={{
                  minHeight: 350,
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <img
                  src={tasks}
                  style={{
                    width: "100%",
                  }}
                />
              </Box>
              <Typography
                variant="h5"
                component="h3"
                sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}
              >
                <TaskAlt style={{ color: "#50A329" }} /> Manage Tasks
              </Typography>
              <Typography sx={{ mb: 2 }}>
                Manage sub-tasks for each work item. Immediately create, assign,
                and resolve tasks so you can get work of any size or complexity
                managed easily.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Divider />

      <Pricing />

      <Box
        sx={{
          backgroundColor: "white",
          borderTop: "1px solid #ddd",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
            py: 5,
          }}
        >
          <Typography variant="h4">
            Ready to start planning with Workplan?
          </Typography>

          <Button
            variant="contained"
            size="large"
            onClick={() => navigate("/login")}
            sx={{ fontSize: 20 }}
          >
            Sign Up for Free
          </Button>

          <img src={finalImage} style={{ width: "100%", maxWidth: 500 }} />
        </Container>
      </Box>
    </Page>
  );
}
