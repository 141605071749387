import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  Dispatch,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  addDoc,
  collection,
  DocumentReference,
  updateDoc,
} from "firebase/firestore";
import {
  CardStatus,
  emailConverter,
  EmailTemplate,
  IWorkItem,
} from "./converters";
import {
  Add,
  Archive,
  Delete,
  Edit,
  TaskAlt,
  Unarchive,
} from "@mui/icons-material";
import {
  DataGridPro,
  GridColumns,
  GridRowModes,
  GridRowModesModel,
  GridSelectionModel,
  GridEventListener,
} from "@mui/x-data-grid-pro";
import { v4 } from "uuid";
import Checkbox from "@mui/material/Checkbox";
import { useDebounce } from "usehooks-ts";
import { colorArray, ColorName, getColor, getSelectedColor } from "./colors";
import AppDrawer from "./AppDrawer";
import { AppContext, IAppContext } from "./App";

import { useConfirm } from "material-ui-confirm";
import CommentBox from "./CommentBox";
import CollaboratorsDropdown from "./CollaboratorsDropdown";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import WorkItemStatus from "./WorkItemStatus";
import ItemDateRangeSelector from "./ItemDateRangeSelector";
import CollaboratorsDropdownSubtask from "./CollaboratorsDropdownSubtask";
import { useAuth } from "./FirebaseAuthContext";
import { useNavigate } from "react-router";
import IconButton from "@mui/material/IconButton";
import useArchiveWorkItem from "./useArchiveWorkItem";
import Dependencies from "./Dependencies";
import CommentMention from "./CommentMention";
import { getEmailsFromIds, getTextFromMentionable } from "./utils";
import { useFirebase } from "./useFirebase";
import useCollaborators from "./useCollaborators";
import useUpdateStatus from "./useUpdateStatus";
import Tooltip from "@mui/material/Tooltip";

export interface ITask {
  id: string;
  name: string;
  complete: boolean;
  owner?: string;
  dateCreated: string;
  collaborators: string[];
}

interface IItemDrawer {
  open: boolean;
  setOpen: Dispatch<boolean>;
  item: IWorkItem;
  itemDocument: DocumentReference<IWorkItem>;
  data: IWorkItem[];
  editable: boolean;
}

interface IColorPicker {
  color: ColorName;
  selected: boolean;
  onSelect: () => void;
  editable: boolean;
}

function ColorPicker({ color, selected, onSelect, editable }: IColorPicker) {
  return (
    <Box
      onClick={onSelect}
      sx={{
        width: 30,
        height: 30,
        borderRadius: 1,
        backgroundColor: selected ? getSelectedColor(color) : getColor(color),
        borderColor: selected ? "divider" : getColor(color),
        borderWidth: 2,
        borderStyle: "solid",
        cursor: editable ? "pointer" : "default",
        "&:hover": {
          backgroundColor: getSelectedColor(color),
        },
      }}
    />
  );
}

export default function ItemDrawer({
  open,
  setOpen,
  item,
  itemDocument,
}: IItemDrawer) {
  const { data, editable, boardData, boardPlan } = useContext(
    AppContext
  ) as IAppContext;
  const { userData } = useAuth();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { auth, firestore } = useFirebase();
  const userList = useCollaborators();
  const [tasks, setTasks] = useState<ITask[]>(item.tasks);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);

  const [itemName, setItemName] = useState<string>(item.name);
  const [itemDescription, setItemDescription] = useState<string>(
    item.description
  );
  const [itemCategory, setItemCategory] = useState<string>(item.category);
  const [itemTrack, setItemTrack] = useState<string>(item.track);
  const handleArchive = useArchiveWorkItem();
  const itemNameDebounced = useDebounce(itemName, 1000);
  const itemDescriptionDebounced = useDebounce(itemDescription, 1000);
  const taskNameRef = useRef<HTMLInputElement>(null);
  const updateStatus = useUpdateStatus();

  useEffect(() => {
    if (open) {
      setItemName(item.name);
      setItemDescription(item.description);
      setItemCategory(item.category);
      setItemTrack(item.track);
      setTasks(item.tasks);
    }

    setTimeout(() => {
      if (
        open &&
        taskNameRef.current &&
        document.activeElement?.tagName === "DIV"
      ) {
        taskNameRef.current.focus();
      }
    }, 0);
  }, [open, taskNameRef]);

  useEffect(() => {
    const tokens = Array.from(item.name.matchAll(/\w+/g), (m) => m[0]);
    history.replaceState(null, "", "#" + tokens.join("-").toLowerCase());
  }, [open, item]);

  const addTask = async () => {
    if (!userData || !boardPlan) {
      return;
    }
    if (tasks.length >= boardPlan.subtasksPerWorkItemLimit) {
      await confirm({
        confirmationText: "Upgrade",
        title: `Number of subtasks exceeded for free plan`,
        description: `The free plan allows creating up to ${boardPlan.subtasksPerWorkItemLimit} subtasks. To create an unlimited number of subtasks on a work item, the board owner's plan should be upgraded.`,
      });
      navigate("/#pricing");
      return;
    }

    const newTask: ITask = {
      id: v4(),
      name: "",
      complete: false,
      dateCreated: new Date().toISOString(),
      collaborators: [],
    };
    setTasks([...tasks, newTask]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newTask.id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  const removeTask = () => {
    if (selectionModel.length === 0) {
      return;
    }
    setTasks(tasks.filter((task) => task.id !== selectionModel[0]));
  };

  const taskColumns: GridColumns<ITask> = [
    {
      field: "name",
      headerName: "Task",
      flex: 1,
      editable,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{
            textDecoration: params.row.complete ? "line-through" : "inherit",
            color: params.row.complete ? "text.secondary" : "inherit",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "collaborators",
      headerName: "Assignees",
      width: 120,
      renderCell: (params) => (
        <CollaboratorsDropdownSubtask item={item} task={params.row} />
      ),
    },
    {
      field: "complete",
      headerName: "Done",
      width: 80,
      type: "boolean",
      editable,
      renderCell: (params) => {
        return (
          <Box>
            <Checkbox
              disabled={!editable}
              checked={params.value}
              onClick={async () => {
                const newTasks = tasks.map((x) =>
                  x.id === params.id ? { ...x, complete: !x.complete } : x
                );
                setTasks(newTasks);

                if (newTasks.every((row) => row.complete)) {
                  try {
                    await confirm({
                      confirmationText: "Complete",
                      title: `Mark Task as Complete`,
                      description: `All subtasks were marked complete. Would you like to mark the task "${item.name}" as complete as well?`,
                    });
                    await updateStatus(item, "done");
                    setOpen(false);
                  } catch (error) {
                    console.log("cancelled to mark task as complete");
                  }
                }
              }}
            />
          </Box>
        );
      },
    },
    // {
    //   field: "owner",
    //   headerName: "Owner",
    //   editable: true,
    // },
  ];

  useEffect(() => {
    if (!itemDocument) {
      return;
    }
    updateDoc(itemDocument, {
      name: itemNameDebounced,
      description: itemDescriptionDebounced,
      category: itemCategory,
      track: itemTrack,
      tasks: tasks,
    });
    // itemDocument is required by react-hooks/exhaustive-deps but it broke item editing in some cases
    // TODO: this needs a proper fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    itemNameDebounced,
    itemDescriptionDebounced,
    itemCategory,
    itemTrack,
    tasks,
  ]);

  const taskUpdate = async (newRow: ITask) => {
    setTasks(
      tasks
        .map((task) => (task.id === newRow.id ? newRow : task))
        .filter((task) => task.name.length > 0)
    );
    return newRow;
  };

  const updateRowOrder: GridEventListener<"rowOrderChange"> = (params) => {
    const newTasks = tasks.filter((x, index) => index !== params.oldIndex);
    newTasks.splice(params.targetIndex, 0, params.row as ITask);
    setTasks(newTasks);
  };

  const categories = [...new Set(data.map((x) => x.category as string))];
  const tracks = [...new Set(data.map((x) => x.track as string))];

  return (
    <AppDrawer
      open={open}
      setOpen={setOpen}
      closeOnEnter={() => false}
      title={editable ? "Edit Work Item" : "Work Item Details"}
      icon={editable ? <Edit /> : <TaskAlt />}
      minWidth={600}
      titleSide={
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <FormControl fullWidth size="small">
            <InputLabel>Status</InputLabel>
            <Select
              readOnly={!editable}
              size="small"
              label="Status"
              sx={{ width: 150 }}
              value={item.status}
              onChange={(e) => updateStatus(item, e.target.value as CardStatus)}
            >
              {boardData.states.map((state) => (
                <MenuItem key={state} value={state}>
                  <WorkItemStatus status={state} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {item.archived ? (
            <Tooltip title="Unarchive">
              <IconButton
                onClick={() => updateDoc(itemDocument, { archived: false })}
              >
                <Unarchive />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Archive">
              <IconButton onClick={() => handleArchive(item)}>
                <Archive />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          flexGrow: 1,
          pt: 1,
          height: "100%",
        }}
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <TextField
            tabIndex={1}
            inputRef={taskNameRef}
            label="Task name"
            fullWidth
            size="small"
            placeholder="Enter task name"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            inputProps={{ readOnly: !editable }}
          />
          <Box>
            <CollaboratorsDropdown item={item} />
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography>Color</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            {colorArray
              .filter((c) => editable || c === item.color)
              .map((c) => (
                <ColorPicker
                  key={c}
                  color={c}
                  selected={c === item.color}
                  onSelect={() => updateDoc(itemDocument, { color: c })}
                  editable={editable}
                />
              ))}
          </Box>
        </Box>

        <TextField
          InputLabelProps={{ shrink: true }}
          value={itemDescription}
          onChange={(e) => setItemDescription(e.target.value)}
          rows={4}
          label="Description"
          multiline
          fullWidth
          placeholder={editable ? "Enter task description..." : ""}
          InputProps={{
            inputComponent: CommentMention,
            inputProps: {
              onChange: (e: any) => setItemDescription(e.target.value),
              value: itemDescription,
              onAdd: (id: string) => {
                if (!userData?.email) {
                  return;
                }
                const mentionedEmails = getEmailsFromIds(
                  [id],
                  userList,
                  userData.email
                );
                const emailCollection = collection(
                  firestore,
                  "emails"
                ).withConverter(emailConverter);
                addDoc(emailCollection, {
                  to: mentionedEmails,
                  template: {
                    name: EmailTemplate.Mention,
                    data: {
                      itemName: item.name,
                      comment: getTextFromMentionable(itemDescription),
                      itemId: item.id ?? "",
                      boardId: boardData.id as string,
                      userName: auth.currentUser?.displayName as string,
                    },
                  },
                });
              },
            },
          }}
          inputProps={{
            readOnly: !editable,
            style: { fontSize: 14 },
          }}
        />

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box>
              {editable ? (
                <Autocomplete<string, false, false, true>
                  size="small"
                  freeSolo
                  fullWidth
                  value={itemCategory}
                  options={categories}
                  renderInput={(params) => (
                    <TextField
                      label="Team"
                      {...params}
                      onBlur={(e) => setItemCategory(e.target.value)}
                    />
                  )}
                  onChange={(e, value) => setItemCategory(value ?? "None")}
                />
              ) : (
                <TextField
                  label="Team"
                  size="small"
                  fullWidth
                  value={itemCategory}
                  inputProps={{ readOnly: true }}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              {editable ? (
                <Autocomplete<string, false, false, true>
                  size="small"
                  freeSolo
                  fullWidth
                  value={itemTrack}
                  options={tracks}
                  renderInput={(params) => (
                    <TextField
                      label="Track"
                      {...params}
                      onBlur={(e) => setItemTrack(e.target.value)}
                    />
                  )}
                  onChange={(e, value) => setItemTrack(value ?? "None")}
                />
              ) : (
                <TextField
                  label="Track"
                  size="small"
                  fullWidth
                  value={itemTrack}
                  inputProps={{ readOnly: true }}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <ItemDateRangeSelector item={item} type="range" />
          </Grid>
          <Grid item xs={12} md={12}>
            <Dependencies item={item} />
          </Grid>
        </Grid>

        <Box sx={{ gap: 1, display: "flex" }}>
          {editable && (
            <Button variant="contained" onClick={addTask} startIcon={<Add />}>
              Add Task
            </Button>
          )}
          {editable && (
            <Button
              variant="outlined"
              color="error"
              onClick={removeTask}
              startIcon={<Delete />}
              disabled={selectionModel.length === 0}
            >
              Remove Task
            </Button>
          )}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <DataGridPro<ITask>
            rowReordering={editable}
            disableColumnReorder
            disableColumnMenu
            disableColumnFilter
            disableColumnResize
            disableColumnPinning
            density="compact"
            columns={taskColumns}
            rows={tasks}
            sx={{ height: "100%", width: "100%", minHeight: 200 }}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={setRowModesModel}
            selectionModel={selectionModel}
            onSelectionModelChange={setSelectionModel}
            experimentalFeatures={{ newEditingApi: true }}
            processRowUpdate={taskUpdate}
            onRowOrderChange={updateRowOrder}
            hideFooter
          />
        </Box>
        <Box>
          <CommentBox item={item} />
        </Box>
      </Box>
    </AppDrawer>
  );
}
