import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useNavigate } from "react-router";

export function PaymentSuccessDialog() {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/boards", { replace: true });
  };

  return (
    <Dialog onClose={handleClose} open>
      <DialogTitle>Thank you for upgrading your account!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You can now enjoy all the benefits and features that come with your
          subscription.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
}
