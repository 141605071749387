import * as React from "react";
import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import useCollaborators from "./useCollaborators";
import UserAvatar from "./UserAvatar";
import { Checkbox, List, ListItem, ListItemButton } from "@mui/material";
import { emailConverter, EmailTemplate, IUser, IWorkItem } from "./converters";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import CollaboratorsPreview from "./CollaboratorsPreview";
import ListItemText from "@mui/material/ListItemText";
import { PersonAdd } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { useFirebase } from "./useFirebase";
import { AppContext, IAppContext } from "./App";
import { useAuth } from "./FirebaseAuthContext";

export default function CollaboratorsDropdown({ item }: { item: IWorkItem }) {
  const boardCollaborators = useCollaborators();
  const navigate = useNavigate();
  const { firestore, auth } = useFirebase();
  const { userData } = useAuth();
  const { boardData, itemCollection, editable } = useContext(
    AppContext
  ) as IAppContext;
  const itemDocument = doc(itemCollection, item.id);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = async (user: IUser) => {
    if (!editable) {
      return;
    }

    let collaborators;
    if (item.collaborators.includes(user.id as string)) {
      collaborators = item.collaborators.filter((id) => id !== user.id);
    } else {
      collaborators = [...item.collaborators, user.id];
      // send invitation email
      if (user.id !== userData?.id) {
        const emailCollection = collection(firestore, "emails").withConverter(
          emailConverter
        );
        await addDoc(emailCollection, {
          to: [user.email],
          template: {
            name: EmailTemplate.WorkItemAssigned,
            data: {
              itemName: item.name,
              itemId: itemDocument.id,
              boardId: boardData.id as string,
              userName: auth.currentUser?.displayName as string,
            },
          },
        });
      }
    }
    updateDoc(itemDocument, { collaborators });
  };

  return (
    <React.Fragment>
      <Box
        onClick={handleClick}
        sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
      >
        <CollaboratorsPreview collaborators={item.collaborators} disableLink />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          sx: {
            minWidth: 250,
          },
        }}
      >
        <List sx={{ bgcolor: "background.paper" }} dense>
          {boardCollaborators.map((user) => {
            return (
              <ListItem key={user.id} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={() => handleToggle(user)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      disabled={!editable}
                      size="small"
                      checked={
                        user.id !== undefined &&
                        item.collaborators.includes(user.id)
                      }
                    />
                  </ListItemIcon>
                  <UserAvatar userId={user.id} />
                </ListItemButton>
              </ListItem>
            );
          })}
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("sharing")}>
              <ListItemIcon>
                <PersonAdd style={{ marginLeft: 5 }} />
              </ListItemIcon>
              <ListItemText>Invite collaborators...</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Menu>
    </React.Fragment>
  );
}
