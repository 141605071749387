import { Box, Modal, Typography } from "@mui/material";
import { Image } from "@mui/icons-material";
import React from "react";

export default function PasteModal({
  pastingImage,
}: {
  pastingImage: boolean;
}) {
  return (
    <Modal open={pastingImage}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          color: "whitesmoke",
        }}
      >
        <Image fontSize="large" />
        <Typography variant="h6">Pasting Image...</Typography>
      </Box>
    </Modal>
  );
}
