import { Avatar, Box, Typography } from "@mui/material";
import {
  useFirestoreDocumentData,
  useFirestoreQueryData,
} from "@react-query-firebase/firestore";
import { IUser, userConverter } from "./converters";
import { collection, doc, query, where } from "firebase/firestore";
import { useFirebase } from "./useFirebase";

export default function UserAvatar({
  userId,
  userEmail,
  allowDisplayEmail,
}: {
  userId?: string;
  userEmail?: string;
  allowDisplayEmail?: boolean;
}) {
  const { firestore } = useFirebase();

  let data: IUser | null = null;
  if (userId !== undefined) {
    const ref = doc(firestore, "users", userId).withConverter(userConverter);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const userData = useFirestoreDocumentData<IUser>(["users", userId], ref);
    data = userData.data ?? null;
  } else if (userEmail !== undefined) {
    const userCollection = collection(firestore, "users").withConverter(
      userConverter
    );
    const ref = query(userCollection, where("email", "==", userEmail));
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const userQuery = useFirestoreQueryData(["users", { userEmail }], ref);
    data = userQuery.data?.[0] ?? null;
  }

  let displayName = data?.displayName || "";
  // if the user does not exist, show the email address if allowed
  if (allowDisplayEmail && data === null) {
    displayName = userEmail || "";
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1, flexGrow: 1 }}>
      <Avatar
        // imgProps is here to prevent getting a 403 error from Google
        // https://stackoverflow.com/questions/56242788/http-403-on-images-loaded-from-googleusercontent-com
        imgProps={{ referrerPolicy: "no-referrer" }}
        src={data?.photoURL ?? undefined}
        alt={displayName}
        sx={{ width: 26, height: 26 }}
      />
      <Typography
        variant="body2"
        sx={{
          flexGrow: 1,
          whiteSpace: "pre",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: 0,
        }}
      >
        {userId === "Unassigned" ? "Unassigned" : displayName}
      </Typography>
    </Box>
  );
}
