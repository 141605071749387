import { MentionsInput, Mention, SuggestionDataItem } from "react-mentions";
import useCollaborators from "./useCollaborators";
import * as React from "react";
import { InputBaseComponentProps } from "@mui/material/InputBase/InputBase";
import "./mentions.css";
import { useState } from "react";
import { useTheme } from "@mui/material";

export default function CommentMention(
  props: InputBaseComponentProps & { onAdd?: (id: string) => void }
) {
  const collaborators = useCollaborators();
  const [mentioned, setMentioned] = useState<string[]>([]);

  const onAdd = (id: string | number, display: string) => {
    setMentioned([...mentioned, id as string]);
    if (props.onAdd) {
      props.onAdd(id as string);
    }
  };

  const data: SuggestionDataItem[] = collaborators.map(
    (x) =>
      ({
        id: x.id,
        display: x.displayName,
      } as SuggestionDataItem)
  );

  const theme = useTheme();

  // value looks like ron reiter @[Ron Reiter](6EopsTCOVJXUXPtcYkC9uQT0rPu2)
  return (
    <MentionsInput
      className="mentions"
      style={{
        width: "100%",
        border: "none",
        outline: "none",
        minHeight: 150,
      }}
      value={props.value}
      onChange={(e) => {
        if (props.onChange) {
          props.onChange(e as any);
        }
      }}
      placeholder={props.placeholder}
    >
      <Mention trigger="@" data={data} onAdd={onAdd} appendSpaceOnAdd />
    </MentionsInput>
  );
}
