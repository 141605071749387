import { Dayjs } from "dayjs";
import {
  DateRange,
  DateRangePicker,
} from "@mui/x-date-pickers-pro/DateRangePicker";
import { doc, updateDoc } from "firebase/firestore";
import React, { useContext } from "react";
import { AppContext } from "./App";
import { Box, TextField } from "@mui/material";
import { IWorkItem } from "./converters";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

export default function ItemDateRangeSelector({
  item,
  type,
}: {
  item: IWorkItem;
  type: "range" | "start" | "end";
}) {
  const appContextData = useContext(AppContext);
  if (!appContextData) {
    return <Box />;
  }
  const { dateRanges, itemCollection, editable } = appContextData;
  const itemDocument = doc(itemCollection, item.id);
  const unitToDate = (unit: number): Dayjs => {
    return dateRanges[unit - 1];
  };

  const dateToUnit = (date: Dayjs | null): number => {
    if (date === null) {
      return -1;
    }
    for (let i = 0; i < dateRanges.length; i++) {
      if (date.diff(dateRanges[i]) === 0) {
        return i;
      }
    }
    return -1;
  };

  const disableDate = (day: Dayjs) => {
    return !dateRanges.some((d) => day.diff(d) === 0);
  };

  const handleDateUpdate = (value: DateRange<Dayjs>) => {
    updateDoc(itemDocument, {
      start: dateToUnit(value[0]) + 1,
      end: dateToUnit(value[1]) + 1,
    });
  };

  const handleStartDate = (value: Dayjs | null) => {
    updateDoc(itemDocument, {
      start: dateToUnit(value) + 1,
    });
  };
  const handleEndDate = (value: Dayjs | null) => {
    updateDoc(itemDocument, {
      end: dateToUnit(value) + 1,
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {type === "range" ? (
        <DateRangePicker
          readOnly={!editable}
          inputFormat="MM/DD/YYYY"
          value={[unitToDate(item.start), unitToDate(item.end)]}
          shouldDisableDate={disableDate}
          onChange={handleDateUpdate}
          renderInput={(startProps, endProps) => (
            <>
              <TextField fullWidth size="small" {...startProps} />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField fullWidth size="small" {...endProps} />
            </>
          )}
        />
      ) : (
        <DatePicker
          inputFormat="MM/DD/YYYY"
          value={unitToDate(type === "start" ? item.start : item.end)}
          shouldDisableDate={disableDate}
          onChange={type === "start" ? handleStartDate : handleEndDate}
          renderInput={(props) => (
            <>
              <TextField fullWidth size="small" {...props} />
            </>
          )}
        />
      )}
    </LocalizationProvider>
  );
}
