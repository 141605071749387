import { Delete, PersonOff, PersonAdd } from "@mui/icons-material";
import { Box, Container, Paper, Typography } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
} from "@mui/x-data-grid-pro";
import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import {
  collection,
  doc,
  updateDoc,
  deleteField,
  deleteDoc,
  FieldPath,
  query,
  getDocs,
  where,
} from "firebase/firestore";
import { useConfirm } from "material-ui-confirm";
import { NotFound } from "./404";
import AppToolbar from "./AppToolbar";
import {
  boardConverter,
  CollaboratorRole,
  itemConverter,
  IUser,
  userConverter,
} from "./converters";
import { useAuth } from "./FirebaseAuthContext";
import { useFirebase } from "./useFirebase";
import { useRemoveCollaborator } from "./useRemoveCollaborator";

export function Admin() {
  const { firestore } = useFirebase();
  const confirm = useConfirm();
  const { userData } = useAuth();
  const removeCollaborator = useRemoveCollaborator();

  const userCollection = collection(firestore, "users").withConverter(
    userConverter
  );
  const usersQuery = useFirestoreQueryData<IUser>(["users"], userCollection, {
    subscribe: true,
  });

  const columns: GridColumns<IUser> = [
    {
      field: "displayName",
      headerName: "Name",
      type: "string",
      flex: 1,
      minWidth: 100,
      filterable: true,
      renderCell: (params) => {
        return <Typography variant="body2">{params.value}</Typography>;
      },
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      minWidth: 250,
      filterable: true,
      renderCell: (params) => {
        return <Typography variant="body2">{params.value}</Typography>;
      },
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      type: "dateTime",
      renderCell: (params) => (
        <Typography variant="body2">
          {params.value.toDate().toDateString()}
        </Typography>
      ),
      minWidth: 170,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 120,
      cellClassName: "actions",
      getActions: ({ row }) => [
        row.enabled !== false ? (
          <GridActionsCellItem
            icon={<PersonOff />}
            label="Deactivate"
            onClick={() => handleDeactivate(row)}
            color="inherit"
          />
        ) : (
          <GridActionsCellItem
            icon={<PersonAdd />}
            label="Activate"
            onClick={() => handleActivate(row)}
            color="inherit"
          />
        ),
        <GridActionsCellItem
          icon={<Delete />}
          label="Delete"
          onClick={() => handleDelete(row)}
          color="inherit"
        />,
      ],
    },
  ];

  const handleDelete = async (user: IUser) => {
    await confirm({
      confirmationText: "Delete",
      title: "Delete User",
      description: `Are you sure you want to delete ${user.displayName}?`,
    });

    // delete the organization's boards
    const boardsCollection = collection(firestore, "boards").withConverter(
      boardConverter
    );
    const orgBoardsQuery = query(
      boardsCollection,
      where("organizationId", "==", user.organizationId)
    );
    const orgBoardsSnapshot = await getDocs(orgBoardsQuery);
    for (const board of orgBoardsSnapshot.docs) {
      await deleteDoc(board.ref);
      const itemCollection = collection(
        firestore,
        "boards",
        board.id as string,
        "items"
      ).withConverter(itemConverter);
      const itemsSnapshot = await getDocs(itemCollection);
      for (const item of itemsSnapshot.docs) {
        await deleteDoc(item.ref);
      }
    }

    // delete the organization
    const orgCollection = collection(firestore, "organizations");
    const orgRef = doc(orgCollection, user.organizationId);
    await deleteDoc(orgRef);

    // remove the user as a collaborator from all boards
    const fieldPath = new FieldPath("collaboratorsByEmail", user.email, "role");
    const boardsQuery = query(
      boardsCollection,
      where(fieldPath, ">=", CollaboratorRole.Viewer)
    );
    const boardsSnapshot = await getDocs(boardsQuery);
    for (const board of boardsSnapshot.docs) {
      const boardData = board.data();
      const itemCollection = collection(
        firestore,
        "boards",
        board.id as string,
        "items"
      ).withConverter(itemConverter);
      const itemsSnapshot = await getDocs(itemCollection);
      const items = itemsSnapshot.docs.map((item) => item.data());
      await removeCollaborator(boardData, items, user.email);
    }

    // delete from the users collection
    const userRef = doc(userCollection, user.id as string);
    await deleteDoc(userRef);
  };

  const handleDeactivate = async (user: IUser) => {
    await confirm({
      confirmationText: "Deactivate",
      title: "Deactivate User",
      description: `Are you sure you want to deactivate ${user.displayName}?`,
    });

    const userRef = doc(userCollection, user.id);
    await updateDoc(userRef, {
      enabled: false,
    });
  };

  const handleActivate = async (user: IUser) => {
    await confirm({
      confirmationText: "Activate",
      title: "Activate User",
      description: `Are you sure you want to activate ${user.displayName}?`,
    });

    const userRef = doc(userCollection, user.id);
    await updateDoc(userRef, {
      enabled: deleteField(),
    });
  };

  // don't render anything before we know if the user is an admin
  if (!userData) {
    return null;
  }

  // display 404 if the user is not an admin
  if (userData && !userData.isAdmin) {
    return <NotFound />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        minHeight: "100vh",
        backgroundColor: "background.default",
      }}
    >
      <AppToolbar />
      <Container
        maxWidth="md"
        sx={{ display: "flex", flexDirection: "column", flex: 1, gap: 2 }}
      >
        <Typography
          variant="h4"
          sx={{ marginTop: 3, marginBottom: 3, color: "text.primary" }}
        >
          Users
        </Typography>

        <Paper sx={{ marginBottom: 5 }}>
          <DataGridPro<IUser>
            initialState={{
              sorting: {
                sortModel: [{ field: "dateCreated", sort: "asc" }],
              },
            }}
            autoHeight
            disableColumnResize
            disableColumnPinning
            disableColumnReorder
            disableColumnSelector
            rows={usersQuery.data ?? []}
            loading={usersQuery.isLoading}
            columns={columns}
            density="standard"
            disableSelectionOnClick
          />
        </Paper>
      </Container>
    </Box>
  );
}
