import AppToolbar from "./AppToolbar";
import React, { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./FirebaseAuthContext";
import { ColorModeContext } from "./MainRouter";

export default function MainDashboard() {
  const { user, loadingUser } = useAuth();
  const { setInApp } = useContext(ColorModeContext);
  // this code allows changing the hash, but doesn't have context to what.
  // it should be possible to do some sort of state callback mechanism
  // const location = useLocation();
  // const params = useParams();

  // useEffect(() => {
  //   const matches = matchRoutes(routes, location);
  //
  //   if (matches) {
  //     const match = matches[matches.length - 1];
  //     console.log(match, match.route.path, match.params);
  //     if (match.route.path === "/boards/:board/items/:item") {
  //       document.location.hash = "foo-bar";
  //     }
  //   }
  // }, [location, params]);
  useEffect(() => {
    setInApp(true);
    return () => setInApp(false);
  }, []);

  if (!loadingUser && !user) {
    return <Navigate to="/login" />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        minHeight: "100vh",
        backgroundColor: "background.default",
      }}
    >
      <AppToolbar sticky />
      {user && <Outlet />}
    </Box>
  );
}
