import * as React from "react";
import StarIcon from "@mui/icons-material/StarBorder";
import {
  Container,
  Typography,
  Grid,
  CardHeader,
  CardContent,
  CardActions,
  Card,
  Box,
  ListItem,
  List,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Check } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import { useAuth } from "./FirebaseAuthContext";
import { useStripe } from "./useStripe";
import LoadingButton from "@mui/lab/LoadingButton";
import { PricingPlanName, pricingPlans } from "./config";

type ButtonText =
  | "Sign up for free"
  | "Get Started"
  | "Change"
  | "Upgrade"
  | "Current Plan";
interface Tier {
  title: string;
  stripeRole: string;
  price: number;
  description: string[];
  buttonTextGuest: ButtonText;
  buttonTextFree: ButtonText;
  buttonTextBasic: ButtonText;
  buttonTextPro: ButtonText;
  buttonVariant: "outlined" | "contained";
  featured?: boolean;
  buttonLink?: string;
  stripePriceIdMonthly?: string;
  stripePriceIdAnnual?: string;
}

const [freePlan, basicPlan, proPlan] = Object.values(pricingPlans);

const tiers: Tier[] = [
  {
    title: freePlan.displayName,
    stripeRole: "free",
    price: 0,
    description: [
      `${freePlan.boardsLimit} boards`,
      `${freePlan.editorsPerBoardLimit} editors per board`,
      `${freePlan.workItemsPerBoardLimit} items per board`,
      `${freePlan.subtasksPerWorkItemLimit} subtasks per item`,
      "E-mail support",
    ],
    buttonTextGuest: "Sign up for free",
    buttonTextFree: "Current Plan",
    buttonTextBasic: "Sign up for free",
    buttonTextPro: "Sign up for free",
    buttonVariant: "outlined",
    buttonLink: "/login",
  },
  {
    title: basicPlan.displayName,
    stripeRole: "basic",
    price: 25,
    description: [
      "Unlimited boards",
      `${basicPlan.editorsPerBoardLimit} editors per board`,
      "Unlimited items",
      "Unlimited subtasks",
      "Priority E-mail support",
    ],
    buttonTextGuest: "Get Started",
    buttonTextFree: "Upgrade",
    buttonTextBasic: "Current Plan",
    buttonTextPro: "Change",
    featured: true,
    buttonVariant: "contained",
    stripePriceIdMonthly: "price_1MfC19B6lBcB8PcPa40ycX1o",
    stripePriceIdAnnual: "price_1MfC19B6lBcB8PcPVySMV7dr",
  },
  {
    title: proPlan.displayName,
    stripeRole: "pro",
    price: 50,
    description: [
      "Unlimited boards",
      `${proPlan.editorsPerBoardLimit} editors per board`,
      "Unlimited items",
      "Unlimited subtasks",
      "Priority E-mail support",
    ],
    buttonTextGuest: "Get Started",
    buttonTextFree: "Upgrade",
    buttonTextBasic: "Upgrade",
    buttonTextPro: "Current Plan",
    buttonVariant: "contained",
    stripePriceIdMonthly: "price_1MfC2EB6lBcB8PcPOohwVNxp",
    stripePriceIdAnnual: "price_1MfC2EB6lBcB8PcPpC3I0CU4",
  },
  // {
  //   title: "Enterprise",
  //   stripeRole: "enterprise",
  //   price: 15,
  //   description: [
  //     "Unlimited boards",
  //     "Unlimited items",
  //     "Phone & email support",
  //     "Organization management & SSO",
  //   ],
  //   buttonText: "Contact us",
  //   buttonVariant: "outlined",
  // },
];

export default function Pricing() {
  const navigate = useNavigate();
  const [clickedButtonTier, setClickedButtonTier] = useState<string | null>(
    null
  );
  const [pricingCadence, setPricingCadence] = useState<string>("monthly");
  const { user, userPlan, loadingUser } = useAuth();
  const { createCheckoutSession } = useStripe();

  // this useEffect is to fix a bug with scrolling to anchors
  useEffect(() => {
    const hash = window.location.hash;
    if (hash.length > 0) {
      window.location.hash = "";
      window.location.hash = hash;
    }
  }, []);

  const handlePricingCadence = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setPricingCadence(newAlignment);
  };

  const handleTierButtonClick = async (tier: Tier) => {
    if (tier.buttonLink) {
      navigate(tier.buttonLink);
      return;
    }

    if (!tier.stripePriceIdMonthly || !tier.stripePriceIdAnnual) {
      return;
    }

    // redirect unauthorized users to login
    if (!user) {
      navigate("/login");
      return;
    }

    setClickedButtonTier(tier.stripeRole);

    const priceId =
      pricingCadence === "monthly"
        ? tier.stripePriceIdMonthly
        : tier.stripePriceIdAnnual;
    const session = await createCheckoutSession(priceId);
    window.location.assign(session.url);
  };

  return (
    <React.Fragment>
      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pt: 6, pb: 6 }}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          gutterBottom
          id="pricing"
        >
          Pricing
        </Typography>
        <Typography variant="h5" align="center" component="p">
          Start with the free tier and upgrade as your team grows. Our free tier
          will be free forever.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main" sx={{ pb: 6 }}>
        <Box sx={{ display: "flex", justifyContent: "center", pb: 3 }}>
          <ToggleButtonGroup
            sx={{ backgroundColor: "background.paper" }}
            color="primary"
            value={pricingCadence}
            exclusive
            onChange={handlePricingCadence}
            aria-label="Platform"
          >
            <ToggleButton value="monthly">Monthly</ToggleButton>
            <ToggleButton value="annual">Annual</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Grid container spacing={4}>
          {tiers.map((tier) => {
            let isCurrentPlan = false;
            if (userPlan?.name === tier.stripeRole) {
              isCurrentPlan = true;
            }

            let buttonText = tier.buttonTextGuest;
            if (userPlan !== null) {
              const planNameToButtonTextMap = {
                [PricingPlanName.Free]: tier.buttonTextFree,
                [PricingPlanName.Basic]: tier.buttonTextBasic,
                [PricingPlanName.Pro]: tier.buttonTextPro,
              };
              buttonText = planNameToButtonTextMap[userPlan.name];
            }

            const buttonLoading = clickedButtonTier === tier.stripeRole;
            const buttonDisabled =
              loadingUser ||
              buttonLoading ||
              isCurrentPlan ||
              (userPlan !== null && userPlan.name !== PricingPlanName.Free);

            return (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={tier.title === "Enterprise" ? 12 : 6}
                md={4}
              >
                <Card
                  sx={{ display: "flex", flexDirection: "column", height: 400 }}
                >
                  <CardHeader
                    title={tier.title}
                    titleTypographyProps={{ align: "center" }}
                    action={tier.featured ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: "center",
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="h3"
                        color="text.primary"
                      >
                        ${tier.price * (pricingCadence === "monthly" ? 1 : 10)}
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                        /user
                      </Typography>
                    </Box>
                    <List>
                      {tier.description.map((line) => (
                        <ListItem disablePadding key={line}>
                          <ListItemIcon>
                            <Check />
                          </ListItemIcon>
                          <ListItemText primary={line} />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                  <CardActions>
                    <LoadingButton
                      loading={buttonLoading}
                      fullWidth
                      disabled={buttonDisabled}
                      variant={tier.buttonVariant as "outlined" | "contained"}
                      onClick={() => handleTierButtonClick(tier)}
                    >
                      {buttonText}
                    </LoadingButton>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
