import AppToolbar from "./AppToolbar";
import React, { ReactNode } from "react";
import { Box, Container } from "@mui/material";
import Footer from "./Footer";

export default function Page({ children }: { children: ReactNode }) {
  return (
    <Box sx={{ backgroundColor: "white" }}>
      <AppToolbar />
      <Box sx={{ paddingY: 8 }}>{children}</Box>
      <Footer />
    </Box>
  );
}
