import {
  addDoc,
  collection,
  doc,
  DocumentReference,
  updateDoc,
  Transaction,
} from "firebase/firestore";
import {
  IWorkItem,
  CardStatus,
  IUser,
  EmailTemplate,
  emailConverter,
} from "./converters";
import { useFirebase } from "./useFirebase";
import { useParams } from "react-router";
import { useContext } from "react";
import { AppContext, IAppContext } from "./App";
import { getEmailsFromIds } from "./utils";
import { stateTitle } from "./Kanban";
import { useAuth } from "./FirebaseAuthContext";

export default function useUpdateStatus() {
  const { itemCollection, boardData } = useContext(AppContext) as IAppContext;
  const { firestore, auth } = useFirebase();
  const params = useParams();
  const { userData } = useAuth();

  return async function updateStatus(
    item: IWorkItem,
    status: CardStatus,
    transaction?: Transaction
  ) {
    const boardID = params.board;
    if (
      item.id === undefined ||
      boardID === undefined ||
      boardData === undefined
    ) {
      return;
    }

    const docRef = doc(itemCollection, item.id);
    if (transaction) {
      transaction.update(docRef, {
        status: status,
      });
    } else {
      await updateDoc(docRef, {
        status: status,
      });
    }

    if (item.status !== status) {
      const userList: IUser[] = Object.values(
        boardData.collaboratorsByEmail
      ).map(
        (x: any) =>
          ({
            id: x.userId,
            email: x.userEmail,
          } as IUser)
      );

      if (!userData) {
        return;
      }
      const to = getEmailsFromIds(item.collaborators, userList, userData.email);
      const emailCollection = collection(firestore, "emails").withConverter(
        emailConverter
      );
      await addDoc(emailCollection, {
        to: to,
        template: {
          name: EmailTemplate.Transition,
          data: {
            itemName: item.name,
            prevStatus: stateTitle[item.status],
            itemStatus: stateTitle[status],
            itemId: item.id,
            boardId: boardData.id as string,
            userName: auth.currentUser?.displayName as string,
          },
        },
      });
    }
  };
}
