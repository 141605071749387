import EditToolbar from "./EditToolbar";
import {
  GridActionsCellItem,
  GridColumns,
  GridRowId,
  GridRowModes,
  GridSelectionModel,
  DataGridPro,
} from "@mui/x-data-grid-pro";
import React, { SyntheticEvent, useContext, useState } from "react";
import {
  AddTask,
  ArchiveOutlined,
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  DeleteOutline,
  EditOutlined,
  MoreHoriz,
} from "@mui/icons-material";
import { GridEventListener } from "@mui/x-data-grid/models/events";
import { colorArray, ColorName, getColor, getStrongColor } from "./colors";
import { Autocomplete, Box, Menu, TextField, Typography } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { GridRenderEditCellParams } from "@mui/x-data-grid/models/params/gridCellParams";
import { doc, runTransaction, setDoc } from "firebase/firestore";
import { IWorkItem } from "./converters";
import { AppContext, IAppContext } from "./App";
import { useNavigate, useParams } from "react-router";
import Tooltip from "@mui/material/Tooltip";
import { useConfirm } from "material-ui-confirm";
import CollaboratorsDropdown from "./CollaboratorsDropdown";
import xlsx, { IColumn } from "json-as-xlsx";
import useGanttData from "./useGanttData";
import useCollaborators from "./useCollaborators";
import WorkItemStatus from "./WorkItemStatus";
import ItemDateRangeSelector from "./ItemDateRangeSelector";
import useDeleteWorkItem from "./useDeleteWorkItem";
import { stateTitle } from "./Kanban";
import useUpdateStatus from "./useUpdateStatus";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useFirebase } from "./useFirebase";

interface IItemTable {
  handleAdd: (color?: ColorName) => void;
  handleDelete: (id: GridRowId) => void;
  handleArchive: (id: GridRowId) => void;
  handleArchiveCompleted: () => void;
  disabled: boolean;
  drawerHeight: number;
  setDrawerHeight: (newDH: number) => void;
}

export default function ItemTable({
  handleAdd,
  handleDelete,
  handleArchive,
  handleArchiveCompleted,
  disabled,
  drawerHeight,
  setDrawerHeight,
}: IItemTable) {
  const {
    data,
    completedExist,
    itemCollection,
    selectionModel,
    setSelectionModel,
    rowModesModel,
    setRowModesModel,
    dateRanges,
    boardData,
    itemTableApiRef,
  } = useContext(AppContext) as IAppContext;

  const { firestore } = useFirebase();
  const navigate = useNavigate();
  const params = useParams();
  const confirm = useConfirm();
  const ganttData = useGanttData();
  const collaborators = useCollaborators();
  const deleteWorkItem = useDeleteWorkItem();

  const renderEditCellFunction = (params: GridRenderEditCellParams<string>) => {
    const options = [
      ...new Set(data.map((x) => x[params.field as keyof IWorkItem] as string)),
    ];
    const changeHandler = (changeEvent: SyntheticEvent, v: string | null) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const apiRef = useGridApiContext();

      if (v === null) {
        return;
      }
      apiRef.current.setEditCellValue({
        id: params.row.id,
        field: params.field,
        value: v,
      });
    };
    return (
      <Autocomplete<string, false, false, true>
        freeSolo
        fullWidth
        value={params.value}
        options={options}
        renderInput={(params) => <TextField {...params} />}
        onChange={changeHandler}
      />
    );
  };

  const updateStatus = useUpdateStatus();
  const handleToggleComplete = async (item: IWorkItem | undefined) => {
    if (!item) {
      return;
    }
    await updateStatus(item, item.status === "done" ? "todo" : "done");
  };

  const states = Object.keys(stateTitle);

  const columns: GridColumns<IWorkItem> = [
    {
      field: "name",
      headerName: "Name",
      // editable: !disabled,
      flex: 1,
      minWidth: 200,
      renderCell: (e) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                backgroundColor: getColor(e.row.color),
                width: 20,
                height: 20,
                marginRight: 1,
                borderRadius: 1,
                borderColor: getStrongColor(e.row.color),
                borderWidth: 1,
                borderStyle: "solid",
              }}
            />
            <Typography
              variant="body2"
              sx={{
                color:
                  e.row.status === "done" ? "text.secondary" : "text.primary",
                textDecorationLine:
                  e.row.status === "done" ? "line-through" : "inherit",
              }}
            >
              {e.value}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => <WorkItemStatus status={params.row.status} />,
      sortable: true,
      valueGetter: (params) => params.row.status,
      sortComparator: (v1: string, v2: string) =>
        states.indexOf(v1) - states.indexOf(v2),
    },
    // {
    //   field: 'start',
    //   headerName: 'Start',
    //   width: 90,
    //   editable: true,
    //   type: 'number',
    // },
    // {
    //   field: 'end',
    //   headerName: 'End',
    //   width: 90,
    //   editable: true,
    //   type: 'number',
    // },
    {
      field: "collaborators",
      headerName: "Collaborators",
      width: 120,
      sortable: false,
      renderCell: (params) => <CollaboratorsDropdown item={params.row} />,
    },
    {
      field: "color",
      headerName: "Color",
      width: 120,
      // editable: !disabled,
      type: "singleSelect",
      valueOptions: colorArray,
    },
    {
      field: "category",
      headerName: "Team",
      width: 150,
      // editable: !disabled,
      renderEditCell: renderEditCellFunction,
    },
    {
      field: "track",
      headerName: "Track",
      width: 150,
      // editable: !disabled,
      type: "string",
      renderEditCell: renderEditCellFunction,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      // editable: true,
      width: 150,
      type: "date",
      valueGetter: (params) =>
        dateRanges ? dateRanges[params.row.start - 1] : undefined,
      valueFormatter: (params) => params.value.toDate().toDateString(),
      renderEditCell: (params) => (
        <ItemDateRangeSelector item={params.row} type="start" />
      ),
    },
    {
      field: "endDate",
      headerName: "End Date",
      // editable: true,
      width: 150,
      type: "date",
      valueGetter: (params) =>
        dateRanges ? dateRanges[params.row.end].add(-1, "day") : undefined,
      valueFormatter: (params) => params.value.toDate().toDateString(),
      renderEditCell: (params) => (
        <ItemDateRangeSelector item={params.row} type="end" />
      ),
    },
    {
      field: "dateCreated",
      headerName: "Created At",
      width: 150,
      type: "date",
      valueFormatter: (params) => params.value.toDate().toDateString(),
      renderEditCell: (params) => (
        <ItemDateRangeSelector item={params.row} type="end" />
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 50,
      cellClassName: "actions",
      getActions: ({ id }) => {
        // const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        // const item = data.find((x) => x.id === id);

        // if (isInEditMode) {
        //   return [
        //     <GridActionsCellItem
        //       icon={<Save />}
        //       label="Save"
        //       onClick={handleSaveClick(id)}
        //     />,
        //     <GridActionsCellItem
        //       icon={<Cancel />}
        //       label="Cancel"
        //       className="textPrimary"
        //       onClick={handleCancelClick(id)}
        //       color="inherit"
        //     />,
        //   ];
        // }

        return [
          <Tooltip title="More">
            <GridActionsCellItem
              icon={<MoreHoriz />}
              label="More"
              onClick={(e) => handleMore(id, e)}
              color="inherit"
            />
          </Tooltip>,
        ];
      },
    },
  ];

  // hide the actions column when editing is disabled
  if (disabled) {
    columns.pop();
  }

  const processRowUpdate = async (newRow: IWorkItem) => {
    const ref = doc(itemCollection, newRow.id);
    await setDoc(ref, newRow);
    return newRow;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleExpand = () => {
    if (drawerHeight === 0) {
      setDrawerHeight(350);
    } else {
      setDrawerHeight(0);
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuItem, setMenuItem] = useState<undefined | IWorkItem>();
  const moreOpen = Boolean(anchorEl);

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuItem(undefined);
  };

  const handleMore = (
    id: GridRowId,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setMenuItem(data.find((item) => item.id === id));
  };

  const selectCell: GridEventListener<"cellClick"> = (e) => {
    if (e.field === "actions") {
      return;
    }
    setSelectionModel([e.row.id]);
  };

  // useEffect(() => {
  //   if (!editing || !editing.id) {
  //     return;
  //   }
  //   // I have no idea why this is needed
  //   const id = editing.id;
  //   setRowModesModel((oldModel) => ({
  //     ...oldModel,
  //     [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
  //   }));
  // }, [editing]);

  const handleArchiveMulti = async (archive: boolean) => {
    if (selectionModel.length === 0) {
      return;
    }
    if (selectionModel.length > 1) {
      const action = archive ? "Archive" : "Un-Archive";
      const actionLower = archive ? "archive" : "un-archive";
      await confirm({
        confirmationText: action,
        title: `${action} ${selectionModel.length} Work Items`,
        description: `Are you sure you want to ${actionLower} ${selectionModel.length} work items?`,
      });
    } else {
      const item = data.find((x) => x.id === selectionModel[0]);
      if (!item) {
        return;
      }
    }

    await runTransaction(firestore, async (transaction) => {
      selectionModel.forEach((selection) => {
        const item = data.find((x) => x.id === selection);
        if (item === undefined) {
          return;
        }
        transaction.update(doc(itemCollection, item.id), { archived: archive });
      });
    });
  };

  const handleDeleteMulti = async () => {
    if (selectionModel.length === 0) {
      return;
    }
    if (selectionModel.length > 1) {
      await confirm({
        confirmationText: "Delete",
        title: `Delete ${selectionModel.length} Work Items`,
        description: `Are you sure you want to delete ${selectionModel.length} work items?`,
      });
    } else {
      const item = data.find((x) => x.id === selectionModel[0]);
      if (!item) {
        return;
      }
    }

    selectionModel.forEach((selection) => {
      const item = data.find((x) => x.id === selection);
      if (item === undefined) {
        return;
      }
      deleteWorkItem(item, selectionModel.length === 1);
    });

    // setData((oldRows) => oldRows.filter((row) => !selectionModel.includes(row.id)))
  };

  const replaceWithUserName = (track: string) => {
    return collaborators.find((c) => c.id === track)?.displayName ?? track;
  };
  const handleExport = async () => {
    if (!ganttData) {
      return;
    }
    const rows = [];
    const header: IColumn[] = [
      { label: "Team", value: "team" },
      { label: "Track", value: "track" },
    ];
    for (let i = 0; i < dateRanges.length; i++) {
      header.push({
        value: `col${i}`,
        label: dateRanges[i].toDate().toDateString(),
      });
    }
    for (let i = 0; i < ganttData.length; i++) {
      for (let j = 0; j < ganttData[i].tracks.length; j++) {
        const row: { [key: string]: string } = {
          team: ganttData[i].category,
          track: replaceWithUserName(ganttData[i].tracks[j].track),
        };
        for (let k = 0; k < dateRanges.length; k++) {
          const itemInCol = ganttData[i].tracks[j].data.find(
            (workItem) => workItem.start <= k + 1 && workItem.end >= k + 1
          );
          row[`col${k}`] = itemInCol?.name ?? "";
        }
        rows.push(row);
      }
    }

    const spreadsheet = [
      {
        sheet: "Tasks",
        columns: [
          { label: "Name", value: "name" },
          { label: "Collaborators", value: "collaborators" },
          { label: "Color", value: "color" },
          { label: "Team", value: "category" },
          { label: "Track", value: "track" },
          { label: "Start Date", value: "start_date" },
          { label: "End Date", value: "end_date" },
          { label: "Date Created", value: "date_created" },
          { label: "Status", value: "status" },
        ],
        content: data.map((d) => ({
          name: d.name,
          collaborators: d.collaborators.map(replaceWithUserName).join(", "),
          color: d.color,
          category: d.category,
          track: d.track,
          status: stateTitle[d.status],
          start_date: dateRanges[d.start - 1].toDate(),
          end_date: dateRanges[d.end - 1].toDate(),
          date_created: d.dateCreated.toDate(),
        })),
      },
      {
        sheet: "Workplan",
        columns: header,
        content: rows,
      },
    ];

    const settings = {
      fileName: boardData.name,
      // A bigger number means that columns will be wider
      extraLength: 3,
      // The available parameters are 'WriteFile' and 'write'.
      // This setting is optional. Useful in such cases
      // https://docs.sheetjs.com/docs/solutions/output#example-remote-file
      writeMode: "writeFile",
      // Style options from https://docs.sheetjs.com/docs/api/write-options
      writeOptions: {},
      RTL: false,
    };

    xlsx(spreadsheet, settings); // Will download the excel file
    console.log("export");
  };
  return (
    <>
      <DataGridPro<IWorkItem>
        sortingOrder={["asc", "desc"]}
        initialState={{
          sorting: {
            sortModel: [{ field: "dateCreated", sort: "asc" }],
          },
          columns: {
            columnVisibilityModel: {
              color: false,
              track: false,
            },
          },
        }}
        apiRef={itemTableApiRef}
        sx={{ flexGrow: 1 }}
        density="compact"
        rows={data}
        columns={columns}
        pageSize={50}
        checkboxSelection
        onSelectionModelChange={(newSelectionModel: GridSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        disableSelectionOnClick
        hideFooter
        // experimentalFeatures={{ newEditingApi: true }}
        // editMode="row"
        // rowModesModel={rowModesModel}
        // onRowModesModelChange={(newModel: GridRowModesModel) =>
        //   setRowModesModel(newModel)
        // }
        components={{
          Toolbar: EditToolbar,
          NoRowsOverlay: NoWorkItems,
        }}
        componentsProps={{
          toolbar: {
            selectionModel,
            handleAdd,
            handleArchive: handleArchiveMulti,
            handleDelete: handleDeleteMulti,
            handleArchiveCompleted,
            handleExport,
            handleExpand,
            expanded: drawerHeight > 0,
            readOnly: disabled,
            completedExist,
          },
        }}
        // onCellEditCommit={(e, event, details) => console.log(e, event, details)}
        onCellClick={selectCell}
        // processRowUpdate={processRowUpdate}
        onRowDoubleClick={(cell) =>
          navigate(`/boards/${params.board}/items/${cell.id}`)
        }
      />
      <Menu
        anchorEl={anchorEl}
        open={moreOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            minWidth: 250,
          },
        }}
      >
        {menuItem && (
          <>
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                handleToggleComplete(menuItem);
              }}
            >
              <ListItemIcon>
                {menuItem?.status === "done" ? (
                  <CheckBoxOutlined />
                ) : (
                  <CheckBoxOutlineBlank />
                )}
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">Mark as complete</Typography>
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                navigate(`/boards/${params.board}/items/${menuItem.id}`);
              }}
            >
              <ListItemIcon>
                <EditOutlined />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">Edit</Typography>
              </ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleCloseMenu();
                handleArchive(menuItem.id as GridRowId);
              }}
            >
              <ListItemIcon>
                <ArchiveOutlined />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">Archive</Typography>
              </ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleCloseMenu();
                handleDelete(menuItem.id as GridRowId);
              }}
            >
              <ListItemIcon>
                <DeleteOutline />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">Delete</Typography>
              </ListItemText>
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
}

function NoWorkItems() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        gap: 2,
      }}
    >
      <AddTask sx={{ fontSize: 60, color: "#ccc" }} />

      <Typography>
        Please add work items by clicking on the "Add Work Item" button.
      </Typography>
    </Box>
  );
}
