import { GridSelectionModel, GridToolbarContainer } from "@mui/x-data-grid-pro";
import { Box, Button, ListItem, Typography } from "@mui/material";
import {
  Archive,
  Delete,
  ExpandLess,
  ExpandMore,
  FileDownload,
  Unarchive,
} from "@mui/icons-material";
import React, { Dispatch, SetStateAction } from "react";
import { IWorkItem } from "./converters";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ColorName } from "./colors";
import IconButton from "@mui/material/IconButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

interface EditToolbarProps {
  selectionModel: GridSelectionModel;
  setEditing: Dispatch<SetStateAction<IWorkItem | undefined>>;
  handleAdd: (color?: ColorName) => void;
  handleExpand: () => void;
  expanded: boolean;
  handleArchive: (archive: boolean) => void;
  handleDelete: () => void;
  handleExport: () => void;
  readOnly: boolean;
  handleArchiveCompleted: () => void;
  completedExist: boolean;
}

export default function EditToolbar({
  selectionModel,
  handleArchive,
  handleDelete,
  handleArchiveCompleted,
  handleExport,
  handleExpand,
  expanded,
  readOnly,
  completedExist,
}: EditToolbarProps) {
  return (
    <GridToolbarContainer sx={{ gap: 1, padding: 2 }}>
      {/*<Button*/}
      {/*  color="error"*/}
      {/*  variant="outlined"*/}
      {/*  startIcon={<Delete />}*/}
      {/*  onClick={handleDelete}*/}
      {/*  disabled={readOnly || selectionModel.length === 0}*/}
      {/*>*/}
      {/*  Delete*/}
      {/*</Button>*/}

      <FormControl size="small">
        <InputLabel>Actions</InputLabel>
        <Select<"archive" | "delete" | "">
          disabled={readOnly || selectionModel.length === 0}
          size="small"
          label="Actions"
          sx={{ width: 200 }}
          value={""}
          onChange={(
            e: SelectChangeEvent<"archive" | "un-archive" | "delete" | "">
          ) => {
            if (e.target.value === "archive") {
              handleArchive(true);
            } else if (e.target.value === "un-archive") {
              handleArchive(false);
            } else if (e.target.value === "delete") {
              handleDelete();
            }
          }}
        >
          <MenuItem value="archive">
            <ListItem dense disableGutters>
              <ListItemIcon>
                <Archive />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">Archive Selected</Typography>
              </ListItemText>
            </ListItem>
          </MenuItem>
          <MenuItem value="un-archive">
            <ListItem dense disableGutters>
              <ListItemIcon>
                <Unarchive />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">Un-Archive Selected</Typography>
              </ListItemText>
            </ListItem>
          </MenuItem>
          <MenuItem value="delete">
            <ListItem dense disableGutters>
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">Delete Selected</Typography>
              </ListItemText>
            </ListItem>
          </MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ display: "flex", flexGrow: 1 }}></Box>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<Archive />}
        onClick={handleArchiveCompleted}
        disabled={readOnly || !completedExist}
        sx={{ display: { xs: "none" } }}
      >
        Archive Completed
      </Button>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<FileDownload />}
        onClick={handleExport}
        sx={{ display: { xs: "none" } }}
      >
        Export
      </Button>
      <IconButton onClick={handleExpand}>
        {expanded ? <ExpandMore /> : <ExpandLess />}
      </IconButton>
    </GridToolbarContainer>
  );
}
