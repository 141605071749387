import { useAuth } from "./FirebaseAuthContext";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "./App";
import { IUser } from "./converters";
import { getDocs, query, where } from "firebase/firestore";

export default function useCollaborators() {
  const { userCollection } = useAuth();
  const appContextData = useContext(AppContext);
  const [userList, setUserList] = useState<IUser[]>([]);

  useEffect(() => {
    (async () => {
      if (!appContextData || !userCollection) {
        return;
      }
      const { boardData } = appContextData;
      const collaborators = Object.keys(boardData.collaboratorsByEmail);

      const newUserList: IUser[] = [];
      for (let i = 0; i < collaborators.length; i += 10) {
        const q = query(
          userCollection,
          where("email", "in", collaborators.slice(i, i + 10))
        );
        const users = await getDocs(q);
        users.forEach((user) => newUserList.push(user.data()));
      }
      setUserList(newUserList);
    })();
  }, [appContextData?.boardData.collaboratorsByEmail]);

  return userList;
}
