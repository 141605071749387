import React from "react";
import { Container, Typography } from "@mui/material";
import Page from "./Page";

export default function PrivacyPolicy() {
  return (
    <Page>
      <Container
        maxWidth="md"
        sx={{ display: "flex", flexDirection: "column", gap: 3 }}
      >
        <Typography variant="h3" component="h1">
          Workplan Privacy Policy
        </Typography>

        <Typography>
          At Workplan (a Shevat Ventures LLC service), we understand the
          importance of protecting the privacy of our users' personal
          information. We have created this privacy policy to explain how we
          collect, use, and protect your personal information when you use our
          service.
        </Typography>

        <Typography variant="h5">Collection of Personal Information</Typography>

        <Typography>
          We collect personal information from you when you register for an
          account, use our service, or communicate with us. This information may
          include your name, email address, and other contact information. We
          may also collect information about your use of our service, such as
          the features you use, the pages you visit, and the time you spend on
          our service.
        </Typography>

        <Typography variant="h5">Use of Personal Information</Typography>

        <Typography>
          We use your personal information to provide you with our service, to
          communicate with you, and to improve our service. We may use your
          personal information to send you emails or other communications about
          our service, to respond to your inquiries, or to send you promotional
          materials. We may also use your personal information to personalize
          your experience on our service, such as by showing you content that we
          think you may be interested in.
        </Typography>

        <Typography variant="h5">Protection of Personal Information</Typography>

        <Typography>
          We take the protection of your personal information seriously and have
          implemented appropriate technical and organizational measures to
          protect your personal information from unauthorized access, use, or
          disclosure. We use industry-standard encryption to protect your
          personal information when it is transmitted over the internet.
        </Typography>

        <Typography variant="h5">Access to Personal Information</Typography>

        <Typography>
          You have the right to access and correct your personal information at
          any time. You can access your personal information by logging into
          your account and updating your profile information. If you have any
          questions about your personal information or this privacy policy,
          please contact us at a@shevat.us
        </Typography>

        <Typography variant="h5">Changes to Privacy Policy</Typography>

        <Typography>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on our website.
          You are advised to review this Privacy Policy periodically for any
          changes.
        </Typography>

        <Typography>Effective date: January 16, 2023</Typography>

        <Typography variant="h5">Contact Us</Typography>
        <Typography>
          If you have any questions or concerns about our Privacy Policy, please
          contact us at a@shevat.us
        </Typography>
      </Container>
    </Page>
  );
}
