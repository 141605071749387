import { createTheme, PaletteMode } from "@mui/material";
import { grey } from "@mui/material/colors";

const lightMode = {
  mode: "light" as PaletteMode,
  primary: {
    light: "hsl(100.9,60%,50%)",
    main: "hsl(100.9,60%,40%)",
    dark: "hsl(100.9,60%,35%)",
  },
  background: {
    default: grey[50],
  },
};

const darkMode = {
  mode: "dark" as PaletteMode,
  primary: {
    light: "hsl(100.9,60%,50%)",
    main: "hsl(100.9,60%,40%)",
    dark: "hsl(100.9,60%,35%)",
  },
  text: {
    primary: grey[50],
  },
  background: {
    paper: "#1e1e1e",
  },
};

const workplanTheme = (mode: PaletteMode) =>
  createTheme({
    typography: {
      fontFamily: ["Manrope", "sans-serif"].join(","),
      fontWeightRegular: 500,
      fontWeightBold: 900,
    },
    palette: mode === "light" ? lightMode : darkMode,
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          disableElevation: true,
          outlined: {
            fontWeight: "bold",
          },
          contained: {
            // color: theme.palette.getContrastText(theme.palette.error.main),
            color: "white",
            fontWeight: "bold",
            boxShadow: "none",
            ":hover": {
              boxShadow: "none",
            },
          },
        },
      },
      MuiAppBar: {
        defaultProps: {
          elevation: 1,
        },
      },
    },
  });

export default workplanTheme;
