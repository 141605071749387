import { Box, Typography, useTheme } from "@mui/material";
import Category from "./Category";
import { useContext, useEffect, useRef } from "react";
import { IWorkItem } from "./converters";
import { AppContext, IAppContext } from "./App";
import useGanttData from "./useGanttData";

export interface CategoryData {
  category: string;
  tracks: TrackData[];
}

export interface TrackData {
  category: string;
  track: string;
  data: IWorkItem[];
}

export default function Gantt() {
  const {
    dateRanges,
    maxSections,
    workDaysSinceStart,
    boardData,
    sectionWidth,
  } = useContext(AppContext) as IAppContext;

  const scrollContainer = useRef<HTMLDivElement>();
  const ganttData = useGanttData();
  const theme = useTheme();
  const gradient =
    theme.palette.mode === "light"
      ? "repeating-linear-gradient(\n" +
        "  45deg,\n" +
        "  rgba(0, 0, 0, 0.05),\n" +
        "  rgba(0, 0, 0, 0.05) 10px,\n" +
        "  rgba(0, 0, 0, 0.1) 10px,\n" +
        "  rgba(0, 0, 0, 0.1) 20px\n" +
        ")"
      : "repeating-linear-gradient(\n" +
        "  45deg,\n" +
        "  rgba(255, 255, 255, 0.05),\n" +
        "  rgba(255, 255, 255, 0.05) 10px,\n" +
        "  rgba(255, 255, 255, 0.1) 10px,\n" +
        "  rgba(255, 255, 255, 0.1) 20px\n" +
        ")";

  const teamWidth = 130;
  const trackWidth = 170;

  useEffect(() => {
    if (scrollContainer.current && workDaysSinceStart && sectionWidth) {
      scrollContainer.current.scrollLeft = Math.max(
        0,
        (workDaysSinceStart - 1) * sectionWidth
      );
    }
  }, [scrollContainer, workDaysSinceStart, sectionWidth]);

  return (
    <Box
      sx={{
        overflow: "scroll",
        flexGrow: 1,
        userSelect: "none",
        position: "relative",
      }}
      ref={scrollContainer}
    >
      <Box
        sx={{
          width: maxSections * sectionWidth + teamWidth + trackWidth,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: teamWidth + trackWidth,
            width: (workDaysSinceStart / boardData.daysPerUnit) * sectionWidth,
            height: "100%",
            zIndex: 0,
            background: gradient,
          }}
        />
        <Box
          sx={{
            position: "sticky",
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            borderBottom: 1,
            borderColor: "divider",
            zIndex: 3,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "text.primary",
              width: teamWidth,
              paddingX: 1,
              backgroundColor: "background.paper",
            }}
          >
            Team
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "text.primary",
              width: trackWidth,
              paddingX: 1,
              backgroundColor: "background.paper",
            }}
          >
            {boardData.groupByPerson ? "Collaborator" : "Track"}
          </Typography>
          {dateRanges.map((i) => (
            <Typography
              key={i.toISOString()}
              variant="overline"
              sx={{
                color: "text.primary",
                width: sectionWidth,
                borderLeft: "1px solid",
                borderLeftColor: "divider",
                textAlign: "center",
                backgroundColor: "background.paper",
                zIndex: 1,
              }}
            >
              {i.format("D/M")}
            </Typography>
          ))}
        </Box>
        {ganttData?.map((category) => (
          <Category
            teamWidth={teamWidth}
            trackWidth={trackWidth}
            category={category}
            key={category.category}
            scale={sectionWidth}
            scrollContainer={scrollContainer}
          />
        ))}
      </Box>
    </Box>
  );
}
