import useCollaborators from "./useCollaborators";
import React from "react";
import { Box } from "@mui/material";
import UserAvatar from "./UserAvatar";
import Tooltip from "@mui/material/Tooltip";
import { Person } from "@mui/icons-material";
import { Link } from "react-router-dom";

const style = {
  cursor: "pointer",
  color: "text.secondary",
  backgroundColor: "background.paper",
  backgroundSize: "contain",
  width: 32,
  height: 32,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "-10px",
  borderWidth: 1,
  borderColor: "divider",
  borderStyle: "solid",
};

export interface CollaboratorsPreviewProps {
  collaborators?: string[];
  disableLink?: boolean;
}

export default function CollaboratorsPreview({
  collaborators,
  disableLink = false,
}: CollaboratorsPreviewProps) {
  const userList = useCollaborators();
  const filtered = collaborators
    ? userList.filter((x) => x.id && collaborators.includes(x.id))
    : userList;

  return (
    <Link
      to={!disableLink ? "sharing" : "#"}
      style={{
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{ display: "flex", marginRight: "10px", textDecoration: "none" }}
      >
        {filtered.length === 0 && (
          <Box sx={style}>
            <Person />
          </Box>
        )}
        {filtered.map((user, index) => (
          // (user) => user.id && <UserAvatar key={user.id} userId={user.id} />
          <Tooltip key={user.id} title={user.displayName}>
            <Box
              sx={{
                ...style,
                backgroundImage: `url("${user.photoURL}")`,
              }}
            >
              {!user.photoURL &&
                (user.displayName ? user.displayName[0] : <Person />)}
            </Box>
          </Tooltip>
        ))}
      </Box>
    </Link>
  );
}
