import { RouterProvider } from "react-router";
import React, { Dispatch, SetStateAction, useState } from "react";
import { createBrowserRouter } from "react-router-dom";
import MainDashboard from "./MainDashboard";
import Boards from "./Boards";
import App from "./App";
import SignIn from "./SignIn";
import Website from "./Website";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import MyAccount from "./MyAccount";
import { PaymentSuccessDialog } from "./PaymentSuccessDialog";
import { NotFound } from "./404";
import {
  createTheme,
  CssBaseline,
  PaletteMode,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import workplanTheme from "./workplanTheme";
import { ConfirmProvider } from "material-ui-confirm";
import { useLocalStorage } from "usehooks-ts";
import { Admin } from "./Admin";

export const routes = [
  {
    path: "/*",
    element: <NotFound />,
  },
  {
    index: true,
    element: <Website />,
  },
  {
    path: "/terms",
    element: <TermsOfService />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/",
    element: <MainDashboard />,
    children: [
      {
        path: "/boards",
        element: <Boards />,
        children: [
          {
            path: "/boards/payment-success",
            element: <PaymentSuccessDialog />,
          },
        ],
      },
      {
        path: "/account",
        element: <MyAccount />,
      },
      {
        path: "/boards/:board",
        element: <App />,
        children: [
          {
            path: "/boards/:board/settings",
          },
          {
            path: "/boards/:board/sharing",
          },
          {
            path: "/boards/:board/items/:item",
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <SignIn />,
    children: [
      {
        path: "/login/:board",
      },
    ],
  },
  {
    path: "/admin",
    element: <Admin />,
  },
];

export type ColorMode = "light" | "dark" | "preference";

interface IColorModeContext {
  mode: ColorMode;
  setMode: (mode: ColorMode) => void;
  inApp: boolean;
  setInApp: Dispatch<SetStateAction<boolean>>;
}
export const ColorModeContext = React.createContext<IColorModeContext>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMode: () => {},
  mode: "preference",
  inApp: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setInApp: () => {},
});

export default function MainRouter() {
  const [inApp, setInApp] = useState<boolean>(false);
  const [mode, setMode] = useLocalStorage<ColorMode>(
    "colorTheme",
    "preference"
  );
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(() => {
    const preference: PaletteMode = prefersDarkMode ? "dark" : "light";
    const paletteMode: PaletteMode = mode === "preference" ? preference : mode;
    return workplanTheme(inApp ? paletteMode : "light");
  }, [mode, prefersDarkMode, inApp]);

  return (
    <ColorModeContext.Provider value={{ mode, setMode, inApp, setInApp }}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <RouterProvider router={createBrowserRouter(routes)} />
        </ConfirmProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
