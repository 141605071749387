export interface PricingPlan {
  name: PricingPlanName;
  displayName: string;
  boardsLimit: number;
  workItemsPerBoardLimit: number;
  subtasksPerWorkItemLimit: number;
  editorsPerBoardLimit: number;
  allowTaskAssignees: boolean;
}

export enum PricingPlanName {
  Free = "free",
  Basic = "basic",
  Pro = "pro",
}

export const pricingPlans: {
  [key in PricingPlanName]: PricingPlan;
} = {
  [PricingPlanName.Free]: {
    name: PricingPlanName.Free,
    displayName: "Free",
    boardsLimit: 5,
    workItemsPerBoardLimit: 20,
    subtasksPerWorkItemLimit: 20,
    editorsPerBoardLimit: 5,
    allowTaskAssignees: false,
  },
  [PricingPlanName.Basic]: {
    name: PricingPlanName.Basic,
    displayName: "Basic",
    boardsLimit: Infinity,
    workItemsPerBoardLimit: Infinity,
    subtasksPerWorkItemLimit: Infinity,
    editorsPerBoardLimit: 10,
    allowTaskAssignees: true,
  },
  [PricingPlanName.Pro]: {
    name: PricingPlanName.Pro,
    displayName: "Pro",
    boardsLimit: Infinity,
    workItemsPerBoardLimit: Infinity,
    subtasksPerWorkItemLimit: Infinity,
    editorsPerBoardLimit: 25,
    allowTaskAssignees: true,
  },
};
