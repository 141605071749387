import { AppBar, Box, Toolbar } from "@mui/material";
import Logo from "./Logo";
import AccountMenu from "./AccountMenu";

interface AppToolbarProps {
  sticky?: boolean;
}

export default function AppToolbar({ sticky }: AppToolbarProps) {
  const position = sticky ? "sticky" : "static";

  return (
    <AppBar
      position={position}
      color="default"
      sx={{
        backgroundColor: "background.paper",
        borderBottom: "1px solid",
        borderBottomColor: "divider",
        boxShadow: "none",
      }}
    >
      <Toolbar variant="dense">
        <Box sx={{ flexGrow: 1 }}>
          <Logo />
        </Box>
        <AccountMenu />
      </Toolbar>
    </AppBar>
  );
}
