import {
  Box,
  Grid,
  Slider,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { Dispatch, useContext, useEffect, useState } from "react";
import { updateDoc } from "firebase/firestore";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import WeekdaysPicker from "./WeekdaysPicker";
import { Dayjs } from "dayjs";
import { Settings } from "@mui/icons-material";
import { useDebounce } from "usehooks-ts";
import AppDrawer from "./AppDrawer";
import { AppContext, IAppContext } from "./App";
import { DatePicker } from "@mui/x-date-pickers";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import StateList from "./StateList";

interface IBoardSettingsDrawer {
  open: boolean;
  setOpen: Dispatch<boolean>;
  editable: boolean;
}
export default function BoardSettingsDrawer({
  open,
  setOpen,
  editable,
}: IBoardSettingsDrawer) {
  const {
    boardDocument,
    boardData,
    sectionWidth,
    setSectionWidth,
    hideCompleted,
    setHideCompleted,
    hideBacklog,
    setHideBacklog,
    hideArchived,
    setHideArchived,
  } = useContext(AppContext) as IAppContext;

  const [boardName, setBoardName] = useState<string>(boardData.name);
  const debouncedBoardName = useDebounce(boardName, 200);

  useEffect(() => {
    updateDoc(boardDocument, { name: debouncedBoardName });
  }, [debouncedBoardName]);
  const handleStartDate = (newValue: Dayjs | null) => {
    if (!newValue) {
      return;
    }
    updateDoc(boardDocument, { startDate: newValue.startOf("day").toDate() });
  };

  return (
    <AppDrawer
      open={open}
      setOpen={setOpen}
      title="Board Settings"
      icon={<Settings />}
      minWidth={400}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 1 }}>
        <Box>
          <TextField
            fullWidth
            size="small"
            placeholder="Enter board name"
            label="Board Name"
            value={boardName}
            onChange={(e) => setBoardName(e.target.value)}
            inputProps={{ readOnly: !editable }}
          />
        </Box>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Section width</Typography>
          </Grid>
          <Grid item xs={6} sx={{ pr: 3 }}>
            <Slider
              min={60}
              max={120}
              value={sectionWidth}
              step={10}
              valueLabelDisplay="on"
              onChange={(e, v) => {
                if (editable) {
                  setSectionWidth(v as number);
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={9}>
            <Typography variant="subtitle1">Group by Person</Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Switch
              checked={boardData.groupByPerson}
              onChange={(e) => {
                if (editable) {
                  updateDoc(boardDocument, {
                    groupByPerson: e.target.checked,
                  });
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={9}>
            <Typography variant="subtitle1">
              Hide Completed from Timeline
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Switch
              checked={hideCompleted}
              onChange={(e) => setHideCompleted(e.target.checked)}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={9}>
            <Typography variant="subtitle1">
              Hide Backlog from Timeline
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Switch
              checked={hideBacklog}
              onChange={(e) => setHideBacklog(e.target.checked)}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={9}>
            <Typography variant="subtitle1">Hide Archived</Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Switch
              checked={hideArchived}
              onChange={(e) => setHideArchived(e.target.checked)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Board start date"
                inputFormat="MM/DD/YYYY"
                value={boardData.startDate}
                onChange={handleStartDate}
                renderInput={(params) => (
                  <TextField fullWidth size="small" {...params} />
                )}
                readOnly={!editable}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small">
              <InputLabel>Timeline Resolution</InputLabel>
              <Select<number>
                readOnly={!editable}
                fullWidth
                size="small"
                label="Timeline Resolution"
                value={
                  boardData.daysPerUnit === 1
                    ? 0
                    : boardData.daysPerUnit / boardData.workDays.length
                }
                onChange={(e) =>
                  updateDoc(boardDocument, {
                    daysPerUnit:
                      e.target.value === 0
                        ? 1
                        : (e.target.value as number) *
                          boardData.workDays.length,
                  })
                }
              >
                <MenuItem value={0}>Daily</MenuItem>
                <MenuItem value={1}>Weekly</MenuItem>
                <MenuItem value={2}>Bi-weekly</MenuItem>
                <MenuItem value={4}>Monthly</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <WeekdaysPicker
              editable={editable}
              weekdays={boardData.workDays}
              setWeekdays={(weekdays) =>
                updateDoc(boardDocument, {
                  workDays: weekdays,
                  // TODO: simplify model - don't use daysPerUnit, calculate it
                  daysPerUnit:
                    (boardData.daysPerUnit / boardData.workDays.length) *
                    weekdays.length,
                })
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: "flex" }}>
            <StateList boardData={boardData} boardDocument={boardDocument} />
          </Grid>
        </Grid>
      </Box>
    </AppDrawer>
  );
}
