import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Search } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { AppContext, IAppContext } from "./App";
import { useContext, useState } from "react";
import { IWorkItem } from "./converters";
import { useNavigate, useParams } from "react-router";

export default function SearchTask() {
  const { data } = useContext(AppContext) as IAppContext;
  const [focused, setFocused] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams();
  const [value, setValue] = useState<IWorkItem | null>(null);
  return (
    <Autocomplete<IWorkItem>
      sx={{
        flex: focused || hover ? 1 : 0,
        minWidth: 200,
        maxWidth: 500,
      }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onChange={(e: any, value) => {
        if (!value) {
          return;
        }
        navigate(`/boards/${params.board}/items/${value.id}`);
        setValue(null);
      }}
      size="small"
      options={data}
      value={value}
      getOptionLabel={(item) => item.name}
      renderInput={(params) => (
        <TextField
          sx={{
            backgroundColor: "background.paper",
            width: "100%",
          }}
          placeholder="Search..."
          {...params}
          InputProps={{
            ...params.InputProps,
            type: "search",
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
