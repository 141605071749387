import { Modal } from "@mui/material";
import { useState } from "react";

export default function ImagePreview({ src }: { src: string }) {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <img
        src={src}
        style={{ maxWidth: "100%" }}
        onClick={() => setOpen(true)}
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          padding: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={src}
          style={{ maxHeight: "100%", outline: 0 }}
          onClick={() => setOpen(false)}
        />
      </Modal>
    </>
  );
}
