import { collection, doc, updateDoc } from "firebase/firestore";
import { IWorkItem, itemConverter } from "./converters";
import { useFirebase } from "./useFirebase";
import { useParams } from "react-router";
import { useConfirm } from "material-ui-confirm";

export default function useArchiveWorkItem() {
  const { firestore } = useFirebase();
  const params = useParams();
  const confirm = useConfirm();

  return async function archiveWorkItem(item: IWorkItem) {
    const itemCollection = collection(
      firestore,
      "boards",
      params.board as string,
      "items"
    ).withConverter(itemConverter);

    await confirm({
      confirmationText: "Archive",
      title: "Archive Work Item",
      description: `Are you sure you want to archive the work item "${item.name}"?`,
    });

    await updateDoc(doc(itemCollection, item.id), {
      archived: true,
    });
  };
}
