import { getColor, getCompleteColor, getSelectedColor } from "./colors";
import { Box, Typography } from "@mui/material";
import React, {
  MutableRefObject,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { IWorkItem } from "./converters";
import IconButton from "@mui/material/IconButton";
import { Error, Launch } from "@mui/icons-material";
import { AppContext, IAppContext } from "./App";
import { useNavigate, useParams } from "react-router";
import Tooltip from "@mui/material/Tooltip";
import { useConfirm } from "material-ui-confirm";
import CollaboratorsDropdown from "./CollaboratorsDropdown";
import useDeleteWorkItem from "./useDeleteWorkItem";

export interface IItem {
  item: IWorkItem;
  scale: number;
  scrollContainer: MutableRefObject<HTMLDivElement | undefined>;
}

const HANDLE_SIZE = 8;

export default function Item({ item, scale, scrollContainer }: IItem) {
  const { itemCollection, selectionModel, setSelectionModel, editable, data } =
    useContext(AppContext) as IAppContext;

  const [placeholder, setPlaceholder] = useState<[number, number]>([0, 0]);
  const [dragging, setDragging] = useState<IWorkItem | undefined>();
  const [dragged, setDragged] = useState<boolean>(false);
  const [point, setPoint] = useState<
    | { x: number; y: number; isLeftHandle: boolean; isRightHandle: boolean }
    | undefined
  >();
  const [draggingEl, setDraggingEl] = useState<HTMLDivElement | undefined>();
  const isSelected = item.id && selectionModel.includes(item.id);

  const params = useParams();
  const navigate = useNavigate();
  const deleteWorkItem = useDeleteWorkItem();

  const setDrawerOpen = (open: boolean) =>
    navigate(
      open
        ? `/boards/${params.board}/items/${item.id}`
        : `/boards/${params.board}`
    );
  const ref = doc(itemCollection, item.id);

  const startDrag = (e: React.MouseEvent<HTMLDivElement>, item: IWorkItem) => {
    if (!(e.target as HTMLDivElement).classList.contains("draggable")) {
      return;
    }

    setPlaceholder([item.start, item.end]);

    setDragged(false);
    setDragging(item);
    const target = e.currentTarget;
    setDraggingEl(target);

    if (!target?.parentElement) {
      return;
    }

    const x =
      e.pageX -
      target.offsetLeft -
      target.parentElement.offsetLeft +
      (scrollContainer.current?.scrollLeft ?? 0);
    const y =
      e.pageY -
      target.offsetTop -
      target.parentElement.offsetTop +
      (scrollContainer.current?.scrollTop ?? 0);

    setPoint({
      x,
      y,
      isLeftHandle: x < HANDLE_SIZE,
      isRightHandle: x > target.clientWidth - HANDLE_SIZE,
    });
  };

  const drag = (e: MouseEvent) => {
    const target = draggingEl;

    if (!target || !target.parentElement || !point || !dragging) {
      return [0, 0];
    }

    const x = e.pageX + (scrollContainer.current?.scrollLeft ?? 0);

    let start: number, end: number;
    if (!point.isLeftHandle && !point.isRightHandle) {
      start = Math.max(
        1,
        Math.floor((x - target.parentElement.offsetLeft - point.x) / scale) + 1
      );
      end = Math.max(1, dragging.end - dragging.start + start);
    } else if (point.isLeftHandle) {
      start = Math.min(
        Math.max(
          1,
          Math.floor((x - target.parentElement.offsetLeft) / scale) + 1
        ),
        dragging.end
      );
      end = dragging.end;
    } else if (point.isRightHandle) {
      start = dragging.start;
      end = Math.max(
        dragging.start,
        Math.floor((x - target.parentElement.offsetLeft) / scale) + 1
      );
    } else {
      start = 1;
      end = 1;
    }

    if (start !== item.start || end !== item.end) {
      // dragging has occured
      setDragged(true);
    }
    setPlaceholder([start, end]);
    return [start, end];
  };

  useEffect(() => {
    if (dragging) {
      window.addEventListener("mousemove", drag);
      window.addEventListener("mouseup", stopDrag);
    }
    return () => {
      window.removeEventListener("mousemove", drag);
      window.removeEventListener("mouseup", stopDrag);
    };
  }, [dragging]);

  const stopDrag = (e: MouseEvent) => {
    const [start, end] = drag(e);
    setDragging(undefined);
    setDraggingEl(undefined);

    if (start === item.start && end === item.end) {
      select(item);
    }
    updateDoc(ref, { start, end });
  };

  const select = (ganttData: IWorkItem) => {
    if (!ganttData.id) {
      return;
    }
    if (dragged) {
      setSelectionModel([ganttData.id]);
      return;
    }
    setSelectionModel(isSelected ? [] : [ganttData.id]);
  };

  const keyHandler = async (key: string) => {
    if (key === "Backspace") {
      await deleteWorkItem(item, true);
    } else if (key === "ArrowLeft" && item.start > 1) {
      await updateDoc(ref, { start: item.start - 1, end: item.end - 1 });
    } else if (key === "ArrowRight") {
      await updateDoc(ref, { start: item.start + 1, end: item.end + 1 });
    }
  };

  const incomplete = useMemo(() => {
    const selected = data.filter((x) => item.dependencies.includes(x.id ?? ""));
    return selected.filter((x) => x.status !== "done" && x.end >= item.start);
  }, [item, data]);

  return (
    <>
      <Box
        tabIndex={-1}
        key={item.id}
        sx={{
          boxShadow: "2px 2px 0px rgba(0,0,0,0.1)",
          borderRadius: 1,
          color: item.status === "done" ? "grey" : "black",
          cursor: isSelected && editable ? "move" : "pointer",
          borderColor: isSelected ? "text.secondary" : "divider",
          zIndex: isSelected ? 1 : "inherit",
          borderWidth: 1,
          borderStyle: "solid",
          display: "flex",
          alignItems: "stretch",
          position: "absolute",
          left: (item.start - 1) * scale + 1,
          top: 0,
          bottom: -1,
          backgroundColor:
            item.status === "done"
              ? getCompleteColor(item.color)
              : isSelected
              ? getSelectedColor(item.color)
              : getColor(item.color),
          width: (item.end - item.start + 1) * scale - 2,
          lineHeight: "40px",
          "&:hover": {
            backgroundColor: getSelectedColor(item.color),
          },
          ".openDrawer": {
            display: "none",
          },
          "&:hover .openDrawer": {
            display: "block",
          },
        }}
        onMouseDown={(e) => {
          if (editable) startDrag(e, item);
        }}
        onDoubleClick={() => {
          if (editable) setDrawerOpen(true);
        }}
        onClick={() => select(item)}
        onKeyDown={(e) => {
          if (editable) keyHandler(e.key);
        }}
      >
        <Box
          className="draggable"
          sx={{
            width: HANDLE_SIZE,
            marginRight: 0.5,
            backgroundColor: "#00000020",
            cursor: editable ? "ew-resize" : "inherit",
          }}
        />
        {incomplete.length > 0 && (
          <Error color="error" sx={{ alignSelf: "center", mr: 1 }} />
        )}
        <Tooltip title={item.name} enterDelay={1000} enterNextDelay={500}>
          <Typography
            className="draggable"
            variant="body2"
            sx={{
              lineHeight: "40px",
              flex: 1,
              width: "100%",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              userSelect: "none",
              fontWeight: isSelected ? "bold" : "medium",
              textDecorationLine:
                item.status === "done" ? "line-through" : "inherit",
            }}
          >
            {item.name}
          </Typography>
        </Tooltip>

        <Tooltip title="Edit item details">
          <IconButton
            className="openDrawer"
            onClick={() => setDrawerOpen(true)}
          >
            <Launch fontSize="small" />
          </IconButton>
        </Tooltip>

        {item.end - item.start > 0 && <CollaboratorsDropdown item={item} />}

        <Box
          className="draggable"
          sx={{
            width: HANDLE_SIZE,
            marginLeft: 0.5,
            backgroundColor: "#00000020",
            cursor: editable ? "ew-resize" : "inherit",
          }}
        />
      </Box>
      {dragging && (
        <Box
          sx={{
            position: "absolute",
            border: "2px dashed gray",
            borderRadius: 1,
            height: 40,
            width: (placeholder[1] - placeholder[0] + 1) * scale - 2,
            left: (placeholder[0] - 1) * scale + 1,
            cursor: editable ? "move" : "pointer",
          }}
        ></Box>
      )}
    </>
  );
}
