import logo from "./images/workplan.png";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "./FirebaseAuthContext";

export default function Logo() {
  const { user } = useAuth();
  return (
    <Link
      to={user ? "/boards" : "/"}
      style={{
        display: "flex",
        alignItems: "center",
        gap: 10,
        textDecoration: "none",
        color: "#333",
        padding: 10,
      }}
    >
      <img src={logo} style={{ width: 30 }} />
      <Typography
        variant="h3"
        sx={{
          fontSize: 18,
          fontWeight: "bold",
          color: "text.primary",
        }}
      >
        Workplan
      </Typography>
    </Link>
  );
}
