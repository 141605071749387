import React, { MutableRefObject, useContext } from "react";
import { CategoryData, TrackData } from "./Gantt";
import { Box, Typography } from "@mui/material";
import Item from "./Item";
import { doc, updateDoc } from "firebase/firestore";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import { AppContext } from "./App";
import Tooltip from "@mui/material/Tooltip";
import UserAvatar from "./UserAvatar";
import { IWorkItem } from "./converters";

interface ICategory {
  category: CategoryData;
  scale: number;
  scrollContainer: MutableRefObject<HTMLDivElement | undefined>;
  teamWidth: number;
  trackWidth: number;
}

export default function Category({
  category,
  scale,
  scrollContainer,
  teamWidth,
  trackWidth,
}: ICategory) {
  const appContextData = useContext(AppContext);
  if (!appContextData) {
    return <Box />;
  }

  const {
    itemCollection,
    selectionModel,
    editable,
    boardData,
    sectionWidth,
    maxSections,
  } = appContextData;

  const separateLanes = (trackItems: IWorkItem[]): IWorkItem[][] => {
    const lanes: IWorkItem[][] = [[]];
    for (let i = 0; i < trackItems.length; i++) {
      let foundEmptyLane = false;
      let emptyLaneId = 0;
      for (let j = 0; j < lanes.length; j++) {
        const s = trackItems[i].start;
        const e = trackItems[i].end;
        let overlap = false;
        for (let k = 0; k < lanes[j].length; k++) {
          if (lanes[j][k].start <= e && lanes[j][k].end >= s) {
            overlap = true;
            break;
          }
        }

        if (!overlap) {
          foundEmptyLane = true;
          emptyLaneId = j;
          break;
        }
      }
      if (!foundEmptyLane) {
        lanes.push([trackItems[i]]);
      } else {
        lanes[emptyLaneId].push(trackItems[i]);
      }
    }
    return lanes;
  };
  const setTrack = (trackData: TrackData) => {
    selectionModel.forEach((selection) => {
      updateDoc(doc(itemCollection, selection as string), {
        category: trackData.category,
        track: trackData.track,
      });
    });
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          position: { sm: "sticky" },
          left: 0,
          color: "text.primary",
          backgroundColor: "background.paper",
          zIndex: 2,
          paddingX: 1,
          width: teamWidth,
          display: "flex",
          alignItems: "center",
          borderRight: "1px solid",
          borderBottom: "1px solid",
          borderRightColor: "divider",
          borderBottomColor: "divider",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
          }}
        >
          {category.category}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {category.tracks.map((track) => (
          <Box
            key={`${track.category}-${track.track}`}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <Box
              sx={{
                position: { sm: "sticky" },
                color: "text.primary",
                backgroundColor: "background.paper",
                left: teamWidth,
                zIndex: 2,
                paddingX: 1,
                borderRight: "1px solid",
                borderBottom: "1px solid",
                borderRightColor: "divider",
                borderBottomColor: "divider",
                width: trackWidth,
                display: "flex",
                alignItems: "center",
              }}
            >
              {boardData.groupByPerson ? (
                <UserAvatar userId={track.track} />
              ) : (
                <Typography
                  variant="body2"
                  sx={{
                    flex: 1,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {track.track}
                </Typography>
              )}

              {selectionModel.length > 0 && editable && (
                <Box
                  sx={{
                    marginRight: 1,
                    color: "text.secondary",
                    cursor: "pointer",
                    lineHeight: "10px",
                  }}
                  onClick={() => setTrack(track)}
                >
                  {boardData.groupByPerson === false && (
                    <Tooltip title="Move work item to this track">
                      <KeyboardDoubleArrowRight />
                    </Tooltip>
                  )}
                </Box>
              )}
            </Box>
            <Box
              sx={{
                width: maxSections * sectionWidth,
                borderBottom: "1px solid",
                borderBottomColor: "divider",
              }}
            >
              {separateLanes(track.data).map((lane, index) => (
                <Box
                  key={index}
                  sx={{
                    position: "relative",
                    height: 40,
                    marginBottom: 0.5,
                  }}
                >
                  {lane.map((item) => (
                    <Item
                      key={item.id}
                      item={item}
                      scale={scale}
                      scrollContainer={scrollContainer}
                    />
                  ))}
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
