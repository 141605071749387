import * as React from "react";
import { ReactNode } from "react";
import "firebase/compat/auth";
import { User } from "@firebase/auth";
import { useFirebase } from "./useFirebase";
import {
  collection,
  CollectionReference,
  doc,
  FieldPath,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  boardConverter,
  CollaboratorRole,
  IOrganization,
  IUser,
  organizationConverter,
  userConverter,
} from "./converters";
import dayjs from "dayjs";
import { useAuthUser } from "@react-query-firebase/auth";
import { PricingPlanName, PricingPlan, pricingPlans } from "./config";

type ContextState = {
  user: User | null;
  userData?: IUser | null;
  userCollection: CollectionReference<IUser> | null;
  loadingUser: boolean | null;
  userPlan: PricingPlan | null;
  userOrganization: IOrganization | null;
};

const FirebaseAuthContext = React.createContext<ContextState>({
  user: null,
  userData: null,
  userCollection: null,
  loadingUser: null,
  userPlan: null,
  userOrganization: null,
});

function FirebaseAuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = React.useState<User | null>(null);
  const [userData, setUserData] = React.useState<IUser | undefined>(undefined);
  const [loadingUser, setLoadingUser] = React.useState<boolean>(true);
  const [firebaseUser, setFirebaseUser] = React.useState<User | null>(null);
  const [userPlan, setUserPlan] = React.useState<PricingPlan | null>(null);
  const [userOrganization, setUserOrganization] =
    React.useState<IOrganization | null>(null);
  const { auth, firestore } = useFirebase();
  const { isLoading: loadingFirebaseAuth } = useAuthUser(["user"], auth);
  const userCollection = collection(firestore, "users").withConverter(
    userConverter
  );

  // load user subscription plan when user changes
  React.useEffect(() => {
    // get the user's role set by Stripe
    async function getStripeRole() {
      await auth.currentUser?.getIdToken(true);
      const decodedToken = await auth.currentUser?.getIdTokenResult();
      return decodedToken?.claims.stripeRole;
    }

    const updateUserPlan = async () => {
      const stripeRole = await getStripeRole();
      const userPlanName: PricingPlanName = stripeRole || PricingPlanName.Free;
      const pricingPlan = pricingPlans[userPlanName];
      setUserPlan(pricingPlan);
    };

    if (user === null) {
      setUserPlan(null);
      return;
    }

    updateUserPlan();
  }, [user, auth.currentUser]);

  React.useEffect(() => {
    const userRef = doc(firestore, "users", user?.uid ?? "0").withConverter(
      userConverter
    );
    return onSnapshot(userRef, (userDoc) => {
      if (!userDoc.data()) {
        return;
      }
      setUserData(userDoc.data());
    });
  }, [firestore, user?.uid]);

  React.useEffect(() => {
    const organizationId = userData?.organizationId;
    if (!organizationId) {
      setUserOrganization(null);
      return;
    }

    const orgRef = doc(
      firestore,
      "organizations",
      userData?.organizationId
    ).withConverter(organizationConverter);
    return onSnapshot(orgRef, (orgDoc) => {
      if (!orgDoc.exists()) {
        setUserOrganization(null);
        return;
      }
      setUserOrganization(orgDoc.data());
    });
  }, [firestore, userData?.organizationId]);

  React.useEffect(() => {
    const handleFirebaseUserChange = async () => {
      // wait for firebase auth to load
      if (loadingFirebaseAuth) {
        return;
      }

      setLoadingUser(false);

      // if firebase auth is loaded and there is still no user, then the user is not authenticated
      // and we set user to null
      if (firebaseUser === null) {
        setUser(null);
        return;
      }

      setUser(firebaseUser);

      const userCollection = collection(firestore, "users").withConverter(
        userConverter
      );
      const userRef = doc(userCollection, firebaseUser.uid);
      const dbUser = await getDoc(userRef);

      // if user is in the database, update it and return
      if (dbUser.exists()) {
        if (dbUser.data()?.enabled === false) {
          // user is disabled
          auth.signOut();
        }
        await updateDoc(userRef, {
          lastLogin: firebaseUser.metadata.lastSignInTime,
        });
        return;
      }

      // on the first login:
      // 1. create an organization for the user
      const orgCollection = collection(
        firestore,
        "organizations"
      ).withConverter(organizationConverter);
      const orgRef = doc(orgCollection);
      const orgData = {
        name: `${firebaseUser.displayName}'s Organization`,
        ownerId: firebaseUser.uid,
        dateCreated: dayjs(),
      };
      await setDoc(orgRef, orgData);
      setUserOrganization({ ...orgData, id: orgRef.id });

      // 2. insert the user into the database
      await setDoc(userRef, {
        displayName: firebaseUser.displayName ?? "Unknown User",
        pricingPlan: PricingPlanName.Free,
        email: firebaseUser.email as string,
        emailVerified: firebaseUser.emailVerified,
        photoURL: firebaseUser.photoURL,
        dateCreated: dayjs(),
        lastLogin: dayjs(),
        enabled: true,
        organizationId: orgRef.id,
      });

      // 3. update all the boards the user was invited to to have the new user id in collaboratorsById
      const boardCollection = collection(firestore, "boards").withConverter(
        boardConverter
      );
      const userEmail = firebaseUser.email as string;
      const fieldPath = new FieldPath(
        "collaboratorsByEmail",
        userEmail,
        "role"
      );
      const ref = query(
        boardCollection,
        where(fieldPath, ">=", CollaboratorRole.Viewer)
      );
      const querySnapshot = await getDocs(ref);
      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (board) => {
          const userId = firebaseUser.uid as string;
          const boardData = board.data();

          const userRoleObject = {
            userEmail,
            userId,
            role: boardData.collaboratorsByEmail[userEmail].role,
          };

          // it was hard to make this work with updateDoc, so I'm just setting the whole thing
          await setDoc(board.ref, {
            ...boardData,
            collaboratorsByEmail: {
              ...boardData.collaboratorsByEmail,
              [userEmail]: userRoleObject,
            },
            collaboratorsById: {
              ...boardData.collaboratorsById,
              [userId]: userRoleObject,
            },
          });
        });
      }
    };

    handleFirebaseUserChange();
  }, [firebaseUser, loadingFirebaseAuth, firestore, auth]);

  React.useEffect(() => {
    // onAuthStateChanged is called multiple times, sometimes with the same user
    // to overcome this, we only handle the change if the user is different
    return auth.onAuthStateChanged(setFirebaseUser);
  }, [auth]);

  return (
    <FirebaseAuthContext.Provider
      value={{
        user,
        userData,
        userCollection,
        loadingUser,
        userPlan,
        userOrganization,
      }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(FirebaseAuthContext);
  if (context.userCollection === undefined) {
    throw new Error(
      "useFirebaseAuth must be used within a FirebaseAuthProvider"
    );
  }
  return context;
}

export { FirebaseAuthProvider, useAuth };
