import { Box, BoxProps } from "@mui/material";
import React from "react";

export default function DragHandler(props: BoxProps) {
  return (
    <Box
      sx={{
        paddingY: "6px",
        height: 10,
        backgroundColor: "background.paper",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderTopWidth: 1,
        borderTopStyle: "solid",
        borderTopColor: "divider",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "divider",
        cursor: "row-resize",
      }}
      {...props}
    >
      <Box
        sx={{
          width: 20,
          height: 3,
          borderTopWidth: 1,
          borderTopStyle: "solid",
          borderTopColor: "text.secondary",
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          borderBottomColor: "text.secondary",
        }}
      />
    </Box>
  );
}
