import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Error, Info } from "@mui/icons-material";
import { MenuList, Typography } from "@mui/material";
import { AppContext, IAppContext } from "./App";
import { useContext, useState } from "react";
import { IWorkItem } from "./converters";
import { useNavigate, useParams } from "react-router";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MenuItem from "@mui/material/MenuItem";
import { doc, updateDoc } from "firebase/firestore";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";

export default function Dependencies({ item }: { item: IWorkItem }) {
  const { data, itemCollection } = useContext(AppContext) as IAppContext;

  const navigate = useNavigate();
  const params = useParams();

  const selected = data.filter((x) => item.dependencies.includes(x.id ?? ""));
  const incomplete = selected.filter(
    (x) => x.status !== "done" && x.end >= item.start
  );
  // const incomplete = incompleteDependencies(data, item);

  return (
    <>
      <Autocomplete<IWorkItem, true>
        fullWidth
        multiple
        disableCloseOnSelect
        value={selected}
        onChange={async (e: any, value) => {
          const valueIds: string[] = value
            .filter((x) => x.id !== undefined)
            .map((x) => x.id as string);
          await updateDoc(doc(itemCollection, item.id), {
            dependencies: valueIds,
          });
        }}
        size="small"
        options={data.filter((x) => x.id !== item.id)}
        getOptionLabel={(item) => item.name}
        renderOption={(props, option) => (
          <MenuItem {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={item.dependencies.includes(option.id ?? "")}
            />
            <Typography variant="body2">{option.name}</Typography>
          </MenuItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Task Dependencies"
            placeholder="Select Tasks..."
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
      {incomplete.length > 0 && (
        <>
          <Typography
            variant="h6"
            sx={{ my: 1, alignItems: "center", display: "flex", gap: 1 }}
          >
            Overlapping Incomplete Tasks
            <Tooltip title="There are incomplete dependencies that have not or will not end by the time that the current task starts.">
              <Info sx={{ color: "#ccc" }} />
            </Tooltip>
          </Typography>
          <MenuList>
            {incomplete.map((x) => (
              <MenuItem
                onClick={() =>
                  navigate(`/boards/${params.board}/items/${x.id}`)
                }
              >
                <ListItemIcon>
                  <Error color="error" />
                </ListItemIcon>
                <ListItemText>{x.name}</ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </>
  );
}
