import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getFunctions } from "firebase/functions";

import firebase from "firebase/compat/app";

export const firebaseConfig = {
  apiKey: "AIzaSyBG7OOaOtyKSNGsGFMeJyX0zn1oFm7ElEQ",
  authDomain: "workplan-1554b.firebaseapp.com",
  projectId: "workplan-1554b",
  storageBucket: "workplan-1554b.appspot.com",
  messagingSenderId: "630250373748",
  appId: "1:630250373748:web:7807f32dceaabbbdddc721",
  measurementId: "G-F5BX5TRZ1Y",
};

// Configure FirebaseUI.
export const uiConfig: firebaseui.auth.Config = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/boards",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
};

export function useFirebase() {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth();
  const database = getDatabase();
  const firestore = getFirestore();
  const storage = getStorage();
  const functions = getFunctions();

  return {
    app,
    auth,
    database,
    firestore,
    functions,
    storage,
    ref,
    uploadBytes,
    getDownloadURL,
  };
}

export default { uiConfig, useFirebase };
