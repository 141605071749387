import { Chip } from "@mui/material";
import { stateTitle, workItemColors } from "./Kanban";

export default function WorkItemStatus({
  status,
}: {
  status: keyof typeof workItemColors;
}) {
  return (
    <Chip
      size="small"
      color={workItemColors[status] as any}
      label={stateTitle[status]}
    />
  );
}
