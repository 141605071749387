import { ColorName, getColor, getStrongColor } from "./colors";
import { Box } from "@mui/material";
import React from "react";

interface IColorChip {
  color: ColorName;
  onClick?: (e: any) => void;
}
export default function ColorChip({ color, onClick }: IColorChip) {
  return (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: getColor(color),
        border: `1px solid ${getStrongColor(color)}`,
        width: 16,
        height: 16,
        marginRight: 1,
        borderRadius: 1,
        display: "inline-block",
        verticalAlign: "sub",
      }}
    ></Box>
  );
}
