import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import StyledFirebaseAuth from "./StyledFirebaseAuth";
import { Container, Grid } from "@mui/material";
//import login from "./images/pexels-ekaterina-bolovtsova-6193084.jpg";
import login from "./images/pexels-ann-poan-5797903.jpg";
import Logo from "./Logo";
import { useParams } from "react-router";

export default function SignIn() {
  const params = useParams();
  const { board } = params;
  const signInSuccessUrl = board ? `/boards/${board}` : "/boards";

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid item xs={12} md={6}>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
          <Logo />
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Container maxWidth="xs" sx={{ minHeight: 500 }}>
              <Typography variant="h4" component="h2" sx={{ mb: 2 }}>
                Welcome to Workplan!
              </Typography>
              <Typography sx={{ mb: 5 }}>
                Please sign in using one of the following methods:
              </Typography>
              <StyledFirebaseAuth signInSuccessUrl={signInSuccessUrl} />
            </Container>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundImage: `url(${login})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      />
    </Grid>
  );
}
