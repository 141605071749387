import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Dispatch } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

interface IWeekdaysPicker {
  weekdays: number[];
  setWeekdays: Dispatch<number[]>;
  editable: boolean;
}

export default function WeekdaysPicker({
  weekdays,
  setWeekdays,
  editable,
}: IWeekdaysPicker) {
  const handleChange = (event: SelectChangeEvent<number[]>) => {
    setWeekdays(event.target.value as number[]);
  };

  return (
    <div>
      <FormControl fullWidth size="small">
        <InputLabel>Working days of week</InputLabel>
        <Select<number[]>
          readOnly={!editable}
          fullWidth
          multiple
          size="small"
          value={weekdays}
          onChange={handleChange}
          input={<OutlinedInput label="Working days of week" />}
          renderValue={(selected) =>
            selected
              .sort()
              .map((x) => names[x].substring(0, 3))
              .join(", ")
          }
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem key={name} value={names.indexOf(name)} dense>
              <Checkbox
                checked={weekdays.includes(names.indexOf(name))}
                size="small"
              />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
