import { Container, Typography } from "@mui/material";
import React from "react";
import Page from "./Page";

export default function TermsOfService() {
  return (
    <Page>
      <Container
        maxWidth="md"
        sx={{ display: "flex", flexDirection: "column", gap: 3 }}
      >
        <Typography variant="h3" component="h1">
          Workplan Terms of Service
        </Typography>

        <Typography variant="h5">Introduction</Typography>
        <Typography>
          These Terms of Service (“Terms”) govern your use of the Workplan SaaS
          service (“Service”) provided by Shevat Ventures LLC (“Company”). By
          using the Service, you agree to be bound by these Terms. If you do not
          agree to these Terms, you may not use the Service.
        </Typography>

        <Typography variant="h5">Service Description</Typography>
        <Typography>
          The Service is a software-as-a-service platform that helps product
          teams manage their work plan. It provides users with tools to
          organize, prioritize, and track tasks and projects.
        </Typography>

        <Typography variant="h5">Subscription and Payment</Typography>
        <Typography>
          The Service is offered on a subscription basis. The subscription fee
          will be billed on a recurring basis, as specified during the
          registration process. The Company reserves the right to change the
          subscription fee at any time, but will provide notice of any such
          change before it becomes effective.
        </Typography>

        <Typography variant="h5">User Account and Security</Typography>
        <Typography>
          You are responsible for maintaining the confidentiality of your
          account and password, and for restricting access to your computer. You
          agree to accept responsibility for all activities that occur under
          your account or password. The Company reserves the right to terminate
          your account at any time for any reason.
        </Typography>

        <Typography variant="h5">Intellectual Property</Typography>
        <Typography>
          The Service and all its components, including but not limited to
          software, text, images, and graphics, are owned by the Company and are
          protected by United States and international copyright and trademark
          laws. You may not use any of the materials without the prior written
          consent of the Company.
        </Typography>

        <Typography variant="h5">Liability</Typography>
        <Typography>
          The Company shall not be liable for any damages arising from your use
          of the Service, including but not limited to any direct, indirect,
          incidental, special, or consequential damages. Your sole remedy for
          any dissatisfaction with the Service is to stop using it. The
          Company's liability to you is limited to the subscription fee you have
          paid for the Service.
        </Typography>

        <Typography variant="h5">Indemnification</Typography>
        <Typography>
          You agree to indemnify and hold the Company and its affiliates,
          officers, agents, and employees harmless from any claim or demand,
          including reasonable attorneys' fees, made by any third party due to
          or arising out of your use of the Service, your violation of these
          Terms, or your violation of any rights of another.
        </Typography>

        <Typography variant="h5">Governing Law</Typography>
        <Typography>
          These terms shall be governed by and construed in accordance with the
          laws of Texas USA, without giving effect to any principles of
          conflicts of law.
        </Typography>

        <Typography variant="h5">Changes to Terms of Service</Typography>
        <Typography>
          The Company reserves the right to change these Terms of Service at any
          time. If the Company makes changes to these Terms, it will provide
          notice of such changes, such as by sending an email or by posting a
          notice on the Service. Your continued use of the Service after any
          changes to these Terms will be deemed acceptance of the new terms.
        </Typography>

        <Typography variant="h5">Contact Information</Typography>
        <Typography>
          If you have any questions about these Terms of Service, please contact
          us at a@shevat.us
        </Typography>
      </Container>
    </Page>
  );
}
