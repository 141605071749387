import {
  getStripePayments,
  createCheckoutSession as createSession,
} from "@stripe/firestore-stripe-payments";
import { useFirebase } from "./useFirebase";

export function useStripe() {
  const { app } = useFirebase();
  const payments = getStripePayments(app, {
    productsCollection: "products",
    customersCollection: "customers",
  });

  const createCheckoutSession = async (priceId: string) => {
    return await createSession(payments, {
      price: priceId,
      success_url: `${window.location.origin}/boards/payment-success`,
    });
  };

  return { createCheckoutSession };
}
