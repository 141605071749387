import React from "react";
import { CardStatus, IBoard } from "./converters";
import { DocumentReference, updateDoc } from "firebase/firestore";
import { Box, List, ListItem, ListItemButton, Typography } from "@mui/material";
import { Checkbox, ListItemIcon, ListItemText } from "@mui/material";
import { stateTitle } from "./Kanban";

export default function StateList({
  boardData,
  boardDocument,
}: {
  boardData: IBoard;
  boardDocument: DocumentReference<IBoard>;
}) {
  const states: CardStatus[] = Object.keys(stateTitle) as CardStatus[];
  const [checked, setChecked] = React.useState<CardStatus[]>(boardData.states);

  const handleToggle = (value: CardStatus) => () => {
    const newChecked = states.filter(
      (state) =>
        (state === value && !checked.includes(value)) ||
        (state !== value && checked.includes(state))
    );

    setChecked(newChecked);
    updateDoc(boardDocument, { states: newChecked });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="h6">Work Item States</Typography>
      <List dense sx={{ width: "100%" }}>
        {states.map((value) => {
          const labelId = `checkbox-list-label-${value}`;

          return (
            <ListItem dense key={value} disablePadding>
              <ListItemButton
                role={undefined}
                onChange={handleToggle(value)}
                dense
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                    disabled={value === "done"}
                    sx={{ padding: 0.5 }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${stateTitle[value]}`} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
