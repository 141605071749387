import {
  Box,
  Button,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

interface INewBoardModal {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  defaultValue: string;
  handleAdd: (boardName: string) => void;
}
export default function NewBoardModal({
  open,
  setOpen,
  defaultValue,
  handleAdd,
}: INewBoardModal) {
  useEffect(() => {
    if (open) {
      setBoardName(defaultValue);
      setTimeout(() => {
        if (boardNameRef.current) {
          boardNameRef.current.focus();
        }
      }, 0);
    }
  }, [open, defaultValue]);
  const [boardName, setBoardName] = useState<string>(defaultValue);
  const boardNameRef = useRef<HTMLInputElement>();
  function submit() {
    handleAdd(boardName);
    setOpen(false);
  }
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Paper
        sx={{
          width: 400,
          padding: 2,
          display: "flex",
          gap: 3,
          flexDirection: "column",
        }}
      >
        <Typography variant="h6">Add Board</Typography>
        <form onSubmit={submit}>
          <TextField
            inputRef={boardNameRef}
            fullWidth
            size="small"
            placeholder="e.g. My Board"
            label="Board Name"
            value={boardName}
            onChange={(e) => setBoardName(e.target.value)}
            error={boardName.length === 0}
            helperText={
              boardName.length === 0 ? "Invalid board name" : undefined
            }
          />
        </form>
        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button variant="contained" startIcon={<Add />} onClick={submit}>
            Add Board
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
}
