import { Box, Typography } from "@mui/material";
import AppToolbar from "./AppToolbar";

export function NotFound() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        height: "100vh",
        backgroundColor: "background.default",
      }}
    >
      <AppToolbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          Page Not Found
        </Typography>
        <Typography variant="body1">
          Oops! The page you are looking for could not be found.
        </Typography>
      </Box>
    </Box>
  );
}
