import { collection, doc, updateDoc } from "firebase/firestore";
import { IBoard, IWorkItem } from "./converters";
import { useFirebase } from "./useFirebase";

export function useRemoveCollaborator() {
  const { firestore } = useFirebase();

  return async function removeCollaborator(
    boardData: IBoard,
    boardItems: IWorkItem[],
    userEmail: string
  ) {
    const userId = boardData.collaboratorsByEmail[userEmail].userId;

    // remove the user from all work items and tasks
    for (const item of boardItems) {
      // if the user never logged in, they won't have a userId
      // users without a userId can't be collaborators on work items and tasks
      if (userId === null) {
        continue;
      }

      const userIdInCollaborators = item.collaborators.includes(userId);
      const userIdInTasks = item.tasks.some((task) =>
        task.collaborators.includes(userId)
      );
      if (!userIdInCollaborators && !userIdInTasks) {
        continue;
      }

      const itemCollection = collection(
        firestore,
        "boards",
        boardData.id as string,
        "items"
      );
      const itemDoc = doc(itemCollection, item.id as string);
      await updateDoc(itemDoc, {
        collaborators: item.collaborators.filter((c) => c !== userId),
        tasks: item.tasks.map((t) => {
          return {
            ...t,
            collaborators: t.collaborators.filter((c) => c !== userId),
          };
        }),
      });
    }

    // remove the user from board.collaboratorsByEmail and board.collaboratorsById
    const updatedCollaboratorsByEmail = {
      ...boardData.collaboratorsByEmail,
    };
    delete updatedCollaboratorsByEmail[userEmail];

    const updatedCollaboratorsById = {
      ...boardData.collaboratorsById,
    };
    if (userId !== null) {
      delete updatedCollaboratorsById[userId];
    }

    const boardRef = doc(firestore, "boards", boardData.id as string);
    await updateDoc(boardRef, {
      collaboratorsByEmail: updatedCollaboratorsByEmail,
      collaboratorsById: updatedCollaboratorsById,
    });
  };
}
