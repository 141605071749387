import { Box, Container } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <Box>
      <Container>
        <Box sx={{ textAlign: "center", p: 3, color: "gray" }}>
          Copyright &copy; 2023 Workplan. Read our{" "}
          <Link to={"/terms"}>Terms of Service</Link> and{" "}
          <Link to={"/privacy"}>Privacy Policy</Link>.
        </Box>
      </Container>
    </Box>
  );
}
