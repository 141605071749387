import React, { CSSProperties } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Cancel } from "@mui/icons-material";
import CollaboratorsDropdown from "./CollaboratorsDropdown";
import { IWorkItem } from "./converters";
import ClampLines from "react-clamp-lines";
import { ITask } from "./ItemDrawer";
import { getTextFromMentionable } from "./utils";
import ColorChip from "./ColorChip";

interface TagProps {
  title: string;
  color?: string;
  bgcolor?: string;
  tagStyle?: CSSProperties;
}

declare type MyCardProps = {
  showDeleteButton?: boolean;
  onDelete?: () => void;
  onClick?: (e: any) => void;
  onChange?: (card: Card) => void;
  style?: CSSProperties;
  tagStyle?: CSSProperties;
  className?: string;
  id: string;
  index: number;
  title?: string;
  label?: string;
  description?: string;
  tags?: TagProps[];
  cardDraggable?: boolean;
  editable?: boolean;
  // t: typeof _default$4;
} & {
  [key: string]: any;
};

export interface Card {
  id: string;
  title?: string;
  label?: string;
  description?: string;
  laneId?: string;
  style?: CSSProperties;
  draggable?: boolean;
  item?: IWorkItem;
  [key: string]: any;
}

export default function MyCard({
  style,
  onClick,
  className,
  id,
  cardDraggable,
  item,
  handleDelete,
}: MyCardProps) {
  const noDescription =
    item.tasks.length === 0
      ? "No description"
      : `${item.tasks.filter((x: ITask) => x.complete).length}/${
          item.tasks.length
        } sub-tasks complete`;

  const theme = useTheme();

  return (
    <Box
      data-id={id}
      style={style}
      className={className}
      sx={{
        backgroundColor: item.archived
          ? "rgba(255,255,255,0.5)"
          : theme.palette.mode === "light"
          ? "white"
          : "#444",
        borderRadius: 2,
        marginBottom: 2,
        padding: 1,
        cursor: "pointer",
        width: 280,
      }}
    >
      <Box draggable={cardDraggable}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Typography
            onClick={onClick}
            variant="h6"
            sx={{
              flex: 1,
              fontSize: 14,
              fontWeight: "bold",
              width: 0,
              whiteSpace: "pre-wrap",
              marginTop: "6px",
            }}
          >
            <ColorChip color={item.color} onClick={onClick} />
            {item.name}
          </Typography>
          {handleDelete && (
            <IconButton
              onClick={() => handleDelete(id)}
              sx={{ marginRight: -1, alignSelf: "start" }}
            >
              <Cancel fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Box>
      <Typography
        component="div"
        onClick={onClick}
        variant="body2"
        sx={{
          whiteSpace: "pre-wrap",
          minHeight: 50,
          maxHeight: 200,
          overflow: "hidden",
          color: item.description ? "text.primary" : "text.secondary",
        }}
      >
        <ClampLines
          id="clamp-lines"
          text={
            item.description
              ? getTextFromMentionable(item.description)
              : noDescription
          }
          lines={2}
          buttons={false}
        />
      </Typography>
      <Box sx={{ marginTop: 1, display: "flex", flexDirection: "row-reverse" }}>
        <CollaboratorsDropdown item={item} />
      </Box>
    </Box>
  );
}
