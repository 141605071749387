import React, { CSSProperties, useContext, useEffect, useState } from "react";
import Board from "react-trello-ts";
import { Box, useTheme } from "@mui/material";
import { AppContext, IAppContext } from "./App";
import { CardStatus, IWorkItem } from "./converters";
import { doc, runTransaction } from "firebase/firestore";
import { useNavigate, useParams } from "react-router";
import MyCard, { Card } from "./MyCard";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useFirebase } from "./useFirebase";
import useUpdateStatus from "./useUpdateStatus";
import { ColorName } from "./colors";

// https://github.com/rcdexta/react-trello
// https://codesandbox.io/s/trello-example-94qqqrqz1r
// https://github.com/christopher-caldwell/react-kanban

export interface BoardData {
  lanes: Lane[];
}
export interface Lane {
  id: string;
  title?: string;
  label?: string;
  style?: CSSProperties;
  cards: Card[];
  currentPage?: number;
  droppable?: boolean;
  labelStyle?: CSSProperties;
  cardStyle?: CSSProperties;
  disallowAddingCard?: boolean;
  [key: string]: any;
}

export const stateTitle = {
  backlog: "Backlog",
  "prioritized-backlog": "Prioritized Backlog",
  todo: "Todo",
  "in-progress": "In Progress",
  cr: "Code Review",
  validate: "Validate",
  done: "Completed",
};

export const workItemColors = {
  backlog: "default",
  "prioritized-backlog": "default",
  todo: "info",
  "in-progress": "warning",
  cr: "warning",
  validate: "warning",
  done: "success",
};

interface IKanban {
  handleAdd: (color?: ColorName) => void;
  handleDelete: (id: GridRowId) => void;
  handleArchiveCompleted: () => void;
  editingAllowed: boolean;
  states: CardStatus[];
}

export default function Kanban({
  handleDelete,
  editingAllowed,
  states,
}: IKanban) {
  const { data, itemCollection } = useContext(AppContext) as IAppContext;

  const theme = useTheme();

  const laneData = {
    lanes: states.map((state) => ({
      id: state,
      title: stateTitle[state],
      style: {
        maxHeight: `calc(100vh - 280px)`,
        backgroundColor: theme.palette.divider,
        color: theme.palette.text.primary,
      },
      cards: [],
    })),
  };
  const { firestore } = useFirebase();
  const navigate = useNavigate();
  const params = useParams();
  const [boardData, setBoardData] = useState<BoardData>(laneData);
  const [eventBus, setEventBus] = useState<{
    publish: (event: any) => any;
  } | null>(null);

  useEffect(() => {
    if (!eventBus || !data) {
      return;
    }

    const newLaneData = laneData.lanes.map((lane) => {
      const cards = data
        .filter((x) => x.status === lane.id)
        .sort((a, b) => a.position - b.position)
        .map((workItem) => ({
          id: workItem.id,
          item: workItem,
          handleDelete: editingAllowed ? handleDeleteWithUpdate : undefined,
        }));
      return { ...lane, cards };
    });
    eventBus.publish({ type: "UPDATE_LANES", lanes: newLaneData });

    //setBoardData({ lanes: populated } as any);
  }, [data, eventBus]);

  const handleDeleteWithUpdate = async (id: GridRowId) => {
    const workItem = data.find((x) => x.id === id);
    await handleDelete(id);
    eventBus?.publish({
      type: "REMOVE_CARD",
      laneId: workItem?.status ?? "backlog",
      cardId: id,
    });
  };

  const shouldReceiveNewData = async (nextData: BoardData) => {
    setBoardData(nextData);
  };

  const onCardUpdate = (cardId: string, data: Card) => {
    console.log("onCardUpdate", cardId, data);
  };

  const updateStatus = useUpdateStatus();

  const onCardMoveAcrossLanes = (
    fromLaneId: string,
    toLaneId: string,
    cardId: string,
    addedIndex: string
  ) => {
    runTransaction(firestore, async (transaction) => {
      boardData.lanes.forEach((lane) => {
        let removedCardFound = false;
        lane.cards.forEach(async (card: Card, index: number) => {
          if (card.id === cardId) {
            removedCardFound = true;
          }
          const fixedIndex =
            fromLaneId === card.item?.status && removedCardFound
              ? index - 1
              : index;
          const updatedCard: Partial<IWorkItem> =
            card.id === cardId
              ? {
                  // status: toLaneId as CardStatus,
                  position: parseInt(addedIndex),
                }
              : {
                  position:
                    fixedIndex >= parseInt(addedIndex) &&
                    card.item?.status === toLaneId
                      ? fixedIndex + 1
                      : fixedIndex,
                };
          transaction.update(doc(itemCollection, card.id), updatedCard);
          if (card.id === cardId && card.item) {
            updateStatus(card.item, toLaneId as CardStatus, transaction);
          }
        });
      });
    });
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
        minHeight: 0,
        height: "100%",
        flexGrow: 1,
        ".smooth-dnd-container": { minHeight: 150 },
      }}
    >
      {/*<Box sx={{ display: "flex", gap: 1, p: 1 }}>*/}
      {/*  <AddWorkItemDropdown readOnly={!editingAllowed} handleAdd={handleAdd} />*/}
      {/*  <Button*/}
      {/*    color="primary"*/}
      {/*    variant="contained"*/}
      {/*    startIcon={<Archive />}*/}
      {/*    onClick={handleArchiveCompleted}*/}
      {/*    disabled={!editingAllowed || !completedExist}*/}
      {/*  >*/}
      {/*    Archive Completed*/}
      {/*  </Button>*/}
      {/*</Box>*/}
      <Board
        components={{ Card: MyCard }}
        style={{
          backgroundColor: "transparent",
          height: "inherit",
        }}
        data={boardData}
        editable={false}
        cardDraggable={editingAllowed}
        laneDraggable={false}
        onDataChange={shouldReceiveNewData}
        onCardUpdate={onCardUpdate}
        onCardMoveAcrossLanes={onCardMoveAcrossLanes}
        eventBusHandle={(e) => setEventBus(e)}
        // laneSortFunction={(a, b) => a.item.position - b.item.position}
        onCardClick={(cardId) =>
          navigate(`/boards/${params.board}/items/${cardId}`)
        }
      />
    </Box>
  );
}
