const COLORS = {
  Green: 147,
  Red: 0,
  Orange: 30,
  Yellow: 50,
  Teal: 190,
  Blue: 225,
  Purple: 280,
};

export type ColorName = keyof typeof COLORS;

export const colorArray: ColorName[] = Object.keys(
  COLORS
) as (keyof typeof COLORS)[];

export const getColor = (color: ColorName) => {
  return `hsla(${COLORS[color]}, 100%, 50%, 0.4)`;
};

export const getCompleteColor = (color: ColorName) => {
  return `hsla(${COLORS[color]}, 100%, 50%, 0.05)`;
};

export const getSelectedColor = (color: ColorName) => {
  return `hsla(${COLORS[color]}, 100%, 50%, 0.6)`;
};

export const getStrongColor = (color: ColorName) => {
  return `hsla(${COLORS[color]}, 100%, 50%, 1)`;
};
