import React, {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import Gantt from "./Gantt";
import ItemTable from "./ItemTable";
import {
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridSelectionModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import dayjs, { Dayjs } from "dayjs";
import {
  collection,
  FirestoreError,
  WithFieldValue,
  query,
  DocumentReference,
  doc,
  orderBy,
  CollectionReference,
  addDoc,
  onSnapshot,
  runTransaction,
} from "firebase/firestore";
import { useFirestoreDocumentData } from "@react-query-firebase/firestore";
import { RouteObject, useLocation, useNavigate, useParams } from "react-router";
import { UseMutationResult } from "react-query";
import {
  boardConverter,
  CollaboratorRole,
  IWorkItem,
  IBoard,
  itemConverter,
  emailConverter,
} from "./converters";
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  useTheme,
} from "@mui/material";
import {
  NavigateNext,
  PersonAdd,
  Settings,
  ViewKanban,
  ViewTimeline,
} from "@mui/icons-material";
import DragHandler from "./DragHandler";
import { useFirebase } from "./useFirebase";
import ShareDrawer from "./ShareDrawer";
import BoardSettingsDrawer from "./BoardSettingsDrawer";
import { Link, matchRoutes } from "react-router-dom";
import { routes } from "./MainRouter";
import { useAuth } from "./FirebaseAuthContext";
import CollaboratorsPreview from "./CollaboratorsPreview";
import ItemDrawer from "./ItemDrawer";
import { useConfirm } from "material-ui-confirm";
import Kanban from "./Kanban";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { colorArray, ColorName } from "./colors";
import { useLocalStorage } from "usehooks-ts";
import useDeleteWorkItem from "./useDeleteWorkItem";
import useFileUpload from "./useFileUpload";
import PasteModal from "./PasteModal";
import firebase from "firebase/compat/app";
import SearchTask from "./SearchTask";
import useArchiveWorkItem from "./useArchiveWorkItem";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import AddWorkItemDropdown from "./AddWorkItemDropdown";
import { httpsCallable } from "firebase/functions";
import { PricingPlan, PricingPlanName, pricingPlans } from "./config";

function calculateWorkDaysInRange(
  start: Dayjs,
  end: Dayjs,
  workDays: number[]
): number {
  let current = start;
  let totalDays = 0;
  while (end.diff(current, "days") > 0) {
    if (workDays.includes(current.day())) {
      totalDays += 1;
    }
    current = current.add(1, "day");
  }
  return totalDays;
}

export type ItemMutation = UseMutationResult<
  DocumentReference<IWorkItem>,
  FirestoreError,
  WithFieldValue<IWorkItem>
>;

export interface IAppContext {
  data: IWorkItem[];
  completedExist: boolean;
  itemCollection: CollectionReference<IWorkItem>;
  boardData: IBoard;
  boardPlan: PricingPlan | null;
  boardDocument: DocumentReference<IBoard>;
  setEditing: Dispatch<SetStateAction<IWorkItem | undefined>>;
  selectionModel: GridSelectionModel;
  setSelectionModel: Dispatch<SetStateAction<GridSelectionModel>>;
  rowModesModel: GridRowModesModel;
  setRowModesModel: Dispatch<SetStateAction<GridRowModesModel>>;
  dateRanges: Dayjs[];
  maxSections: number;
  workDaysSinceStart: number;
  editable: boolean;
  editing: IWorkItem | undefined;
  currentRoute?: RouteObject | null;
  sectionWidth: number;
  setSectionWidth: Dispatch<SetStateAction<number>>;
  hideCompleted: boolean;
  setHideCompleted: Dispatch<SetStateAction<boolean>>;
  hideBacklog: boolean;
  setHideBacklog: Dispatch<SetStateAction<boolean>>;
  hideArchived: boolean;
  setHideArchived: Dispatch<SetStateAction<boolean>>;
  itemTableApiRef: React.MutableRefObject<GridApiPro>;
}

export const AppContext = React.createContext<IAppContext | undefined>(
  undefined
);

function App() {
  const theme = useTheme();
  const confirm = useConfirm();
  const { auth, firestore, functions } = useFirebase();
  const { user, userData } = useAuth();
  const navigate = useNavigate();
  const [boardPlan, setBoardPlan] = useState<PricingPlan | null>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [pastingImage, setPastingImage] = useState<boolean>(false);
  const [editing, setEditing] = useState<IWorkItem | undefined>();
  const [data, setData] = useState<IWorkItem[]>([]);
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const params = useParams();
  const location = useLocation();
  const { uploadFile } = useFileUpload();
  const route = matchRoutes(routes, location);
  const currentRoute = route && route[route.length - 1].route;
  const itemTableApiRef = useGridApiRef();

  const boardSettingsDrawerOpen =
    currentRoute?.path === "/boards/:board/settings";
  const shareDrawerOpen = currentRoute?.path === "/boards/:board/sharing";
  const boardID = params.board as string;
  const [boardView, setBoardView] = useLocalStorage<"timeline" | "kanban">(
    `boardView/${boardID}`,
    "timeline"
  );
  const [timelineDrawerHeight, setTimelineDrawerHeight] =
    useLocalStorage<number>(`timelineDrawerHeight/${boardID}`, 350);
  const [kanbanDrawerHeight, setKanbanDrawerHeight] = useLocalStorage<number>(
    `kanbanDrawerHeight/${boardID}`,
    0
  );

  const drawerHeight =
    boardView === "timeline" ? timelineDrawerHeight : kanbanDrawerHeight;

  const [sectionWidth, setSectionWidth] = useLocalStorage<number>(
    `sectionWidth/${boardID}`,
    100
  );
  const [hideCompleted, setHideCompleted] = useLocalStorage<boolean>(
    `hideCompleted/${boardID}`,
    true
  );
  const [hideBacklog, setHideBacklog] = useLocalStorage<boolean>(
    `hideBacklog/${boardID}`,
    false
  );
  const [hideArchived, setHideArchived] = useLocalStorage<boolean>(
    `hideArchived/${boardID}`,
    true
  );

  const setShareDrawerOpen = (open: boolean) =>
    navigate(open ? `/boards/${boardID}/sharing` : `/boards/${boardID}`);
  const setBoardSettingsDrawerOpen = (open: boolean) =>
    navigate(open ? `/boards/${boardID}/settings` : `/boards/${boardID}`);

  const itemCollection = collection(
    firestore,
    "boards",
    boardID,
    "items"
  ).withConverter(itemConverter);

  const boardDocument = doc(firestore, "boards", boardID).withConverter(
    boardConverter
  );
  const boardData = useFirestoreDocumentData<IBoard>(
    ["boards", boardID],
    boardDocument
  );
  const deleteWorkItem = useDeleteWorkItem();
  const archiveWorkItem = useArchiveWorkItem();
  // this is a bug - subscribe: true has to be activated with false above
  const boardDataSub = useFirestoreDocumentData<IBoard>(
    ["boards", boardID],
    boardDocument,
    { subscribe: true },
    {
      onError: async (error) => {
        if (error.code === "permission-denied") {
          try {
            await confirm({
              confirmationText: "Try again",
              title: "You do not have access to this board.",
              description:
                "Please check if you have been invited to the board and try again.",
            });
            document.location.reload();
          } catch {
            navigate("/boards");
          }
        }
      },
    }
  );

  const [snapshot, setSnapshot] = useState<
    firebase.firestore.QuerySnapshot<IWorkItem> | undefined
  >();

  useEffect(() => {
    async function loadBoardPlan() {
      const getBoardPlan = httpsCallable(functions, "getBoardPlan");
      const result = await getBoardPlan({ boardId: boardID });
      const pricingPlan = pricingPlans[result.data as PricingPlanName];
      setBoardPlan(pricingPlan);
    }

    loadBoardPlan();
  }, [boardID, functions]);

  useEffect(() => {
    const itemQueryRef = query(itemCollection, orderBy("dateCreated"));
    return onSnapshot(itemQueryRef, (snap) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSnapshot(snap);
    });
  }, []);
  const emailCollection = collection(firestore, "emails").withConverter(
    emailConverter
  );

  useEffect(() => {
    if (!snapshot) {
      return;
    }
    if (!userData?.email) {
      return;
    }
    const newData: IWorkItem[] = [];
    snapshot.forEach((item) => {
      if (item.data().archived && hideArchived) {
        return;
      }
      newData.push(item.data());
    });
    setData(newData);
  }, [snapshot, userData, hideArchived]);

  const bd = boardData.data;
  // function bufferToBase64(buffer: any) {
  //   return btoa(
  //     new Uint8Array(buffer).reduce((data, byte) => {
  //       return data + String.fromCharCode(byte);
  //     }, "")
  //   );
  // }

  useEffect(() => {
    document.onpaste = (evt) => {
      if (!evt.clipboardData || !user?.email) {
        return true;
      }
      const file = evt.clipboardData.files[0];
      if (!file) {
        return true;
      }
      (async () => {
        setPastingImage(true);
        try {
          await uploadFile(file);
        } catch (err) {
          await confirm({
            title: "Pasting failed",
            description: "Please try pasting the image again.",
            cancellationText: null,
          });
        }
        setPastingImage(false);
      })();
    };
  }, [params, confirm, uploadFile, user?.email]);

  useEffect(() => {
    if (!boardData.data) {
      return;
    }

    const matches = matchRoutes(routes, location);

    if (matches) {
      const match = matches[matches.length - 1];
      if (match.route.path === "/boards/:board") {
        const tokens = Array.from(
          boardData.data.name.matchAll(/\w+/g),
          (m) => m[0]
        );
        history.replaceState(null, "", "#" + tokens.join("-").toLowerCase());
      }
    }
  }, [boardData.data, location, params]);

  const dateRanges = useMemo<Dayjs[]>(() => {
    if (!bd) {
      return [];
    }
    const res: Dayjs[] = [];
    res.push(bd.startDate.startOf("day"));
    const maxSections = Math.max(...data.map((item) => item.end)) + 10;
    for (let i = 0; i < maxSections - 1; i++) {
      let daysLeft = bd.daysPerUnit;
      let d = res[res.length - 1];
      while (daysLeft > 0) {
        d = d.add(1, "day");
        if (bd.workDays.includes(d.day())) {
          daysLeft -= 1;
        }
      }
      res.push(d);
    }
    return res;
  }, [bd, data]);

  const maxSections = useMemo<number>(() => {
    return Math.max(...data.map((item) => item.end)) + 10;
  }, [data]);

  const currentUserRole = useMemo(() => {
    if (!bd) {
      return null;
    }

    const currentUserEmail = auth.currentUser?.email;
    if (!currentUserEmail) {
      return null;
    }

    return bd.collaboratorsByEmail[currentUserEmail].role;
  }, [bd, auth.currentUser]);

  const editingAllowed = [
    CollaboratorRole.Owner,
    CollaboratorRole.Editor,
  ].includes(currentUserRole as CollaboratorRole);

  const handleAdd = async (color?: ColorName) => {
    if (!user || !userData || !boardPlan) {
      return;
    }
    if (data.length >= boardPlan.workItemsPerBoardLimit) {
      await confirm({
        confirmationText: "Upgrade",
        title: `Number of work items exceeded for free plan`,
        description: `The free plan allows creating up to ${boardPlan.workItemsPerBoardLimit} items. To create an unlimited number of work items on a board, the board owner's plan should be upgraded.`,
      });
      navigate("/#pricing");
      return;
    }
    let newIndex = 1;
    while (data.filter((x) => x.name === `New Item ${newIndex}`).length > 0) {
      newIndex += 1;
    }
    const item =
      selectionModel.length > 0
        ? data.find((x) => x.id === selectionModel[0])
        : null;
    const doc = await addDoc(itemCollection, {
      name: `New Item ${newIndex}`,
      userId: user.uid,
      start: item ? item.start : 1,
      end: item ? item.end : 3,
      category: item ? item.category : "My Team",
      color: color ?? colorArray[Math.floor(Math.random() * colorArray.length)],
      track: item ? item.track : "My Track",
      description: "",
      tasks: [],
      collaborators: [],
      status: "backlog",
      position: data.length,
      dateCreated: dayjs(),
      archived: false,
      dependencies: [],
    });

    if (itemTableApiRef.current && itemTableApiRef.current.scrollToIndexes) {
      itemTableApiRef.current.scrollToIndexes({ rowIndex: data.length });
    }

    navigate(`/boards/${params.board}/items/${doc.id}`);

    setSelectionModel([doc.id]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [doc.id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  if (boardData.isLoading || !bd) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const handleArchive = async (id: GridRowId) => {
    const item = data.find((x) => x.id === id);
    if (!item) {
      return;
    }

    await archiveWorkItem(item);
  };

  const handleArchiveCompleted = async () => {
    await runTransaction(firestore, async (transaction) => {
      data.forEach((item) => {
        if (item.status === "done") {
          transaction.update(doc(itemCollection, item.id), { archived: true });
        }
      });
    });
  };

  const handleDelete = async (id: GridRowId) => {
    const item = data.find((x) => x.id === id);
    if (!item) {
      return;
    }

    await deleteWorkItem(item, true);
  };

  const workDaysSinceStart = calculateWorkDaysInRange(
    dayjs(bd.startDate),
    dayjs(),
    bd.workDays
  );

  const startDrag: MouseEventHandler<HTMLDivElement> = () => {
    setDragging(true);
  };

  const moveDrag: MouseEventHandler<HTMLDivElement> = (e) => {
    if (dragging) {
      const newDH = window.innerHeight - e.pageY;

      if (boardView === "timeline") {
        setTimelineDrawerHeight(newDH);
      } else if (boardView === "kanban") {
        setKanbanDrawerHeight(newDH);
      }
    }
  };

  const setDrawerHeight = (newDH: number) => {
    if (boardView === "timeline") {
      setTimelineDrawerHeight(newDH);
    } else if (boardView === "kanban") {
      setKanbanDrawerHeight(newDH);
    }
  };

  const endDrag: MouseEventHandler<HTMLDivElement> = () => {
    setDragging(false);
  };

  const setDrawerOpen = (item: IWorkItem) => (open: boolean) =>
    navigate(
      open
        ? `/boards/${params.board}/items/${item.id}`
        : `/boards/${params.board}`
    );

  const completedExist = data.map((item) => item.status).includes("done");

  return (
    <AppContext.Provider
      value={{
        data,
        completedExist,
        itemCollection,
        boardPlan,
        boardData: bd,
        boardDocument,
        setEditing,
        selectionModel,
        setSelectionModel,
        rowModesModel,
        setRowModesModel,
        dateRanges,
        maxSections,
        workDaysSinceStart,
        editable: editingAllowed,
        editing,
        currentRoute,
        sectionWidth,
        setSectionWidth,
        hideCompleted,
        setHideCompleted,
        hideBacklog,
        setHideBacklog,
        hideArchived,
        setHideArchived,
        itemTableApiRef,
      }}
    >
      <Box
        onMouseMove={moveDrag}
        onMouseUp={endDrag}
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            p: 1,
            borderBottom: "1px solid",
            borderTop: "1px solid",
            borderBottomColor: "divider",
            borderTopColor: "divider",
            display: "flex",
            alignItems: "center",
            backgroundColor: "background.primary",
          }}
        >
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNext fontSize="small" />}
                sx={{
                  gap: 2,
                  marginRight: 2,
                }}
              >
                <Link
                  to={user ? "/boards" : "/"}
                  style={{ color: theme.palette.primary.main }}
                >
                  Home
                </Link>
                <Link
                  to={`/boards/${boardID}/settings`}
                  style={{ color: theme.palette.primary.main }}
                >
                  {bd.name}
                </Link>
              </Breadcrumbs>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
              <Box sx={{ flex: 1 }} />
              <ButtonGroup>
                <Tooltip title="Kanban View">
                  <IconButton onClick={() => setBoardView("kanban")}>
                    <ViewKanban
                      sx={{
                        color:
                          boardView === "kanban"
                            ? theme.palette.primary.main
                            : theme.palette.text.secondary,
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Timeline View">
                  <IconButton onClick={() => setBoardView("timeline")}>
                    <ViewTimeline
                      sx={{
                        color:
                          boardView === "timeline"
                            ? theme.palette.primary.main
                            : theme.palette.text.secondary,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </ButtonGroup>
              <Button
                size="small"
                variant="outlined"
                startIcon={<Settings />}
                onClick={() =>
                  setBoardSettingsDrawerOpen(!boardSettingsDrawerOpen)
                }
              >
                Settings
              </Button>
              <CollaboratorsPreview />
              {currentUserRole === CollaboratorRole.Owner && (
                <Button
                  size="small"
                  variant="contained"
                  startIcon={<PersonAdd />}
                  onClick={() => setShareDrawerOpen(!shareDrawerOpen)}
                >
                  Invite
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            p: 1,
            backgroundColor: "background.paper",
            borderBottomColor: "divider",
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            display: "flex",
            gap: 1,
          }}
        >
          <AddWorkItemDropdown
            readOnly={!editingAllowed}
            handleAdd={handleAdd}
          />
          <SearchTask />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            height: 0,
          }}
        >
          {boardView === "timeline" && <Gantt />}
          {boardView === "kanban" && boardData.data && (
            <Kanban
              handleAdd={handleAdd}
              handleArchiveCompleted={handleArchiveCompleted}
              handleDelete={handleDelete}
              editingAllowed={editingAllowed}
              states={boardData.data.states}
            />
          )}

          <DragHandler onMouseDown={startDrag} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: drawerHeight < 80 ? 80 : drawerHeight,
              height: drawerHeight < 80 ? 80 : drawerHeight,
              userSelect: dragging ? "none" : "inherit",
              backgroundColor: "background.paper",
            }}
          >
            <ItemTable
              handleAdd={handleAdd}
              handleArchive={handleArchive}
              handleArchiveCompleted={handleArchiveCompleted}
              handleDelete={handleDelete}
              disabled={!editingAllowed}
              drawerHeight={drawerHeight}
              setDrawerHeight={setDrawerHeight}
            />
          </Box>
        </Box>

        <ShareDrawer open={shareDrawerOpen} setOpen={setShareDrawerOpen} />
        <BoardSettingsDrawer
          open={boardSettingsDrawerOpen}
          setOpen={setBoardSettingsDrawerOpen}
          editable={editingAllowed}
        />
      </Box>
      {data
        // .filter((item) => params.item === item.id)
        .map((item) => (
          <ItemDrawer
            key={item.id}
            open={params.item === item.id}
            setOpen={setDrawerOpen(item)}
            item={item}
            itemDocument={doc(itemCollection, item.id)}
            data={data}
            editable={editingAllowed}
          />
        ))}
      <PasteModal pastingImage={pastingImage} />
    </AppContext.Provider>
  );
}

export default App;
