import { CategoryData, TrackData } from "./Gantt";
import { useContext, useMemo } from "react";
import { AppContext, IAppContext } from "./App";
import { CardStatus } from "./converters";

export default function useGanttData() {
  const { data, boardData, hideCompleted, hideBacklog } = useContext(
    AppContext
  ) as IAppContext;

  return useMemo(() => {
    const categories: CategoryData[] = [];
    data
      .filter(
        (workItem) =>
          (["todo", "in-progress", "cr", "validate"] as CardStatus[]).includes(
            workItem.status
          ) ||
          ((["backlog", "prioritized-backlog"] as CardStatus[]).includes(
            workItem.status
          ) &&
            !hideBacklog) ||
          (workItem.status === "done" && !hideCompleted)
      )
      .forEach((item) => {
        let category: CategoryData | undefined = categories.find(
          (c) => c.category === item.category
        );
        if (!category) {
          category = {
            category: item.category,
            tracks: [],
          };
          categories.push(category);
          //categories.sort((a, b) => a.category.localeCompare(b.category));
        }

        if (boardData.groupByPerson) {
          const collabList = [
            ...item.collaborators,
            ...(item.collaborators.length === 0 ? ["Unassigned"] : []),
          ];
          for (const collaborator in collabList) {
            let track: TrackData | undefined = category.tracks.find(
              (t) =>
                t.category === item.category &&
                t.track === collabList[collaborator]
            );

            if (!track) {
              track = {
                category: item.category,
                track: collabList[collaborator],
                data: [],
              };

              category.tracks.push(track);
              //category.tracks.sort((a, b) => a.track.localeCompare(b.track));
            }

            track.data.push(item);
          }
        } else {
          let track: TrackData | undefined = category.tracks.find(
            (t) => t.category === item.category && t.track === item.track
          );

          if (!track) {
            track = {
              category: item.category,
              track: item.track,
              data: [],
            };

            category.tracks.push(track);
            // category.tracks.sort((a, b) => a.track.localeCompare(b.track));
          }

          track.data.push(item);
        }
      });
    return categories;
  }, [data, boardData, hideCompleted, hideBacklog]);
}
