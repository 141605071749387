import { addDoc, collection } from "firebase/firestore";
import { commentConverter } from "./converters";
import { v4 } from "uuid";
import dayjs from "dayjs";
import { useConfirm } from "material-ui-confirm";
import { useParams } from "react-router";
import { useFirebase } from "./useFirebase";
import { useAuth } from "./FirebaseAuthContext";

export default function useFileUpload() {
  const confirm = useConfirm();
  const params = useParams();
  const { firestore, storage, ref, uploadBytes, getDownloadURL } =
    useFirebase();
  const { user } = useAuth();

  const uploadFile = async (file: File) => {
    // just some code to get it locally if needed
    // const data = await file.arrayBuffer();
    // const base64String = bufferToBase64(data);
    // const imageURI = `data:${file.type};base64,${base64String}`;
    const itemID = params.item as string | undefined;
    const boardID = params.board as string;

    if (!user?.email) {
      return;
    }
    if (!itemID) {
      await confirm({
        title: "No work item selected",
        description:
          "Pasting images can only be done when editing a work item.",
      });
      return;
    }
    if (!file.type.includes("image")) {
      await confirm({
        title: "Only images are supported",
        description:
          "File uploads are not supported. You can copy and paste an image, and it will appear as a comment on a work item.",
      });
      return;
    }
    const commentCollection = collection(
      firestore,
      "boards",
      boardID,
      "items",
      itemID,
      "comments"
    ).withConverter(commentConverter);

    const fileRef = ref(storage, `boards/${boardID}/items/${itemID}/${v4()}`);
    await uploadBytes(fileRef, file);
    const url = await getDownloadURL(fileRef);

    await addDoc(commentCollection, {
      from: user.email,
      text: "",
      dateCreated: dayjs(),
      fileMime: file.type,
      fileName: file.name,
      fileData: url,
    });
  };

  return { uploadFile };
}
