import ReactMarkdown from "react-markdown";
import { Comment, Delete, UploadFile } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useContext, useState } from "react";
import {
  commentConverter,
  emailConverter,
  EmailTemplate,
  IComment,
  IWorkItem,
} from "./converters";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
} from "firebase/firestore";
import { AppContext, IAppContext } from "./App";
import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { useFirebase } from "./useFirebase";
import { useParams } from "react-router";
import { useAuth } from "./FirebaseAuthContext";
import dayjs from "dayjs";
import UserAvatar from "./UserAvatar";
import { getStorage, ref, deleteObject } from "firebase/storage";
import useFileUpload from "./useFileUpload";
import CommentMention from "./CommentMention";
import { InputBaseComponentProps } from "@mui/material/InputBase/InputBase";
import useCollaborators from "./useCollaborators";
import ImagePreview from "./ImagePreview";
import {
  getEmailsFromIds,
  getIdsFromMentionable,
  getTextFromMentionable,
} from "./utils";

export interface ICommentBox {
  item: IWorkItem;
}

export default function CommentBox({ item }: ICommentBox) {
  const { userData } = useAuth();
  const { firestore } = useFirebase();
  const params = useParams();
  const boardID = params.board as string;
  const { uploadFile } = useFileUpload();
  const { editable, boardData } = useContext(AppContext) as IAppContext;
  const [comment, setComment] = useState<string>("");
  const { auth } = useFirebase();
  const userList = useCollaborators();

  const commentCollection = collection(
    firestore,
    "boards",
    boardID,
    "items",
    item.id as string,
    "comments"
  ).withConverter(commentConverter);
  const commentRef = query(commentCollection, orderBy("dateCreated", "desc"));
  const commentQuery = useFirestoreQueryData<IComment>(
    ["itemComments", item.id],
    commentRef,
    {
      subscribe: true,
    }
  );

  const onUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    await uploadFile(e.target.files[0]);
  };

  const handleComment = async () => {
    if (!comment) {
      return;
    }
    if (!userData?.email) {
      console.log("no email");
      return;
    }
    setComment("");
    await addDoc(commentCollection, {
      from: userData?.email,
      text: comment,
      dateCreated: dayjs(),
    });

    if (!item.id) {
      return;
    }
    const emailCollection = collection(firestore, "emails").withConverter(
      emailConverter
    );

    const mentionedIds = getIdsFromMentionable(comment);
    const mentionedEmails = getEmailsFromIds(
      mentionedIds,
      userList,
      userData.email
    );

    await addDoc(emailCollection, {
      to: mentionedEmails,
      template: {
        name: EmailTemplate.Comment,
        data: {
          itemName: item.name,
          comment: getTextFromMentionable(comment),
          itemId: item.id,
          boardId: boardData.id as string,
          userName: auth.currentUser?.displayName as string,
        },
      },
    });
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {editable && (
        <>
          <TextField
            InputLabelProps={{ shrink: true }}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            rows={4}
            label="Comment"
            multiline
            fullWidth
            placeholder={editable ? "Enter comment..." : ""}
            InputProps={{
              inputComponent: CommentMention,
              inputProps: {
                onChange: (e: any) => {
                  setComment(e.target.value);
                },
                value: comment,
              },
            }}
            inputProps={{
              readOnly: !editable,
            }}
          />

          <Box>
            <Button
              sx={{ mr: 1 }}
              variant="contained"
              disabled={comment === ""}
              startIcon={<Comment />}
              onClick={handleComment}
            >
              Comment
            </Button>
            <Button
              startIcon={<UploadFile />}
              variant="contained"
              component="label"
            >
              Upload Image
              <input type="file" hidden onChange={(e) => onUploadFile(e)} />
            </Button>
          </Box>
        </>
      )}
      {commentQuery.data && commentQuery.data.length > 0 ? (
        <Typography variant="h6">
          Comments ({commentQuery.data.length})
        </Typography>
      ) : (
        <Typography>There are no comments on this work item.</Typography>
      )}
      {commentQuery.data?.map((comment) => (
        <Box
          key={comment.id}
          sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 2 }}
        >
          <Paper sx={{ padding: 1, backgroundColor: "background.paper" }}>
            <ReactMarkdown
              children={comment.text}
              components={{
                a: (props) => (
                  <Typography component="span" sx={{ fontWeight: "bold" }}>
                    {props.children}
                  </Typography>
                ),
              }}
            />
            {comment.fileData && <ImagePreview src={comment.fileData} />}
          </Paper>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <UserAvatar userEmail={comment.from} />
            <Typography variant="subtitle2">
              {comment.dateCreated.toDate().toLocaleString()}
            </Typography>
            <Typography>
              <IconButton
                size="small"
                onClick={async () => {
                  if (!item.id || !comment.id) {
                    return;
                  }
                  await deleteDoc(
                    doc(
                      firestore,
                      "boards",
                      boardID,
                      "items",
                      item.id,
                      "comments",
                      comment.id
                    )
                  );

                  // delete file
                  if (comment.fileData) {
                    const storage = getStorage();
                    const imageRef = ref(storage, comment.fileData);
                    await deleteObject(imageRef);
                  }
                }}
              >
                <Delete />
              </IconButton>
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
