import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";
import dayjs, { Dayjs } from "dayjs";
import { ColorName } from "./colors";
import { ITask } from "./ItemDrawer";
import { stateTitle } from "./Kanban";
import { DEFAULT_STATES } from "./Boards";

export type CardStatus = keyof typeof stateTitle;

export enum CollaboratorRole {
  Viewer = 0,
  Editor = 1,
  Owner = 100,
}

export enum EmailTemplate {
  ShareBoardWithNewUser = "shareBoardWithNewUser",
  ShareBoardWithExistingUser = "shareBoardWithExistingUser",
  WorkItemAssigned = "workItemAssigned",
  SubtaskAssigned = "subtaskAssigned",
  Comment = "comment",
  Mention = "mention",
  Transition = "transition",
}

export interface IBoard {
  id?: string;
  organizationId: string;
  name: string;
  dateCreated: Dayjs;
  startDate: Dayjs;
  daysPerUnit: number;
  workDays: number[];
  collaboratorsByEmail: {
    [userEmail: string]: {
      userEmail: string;
      userId: string | null;
      role: CollaboratorRole;
    };
  };
  collaboratorsById: {
    [userId: string]: {
      userEmail: string;
      userId: string;
      role: CollaboratorRole;
    };
  };
  groupByPerson: boolean;
  states: CardStatus[];
}

export interface IWorkItem {
  id?: string;
  userId: string;
  name: string;
  start: number;
  end: number;
  color: ColorName;
  category: string;
  track: string;
  description: string;
  status: CardStatus;
  tasks: ITask[];
  collaborators: string[];
  position: number;
  dateCreated: Dayjs;
  archived: boolean;
  dependencies: string[];
}

export interface IUser {
  id?: string;
  organizationId: string;
  displayName: string;
  pricingPlan: string;
  email: string;
  photoURL: string | null;
  emailVerified: boolean;
  dateCreated: Dayjs;
  lastLogin: Dayjs | null;
  enabled: boolean;
  isAdmin?: boolean;
}

export interface IEmail {
  to: string[];
  template: {
    name: EmailTemplate;
    data: {
      [key: string]: string;
    };
  };
}

export interface IComment {
  id?: string;
  from: string;
  dateCreated: Dayjs;
  text: string;
  fileData?: string;
  fileName?: string;
  fileMime?: string;
}

export interface IOrganization {
  id?: string;
  name: string;
  ownerId: string;
  dateCreated: Dayjs;
}

export const boardConverter = {
  toFirestore(board: IBoard): DocumentData {
    return {
      organizationId: board.organizationId,
      name: board.name,
      dateCreated: board.dateCreated.toDate(),
      startDate: board.startDate.toDate(),
      daysPerUnit: board.daysPerUnit,
      workDays: board.workDays,
      collaboratorsByEmail: board.collaboratorsByEmail,
      collaboratorsById: board.collaboratorsById,
      groupByPerson: board.groupByPerson,
      states: board.states,
    };
  },

  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): IBoard {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      organizationId: data.organizationId,
      name: data.name,
      dateCreated: dayjs(new Date(data.dateCreated.seconds * 1000)),
      startDate: dayjs(new Date(data.startDate.seconds * 1000)),
      daysPerUnit: data.daysPerUnit,
      workDays: data.workDays,
      collaboratorsByEmail: data.collaboratorsByEmail,
      collaboratorsById: data.collaboratorsById,
      groupByPerson: data.groupByPerson,
      states: data.states ?? DEFAULT_STATES,
    };
  },
};

export const itemConverter = {
  toFirestore(item: IWorkItem): DocumentData {
    return {
      userId: item.userId,
      name: item.name,
      start: item.start,
      end: item.end,
      color: item.color,
      category: item.category,
      track: item.track,
      description: item.description,
      tasks: item.tasks,
      collaborators: item.collaborators,
      status: item.status,
      position: item.position,
      dateCreated: item.dateCreated.toDate(),
      archived: item.archived,
      dependencies: item.dependencies,
    };
  },

  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): IWorkItem {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      userId: data.userId,
      name: data.name,
      start: data.start,
      end: data.end,
      color: data.color,
      category: data.category,
      track: data.track,
      description: data.description,
      tasks: data.tasks,
      collaborators: data.collaborators,
      status: data.status,
      position: data.position,
      dateCreated: data.dateCreated
        ? dayjs(new Date(data.dateCreated.seconds * 1000))
        : dayjs(),
      archived: data.archived ?? false,
      dependencies: data.dependencies ?? [],
    };
  },
};

export const userConverter = {
  toFirestore(user: IUser): DocumentData {
    return {
      organizationId: user.organizationId,
      displayName: user.displayName,
      pricingPlan: user.pricingPlan,
      // lowerCaseDisplayName is used for case insensitive search
      lowerCaseDisplayName: user.displayName.toLowerCase(),
      email: user.email,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      dateCreated: user.dateCreated.toDate(),
      lastLogin: user.lastLogin?.toDate() || null,
      enabled: user.enabled,
    };
  },

  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): IUser {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      organizationId: data.organizationId,
      displayName: data.displayName,
      pricingPlan: data.pricingPlan,
      email: data.email,
      photoURL: data.photoURL,
      emailVerified: data.emailVerified,
      dateCreated: dayjs(new Date(data.dateCreated.seconds * 1000)),
      lastLogin: data.lastLogin
        ? dayjs(new Date(data.lastLogin.seconds * 1000))
        : null,
      enabled: data.enabled,
      isAdmin: data.isAdmin,
    };
  },
};

export const emailConverter = {
  toFirestore(email: IEmail): DocumentData {
    return {
      to: email.to,
      template: email.template,
    };
  },

  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): IEmail {
    const data = snapshot.data(options);
    return {
      to: data.to,
      template: data.template,
    };
  },
};

export const commentConverter = {
  toFirestore(comment: IComment): DocumentData {
    return {
      from: comment.from,
      text: comment.text,
      dateCreated: comment.dateCreated.toDate(),
      fileData: comment.fileData ?? null,
      fileName: comment.fileName ?? null,
      fileMime: comment.fileMime ?? null,
    };
  },

  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): IComment {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      dateCreated: dayjs(new Date(data.dateCreated.seconds * 1000)),
      from: data.from,
      text: data.text,
      fileData: data.fileData,
      fileName: data.fileName,
      fileMime: data.fileMime,
    };
  },
};

export const organizationConverter = {
  toFirestore(organization: IOrganization): DocumentData {
    return {
      name: organization.name,
      dateCreated: organization.dateCreated.toDate(),
      ownerId: organization.ownerId,
    };
  },

  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): IOrganization {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      name: data.name,
      ownerId: data.ownerId,
      dateCreated: dayjs(new Date(data.dateCreated.seconds * 1000)),
    };
  },
};
