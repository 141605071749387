import { Box, Button, Drawer, Typography } from "@mui/material";
import React, { Dispatch, ReactNode, useEffect, useRef } from "react";
import { Cancel, ChevronLeft } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

interface IAppDrawer {
  open: boolean;
  setOpen: Dispatch<boolean>;
  title: string;
  icon: ReactNode;
  children: ReactNode;
  titleSide?: ReactNode;
  minWidth: number;
  closeOnEnter?: () => boolean;
}
export default function AppDrawer({
  open,
  setOpen,
  title,
  icon,
  children,
  titleSide,
  minWidth,
  closeOnEnter,
}: IAppDrawer) {
  const drawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (ev: KeyboardEvent) => {
      if (closeOnEnter && !closeOnEnter()) {
        return;
      }
      if (ev.key !== "Enter") {
        return;
      }
      return;
      // I have no idea why this needs to be in a setTimeout
      setTimeout(() => setOpen(false), 0);
    };
    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, []);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
      ref={drawerRef}
    >
      <Box
        sx={{
          width: minWidth,
          maxWidth: "100vw",
          height: "100vh",
          backgroundColor: "background.paper",
          display: "flex",
          flexDirection: "column",
          gap: 1,
          p: 2,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            variant="h6"
            component="h2"
            sx={{ flexGrow: 1, alignItems: "center", display: "flex", gap: 1 }}
          >
            <Box sx={{ color: "text.secondary", lineHeight: "1em" }}>
              {icon}
            </Box>
            <Box
              sx={{
                overflow: "hidden",
                whiteSpace: "pre",
                textOverflow: "ellipsis",
                width: 0,
                flex: 1,
              }}
            >
              {title}
            </Box>
          </Typography>
          {titleSide}
          <IconButton onClick={() => setOpen(false)}>
            <Cancel />
          </IconButton>
        </Box>
        <Divider sx={{ mb: 1 }} />
        <Box sx={{ flexGrow: 1, overflowY: "scroll" }}>{children}</Box>
      </Box>
    </Drawer>
  );
}
